// Fundamentals
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import auth from '../../firebase/init';
import { authActions } from '../../redux/auth-slice';
import { useDispatch, useSelector } from 'react-redux';
import { PopupButton } from '@typeform/embed-react';

// Styles
import './sideBar.scss';

// Assets
import admin from '../../assets/sideBar/admin-page-v2.svg';
import companyProfile from '../../assets/sideBar/company_profile.png';
import logout from '../../assets/sideBar/power-off.svg';
import logo from '../../assets/sideBar/logo.png';
import ctrlLogo from '../../assets/sideBar/ctrl-logo.svg';
import ctrlLogoSmall from '../../assets/sideBar/ctrl-logo-small.svg';
import plusSign from '../../assets/sideBar/plus-sign.png';
import dashboard from '../../assets/sideBar/dashboard-icon.png';
import arrow from '../../assets/sideBar/arrow.png';
import feedback from '../../assets/sideBar/faq-v2.svg';
import contacts from '../../assets/sideBar/contacts-page-v2.svg';
import companyLogo from '../../assets/sideBar/company-logo.png';
import moonman from '../../assets/sideBar/sidebar-moon-man.svg';
import { useTheme } from '../../contexts/theme/themeContext';
import collapsedLogo from '../../assets/sideBar/collapsed-logo.png';
import personaLinkIcon from '../../assets/sideBar/persona-page-v2.svg';
import renameSequencesIcon from '../../assets/sideBar/copy-link-icon.png';
import copyLinkIcon from '../../assets/sideBar/copy-v2.svg';
import analyticsLinkIcon from '../../assets/sideBar/analytics-page-v2.svg';
import settingsLinkIcon from '../../assets/sideBar/settings-link-icon.png';
import overviewLinkIcon from '../../assets/sideBar/overview-link-icon.png';
import faqIcon from '../../assets/sideBar/faq-icon.png';
import settingsIcon from '../../assets/sideBar/settings-page-v2.svg';

// Interface Declarations
interface ISideBarLink {
  icon: any;
  name: string;
  active: boolean;
  isAdmin: boolean;
  childLinks: string[];
  setActiveLink: (name: string) => void;
  isCollapsedSide: boolean;
  toggleSidebar: () => void;
}

interface ISideBarChildLink {
  links: string[];
  isAdmin: boolean;
  isCollapsedSide: boolean;
  toggleSidebar: () => void;
}

interface ISideBar {
  isAdmin: boolean;
}

interface IStore {
  clienttype: IClientTypeSlice;
}

interface ToolTips {
  [key: string]: boolean;
}

interface IType {
  type: string;
  payload: string;
}

interface IClientTypeSlice {
  clientType: IType;
}

// Child Components
const SideBarLink = ({
  icon,
  name,
  active,
  isAdmin,
  childLinks,
  setActiveLink,
  isCollapsedSide,
  toggleSidebar,
}: ISideBarLink) => {
  const [isChildLinksVisible, setIsChildLinksVisible] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);

  const handleClick = () => {
    setIsChildLinksVisible(!isChildLinksVisible);
    setActiveLink(name);
    setHoveredLink(null);
  };

  const handleMouseOver = () => {
    setHoveredLink(name);
  };

  const handleMouseOut = () => {
    setHoveredLink(null);
  };

  useEffect(() => {
    if (!active) {
      setIsChildLinksVisible(false);
    }
  }, [active]);

  return (
    <div
      className={[
        'sidebar-container__links-container__link-item',
        'curved-button',
        'cursor',
        active ? 'sidebar-container__links-container__active-link-item' : '',
      ].join(' ')}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="sidebar-container__links-container__link-item__top-row">
        <div className="sidebar-container__links-container__link-item__top-row__left-aligned-elements">
          <img className="icon-with-right-margin" src={icon} alt="dashboard" />
          {isCollapsedSide ? null : name}
        </div>

        {isCollapsedSide ? null : (
          <div>
            {childLinks.length > 0 && (
              <img
                className="sidebar-container__links-container__link-item__top-row__expanded-arrow"
                src={arrow}
                alt="arrow"
              />
            )}
          </div>
        )}
      </div>

      {/* {isChildLinksVisible && childLinks.length > 0 && (
        <div>
          <SideBarChildLink
            links={childLinks}
            isAdmin={isAdmin}
            isCollapsedSide={isCollapsedSide}
            toggleSidebar={toggleSidebar}
          />
        </div>
      )} */}

      {hoveredLink === name && isCollapsedSide && (
        <span className="link-name">{name}</span>
      )}
    </div>
  );
};

// const SideBarChildLink = ({
//   links,
//   isCollapsedSide,
//   toggleSidebar,
//   isAdmin,
// }: ISideBarChildLink) => {
//   const navigate = useNavigate();
//   const [hoveredLink, setHoveredLink] = useState(null);

//   const handleChildLinkClick = (link: string) => {
//     switch (link) {
//       case 'Overview':
//         if (isAdmin) {
//           navigate(`/admin/sequences/${Cookies.get('displayName')}`);
//         } else {
//           navigate(`/sequences//${Cookies.get('displayName')}`);
//         }
//         break;
//       case 'Settings':
//         if (isAdmin) {
//           navigate(`/admin/sequence-settings/${Cookies.get('displayName')}`);
//         } else {
//           navigate(`/sequence-settings//${Cookies.get('displayName')}`);
//         }
//         break;
//       case 'Analytics':
//         if (isAdmin) {
//           navigate(`/admin/analytics/${Cookies.get('displayName')}`);
//         } else {
//           navigate(`/analytics/${Cookies.get('displayName')}`);
//         }
//         break;
//       case 'Personas':
//         if (isAdmin) {
//           navigate(`/admin/personas/${Cookies.get('displayName')}`);
//         } else {
//           navigate(`/personas/${Cookies.get('displayName')}`);
//         }
//         break;
//       case 'Copy':
//         if (isAdmin) {
//           navigate(`/admin/copies/${Cookies.get('displayName')}`);
//         } else {
//           navigate(`/copies/${Cookies.get('displayName')}`);
//         }
//         break;
//       default:
//       // Do Nothing
//     }

//     toggleSidebar();
//   };

//   const location = useLocation();
//   const currentPathname = location.pathname;

//   return (
//     <div className="sidebar-container__links-container__link-item__child-links-container">
//       {links.map((link, index) => {
//         const isActiveChildLink =
//           link === 'Overview'
//             ? currentPathname === '/sequences'
//             : currentPathname.includes(
//                 link === 'Copy'
//                   ? 'copies'
//                   : link.toLowerCase().replace(' ', '-')
//               );

//         const handleMouseOver = (link: any) => {
//           setHoveredLink(link);
//         };

//         const handleMouseOut = () => {
//           setHoveredLink(null);
//         };

//         return (
//           <div
//             key={index}
//             className={`sidebar-container__links-container__link-item__child-links-container__child-link ${
//               isActiveChildLink ? 'active-child-link' : ''
//             }`}
//             onMouseOver={() => handleMouseOver(link)}
//             onMouseOut={() => handleMouseOut()}
//             onClick={() => handleChildLinkClick(link)}
//           >
//             {link === 'Personas' ? <img src={personaLinkIcon} alt="" /> : null}
//             {link === 'Analytics' ? (
//               <img src={analyticsLinkIcon} alt="" />
//             ) : null}
//             {link === 'Copy' ? <img src={copyLinkIcon} alt="" /> : null}
//             {link === 'Settings' ? <img src={settingsLinkIcon} alt="" /> : null}
//             {link === 'Overview' ? <img src={overviewLinkIcon} alt="" /> : null}
//             {isCollapsedSide ? null : link}
//             {hoveredLink === link && isCollapsedSide && (
//               <span className="link-name">{link}</span>
//             )}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

const Upsell = () => {
  return (
    <div className="sidebar-container__upsell-container curved-button">
      <p className="sidebar-container__upsell-container__header">
        Upgrade to Embark
      </p>
      <p className="sidebar-container__upsell-container__description">
        Get 1,000 more contacts at 17% off per contact
      </p>
      <div className="sidebar-container__upsell-container__action-buttons-row">
        <div className="sidebar-container__upsell-container__action-buttons-row__dismiss-button icon-with-right-margin">
          Dismiss
        </div>
        <div className="sidebar-container__upsell-container__action-buttons-row__learn-more-button">
          Learn more
        </div>
      </div>
      <div className="sidebar-container__upsell-container__moonman-container">
        <img
          src={moonman}
          alt="upsell image"
          className="sidebar-container__upsell-container__moonman-container__moonman"
        />
      </div>
    </div>
  );
};

const SideBar = ({ isAdmin }: ISideBar) => {
  const dispatch = useDispatch();
  const { setCurrentTheme } = useTheme();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('Analytics');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [toolTips, setToolTips] = useState<ToolTips>({});
  const location = useLocation();
  const currentPathname = location.pathname;

  useEffect(() => {
    currentPathname.includes('/copies')
      ? setActiveLink('Copy')
      : currentPathname.includes('/analytics')
      ? setActiveLink('Analytics')
      : currentPathname.includes('/personas')
      ? setActiveLink('Personas')
      : currentPathname.includes('/rename')
      ? setActiveLink('Rename Sequences')
      : currentPathname.includes('/settings')
      ? setActiveLink('Settings')
      : setActiveLink('Contacts');
  }, []);

  const handleContactPageClick = () => {
    if (isAdmin) {
      navigate(`/admin/contacts/${Cookies.get('displayName')}`);
    } else {
      navigate(`/contacts/${Cookies.get('displayName')}`);
    }
  };

  const handleSettingsPageClick = () => {
    if (isAdmin) {
      navigate(`/admin/settings/${Cookies.get('displayName')}`);
    } else {
      navigate(`/settings/${Cookies.get('displayName')}`);
    }
  };

  const handleRenameSequences = () => {
    if (isAdmin) {
      navigate(`/admin/rename/${Cookies.get('displayName')}`);
    } else {
      navigate(`/rename/${Cookies.get('displayName')}`);
    }
  };

  const handleAnalyticsPageClick = () => {
    if (isAdmin) {
      navigate(`/admin/analytics/${Cookies.get('displayName')}`);
    } else {
      navigate(`/analytics/${Cookies.get('displayName')}`);
    }
  };

  const handlePersonasPageClick = () => {
    if (isAdmin) {
      navigate(`/admin/personas/${Cookies.get('displayName')}`);
    } else {
      navigate(`/personas/${Cookies.get('displayName')}`);
    }
  };

  const handleCopyPageClick = () => {
    if (isAdmin) {
      navigate(`/admin/copies/${Cookies.get('displayName')}`);
    } else {
      navigate(`/copies/${Cookies.get('displayName')}`);
    }
  };

  const handleLogoClick = () => {
    if (isAdmin) {
      navigate(`/admin/analytics/${Cookies.get('displayName')}`);
    } else {
      navigate(`/analytics/${Cookies.get('displayName')}`);
    }
  };

  const sidebarExpand = () => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };

  const toggleSidebar = () => {
    setIsCollapsed(true);
  };

  const handleSetActiveLink = (name: string) => {
    setActiveLink(name);
  };

  const handleAdminDashboardClick = () => {
    navigate(`/admin`);
  };

  const logOut = () => {
    auth.signOut();
    window.location.reload();
    Cookies.remove('isAdmin');
    Cookies.remove('loggedIn');
    Cookies.remove('displayName');
    Cookies.remove('photoURL');
    Cookies.remove('fuelStationId');
    Cookies.remove('clientType');
    dispatch(authActions.logout());
    navigate('/');
  };

  const faqClick = () => {
    if (isAdmin) {
      navigate(`/admin/faq`);
    } else {
      navigate(`/faq`);
    }
  };

  //Below is setting the tooltip hover for the PopupButtons
  const handleMouseOver = (buttonId: any) => {
    setToolTips((prevToolTips) => ({ ...prevToolTips, [buttonId]: true }));
  };

  const handleMouseLeave = (buttonId: any) => {
    setToolTips((prevToolTips) => ({ ...prevToolTips, [buttonId]: false }));
  };

  const renderToolTip = (buttonId: any, text: any) => {
    return toolTips[buttonId] ? (
      <span
        className={buttonId === 'v1utshfh' ? 'link-name-request' : 'link-name'}
      >
        {isCollapsed ? text : null}
      </span>
    ) : null;
  };

  return (
    <div
      className={`sidebar-container ${isCollapsed ? 'collapsed' : ''}`}
      id=""
    >
      {Cookies.get('clientType') === 'SaaS' ? (
        <img
          onClick={handleLogoClick}
          src={isCollapsed ? ctrlLogoSmall : ctrlLogo}
          alt="logo"
          className="sidebar-container__logo"
        />
      ) : (
        <img
          onClick={handleLogoClick}
          src={isCollapsed ? collapsedLogo : logo}
          alt="logo"
          className="sidebar-container__logo"
        />
      )}

      {Cookies.get('clientType') === 'SaaS' ? (
        <div>
          <div
            className={`${
              activeLink === 'Rename Sequences' ? 'this-is-active' : null
            }  sidebar-container__rename-sequence-button`}
          ></div>
        </div>
      ) : (
        <div>
          <PopupButton
            id="v1utshfh"
            className={`sidebar-container__new-sequence-button ${
              isCollapsed ? 'new-sequence-button-hover' : null
            } curved-button`}
          >
            <img
              className="icon-with-right-margin request-sequence-icon"
              src={plusSign}
              alt="plus"
            />
            {renderToolTip('v1utshfh', 'Request a Sequence')}
            {isCollapsed ? null : 'Request a Sequence'}
          </PopupButton>
        </div>
      )}

      <button className="collapse-expand" onClick={sidebarExpand}>
        <img src={isCollapsed ? arrow : arrow} alt="" />
      </button>
      <div className="sidebar-container__links-container">
        {/* <SideBarLink
          icon={dashboard}
          name="Dashboard"
          active={activeLink === 'Dashboard'}
          childLinks={['Analytics', 'Personas', 'Copy']}
          isAdmin={isAdmin}
          setActiveLink={handleSetActiveLink}
          isCollapsedSide={isCollapsed}
          toggleSidebar={toggleSidebar}
        /> */}
        {/* <SideBarLink
          icon={sequences}
          name="Sequences"
          active={activeLink === 'Sequences'}
          childLinks={['Overview', 'Settings']}
          isAdmin={isAdmin}
          setActiveLink={handleSetActiveLink}
          isCollapsedSide={isCollapsed}
          toggleSidebar={toggleSidebar}
        /> */}
        <div
          onClick={handleAnalyticsPageClick}
          className={`${activeLink === 'Analytics' ? 'this-is-active' : null}`}
        >
          <SideBarLink
            icon={analyticsLinkIcon}
            name="Analytics"
            active={false}
            childLinks={[]}
            isAdmin={isAdmin}
            setActiveLink={handleSetActiveLink}
            isCollapsedSide={isCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div
          onClick={handlePersonasPageClick}
          className={`${activeLink === 'Personas' ? 'this-is-active' : null}`}
        >
          <SideBarLink
            icon={personaLinkIcon}
            name="Personas"
            active={false}
            childLinks={[]}
            isAdmin={isAdmin}
            setActiveLink={handleSetActiveLink}
            isCollapsedSide={isCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div
          onClick={handleCopyPageClick}
          className={`${activeLink === 'Copy' ? 'this-is-active' : null} `}
        >
          <SideBarLink
            icon={copyLinkIcon}
            name="Copy"
            active={false}
            childLinks={[]}
            isAdmin={isAdmin}
            setActiveLink={handleSetActiveLink}
            isCollapsedSide={isCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div
          onClick={handleContactPageClick}
          className={`${activeLink === 'Contacts' ? 'this-is-active' : null} `}
        >
          <SideBarLink
            icon={contacts}
            name="Contacts"
            active={false}
            childLinks={[]}
            isAdmin={isAdmin}
            setActiveLink={handleSetActiveLink}
            isCollapsedSide={isCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        <div
          onClick={handleSettingsPageClick}
          className={`${
            activeLink === 'Settings' ? 'this-is-active' : null
          } settings-link`}
        >
          <SideBarLink
            icon={settingsIcon}
            name="Settings"
            active={false}
            childLinks={[]}
            isAdmin={isAdmin}
            setActiveLink={handleSetActiveLink}
            isCollapsedSide={isCollapsed}
            toggleSidebar={toggleSidebar}
          />
        </div>
        {Cookies.get('clientType') === 'SaaS' ? null : (
          <PopupButton
            id="n92ULmZz"
            className={
              isCollapsed
                ? 'company-profile-hover sidebar-container__links-container__company-profile'
                : 'sidebar-container__links-container__company-profile'
            }
          >
            <img
              className="icon-with-right-margin"
              src={companyProfile}
              alt="plus"
            />
            {renderToolTip('n92ULmZz', 'Company Profile')}
            {isCollapsed ? null : 'Company Profile'}
          </PopupButton>
        )}

        {isAdmin && (
          <div onClick={handleAdminDashboardClick}>
            <SideBarLink
              icon={admin}
              name="Admin Dashboard"
              active={false}
              childLinks={[]}
              isAdmin={isAdmin}
              setActiveLink={handleSetActiveLink}
              isCollapsedSide={isCollapsed}
              toggleSidebar={toggleSidebar}
            />
          </div>
        )}
      </div>
      {/* {isCollapsed ? null : <Upsell />} */}

      {Cookies.get('clientType') === 'SaaS' ? null : (
        <div className="sidebar-container__theme">
          <div className="sequence-toggle-container sidebar-container__theme__toggle-button">
            <label className="switch">
              <input type="checkbox" />
              <span
                className="slider round"
                onClick={() => {
                  setCurrentTheme((prevTheme) =>
                    prevTheme === 'dark' ? 'light' : 'dark'
                  );
                }}
              ></span>
            </label>
          </div>
        </div>
      )}

      <PopupButton
        id="rYwW7gB4"
        className={
          isCollapsed
            ? 'feedback-hover sidebar-container__feedback'
            : 'sidebar-container__feedback'
        }
      >
        <img
          className="icon-with-right-margin feedback-icon cursor"
          src={feedback}
          alt="plus"
        />
        {renderToolTip('submit-feedback-icon', 'Submit Feedback')}
        {isCollapsed ? null : 'Submit Feedback'}
      </PopupButton>
      {Cookies.get('clientType') === 'SaaS' ? null : (
        <div
          className="sidebar-container__faq"
          onClick={faqClick}
          id="faq-icon"
        >
          <img
            className="icon-with-right-margin sidebar-container__faq__icon"
            src={faqIcon}
            alt="faq"
            onMouseOver={() => handleMouseOver('faq-icon')}
            onMouseLeave={() => handleMouseLeave('faq-icon')}
          />
          {renderToolTip('faq-icon', 'FAQ')}
          {isCollapsed ? '' : 'FAQ'}
        </div>
      )}

      <div
        className={
          isCollapsed
            ? 'sidebar-container__logout-button-collapsed'
            : 'sidebar-container__logout-button'
        }
        onClick={() => logOut()}
        id="logout-icon"
      >
        <img
          className={
            isCollapsed
              ? 'icon-with-right-margin sidebar-container__logout-button-collapsed__image'
              : 'icon-with-right-margin sidebar-container__logout-button__image'
          }
          src={logout}
          alt="logout"
        />
        {isCollapsed ? '' : 'Logout'}
      </div>
      <div className="sidebar-container__company-name">
        {isCollapsed ? null : (
          <>
            <img
              className="icon-with-right-margin"
              src={companyLogo}
              alt="logo"
            />{' '}
            {Cookies.get('displayName')}
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;
