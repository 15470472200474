const firstSpaceRemover = (input: string): string => {
  if (input?.charAt(0) != ' ') {
    return input;
  }
  return firstSpaceRemover(input.slice(1));
};

const trailingSpaceRemover = (input: string): string => {
  if (input?.charAt(input.length - 1) != ' ') {
    return input;
  }
  return trailingSpaceRemover(input.slice(0, -1));
};

export { firstSpaceRemover };

export default trailingSpaceRemover;
