//Fundamentals
import React, { useState } from 'react';

// Styles
import './companyDetails.scss';

//Images
import backBtn from '../../../assets/register/back-btn.png';

interface CompanyDetails {
  onCompanyDetailsBack: any;
  inputValues: any;
  setInputValues: any;
  signUpSubmit: any;
  requestInProgress: boolean;
}

const CompanyDetails = ({
  onCompanyDetailsBack,
  inputValues,
  setInputValues,
  signUpSubmit,
  requestInProgress,
}: CompanyDetails) => {
  const [urlInvalid, setUrlInvalid] = useState(false);

  const handleBack = () => {
    // Call the onBack function passed as a prop to go back to the sign-up page
    onCompanyDetailsBack();
  };

  const cursorStyle = () => (requestInProgress ? { cursor: 'wait' } : null);

  const isURLValid = (url: string) => {
    // Regular expression to check if the URL has a valid TLD
    const urlPattern = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i;
    return urlPattern.test(url);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const isCompanyWebsiteValid = isURLValid(inputValues.companyWebsite);

    if (!isCompanyWebsiteValid && inputValues.companyWebsite.length > 0) {
      // Display an error message or handle the invalid URL case
      setUrlInvalid(true);
      return;
    } else {
      signUpSubmit();
    }
  };

  return (
    <div className={`company-details-block`}>
      <div className="company-details-block__header">
        <img src={backBtn} style={cursorStyle()} onClick={handleBack}></img>
        <h1>Company Details</h1>
      </div>
      <form
        onSubmit={handleSubmit}
        action="#"
        className="company-details-block__form"
      >
        <div className="company-details-block__form__company-name">
          <label htmlFor="company-name" style={cursorStyle()}>
            Company Name
          </label>
          <input
            type="text"
            className="onboarding-input"
            placeholder="Add your company name"
            id="company-name"
            style={cursorStyle()}
            value={inputValues.companyName}
            onChange={(e) =>
              setInputValues((prevValues: any) => ({
                ...prevValues,
                companyName: e.target.value,
              }))
            }
            required
          />
        </div>

        <div className="company-details-block__form__company-website">
          <label htmlFor="company-website" style={cursorStyle()}>
            Company Website
          </label>
          {urlInvalid ? (
            <p className="error-message">Please enter a valid url</p>
          ) : null}
          <input
            type="text"
            className="onboarding-input"
            placeholder="Add your company website"
            id="company-website"
            style={cursorStyle()}
            value={inputValues.companyWebsite}
            onChange={(e) =>
              setInputValues((prevValues: any) => ({
                ...prevValues,
                companyWebsite: e.target.value,
              }))
            }
          />
        </div>
        <button
          type="submit"
          className="complete-signup-btn"
          disabled={requestInProgress}
          style={cursorStyle()}
        >
          Start your 7 day free trial
        </button>
        <span className="company-details-block__form__policies">
          By clicking continue you agree to our{' '}
          <a
            href="https://ctrlroom.ai/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://ctrlroom.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy.
          </a>
        </span>
      </form>
    </div>
  );
};

export default CompanyDetails;
