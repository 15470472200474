//Fundamentals
import React, { useState, useEffect } from 'react';
import auth from '../../../firebase/init';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { showErrorToast } from '../../../utils/toast';

// Styles
import './signUp.scss';

//Images
import logo from '../../assets/sideBar/logo.png';
import eyeIcon from '../../../assets/register/eye-icon.png';
import eyeOpenIcon from '../../../assets/register/eye-open-icon.png';
import { Link } from 'react-router-dom';

interface SignUpBlockProps {
  onSignUpComplete: any;
  inputValues: any;
  setInputValues: any;
}

const SignUpBlock = ({
  onSignUpComplete,
  inputValues,
  setInputValues,
}: SignUpBlockProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [uppercase, setUppercase] = useState(null);
  const [greaterThan7, setGreaterThan7] = useState(null);
  const [specialCharacter, setSpecialCharacter] = useState(null);
  const [emailError, setEmailError] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  //Final submit
  const emailCheck = (e: any) => {
    e.preventDefault();

    if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      setEmailError('invalidEmail');
      return;
    }
    fetchSignInMethodsForEmail(auth, inputValues.email)
      .then((signInMethods: any) => {
        if (signInMethods.length === 0) {
          setEmailError(null);
          handleSubmit(e);
        } else {
          setEmailError('emailInUse');
        }
      })
      .catch((error: string) => {
        showErrorToast('Something went wrong.');
      });
  };

  useEffect(() => {
    // Check if all password criteria are met
    if (uppercase && greaterThan7 && specialCharacter) {
      // If all criteria are met, trigger onSignUpComplete
      onSignUpComplete();
    }
  }, [
    uppercase,
    greaterThan7,
    specialCharacter,
    firstName,
    lastName,
    email,
    onSignUpComplete,
  ]);

  const checkPasswordCriteria = (inputPassword: string) => {
    setUppercase(/(?=.*[A-Z])/.test(inputPassword));
    setGreaterThan7(inputPassword.length > 7);
    setSpecialCharacter(/(?=.*[!@#$%^&*()_+])/.test(inputPassword));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Perform any necessary form handling logic here

    checkPasswordCriteria(password || inputValues.password);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="sign-up-block">
      <form action="#" onSubmit={emailCheck} className="sign-up-block__form">
        <h1>Sign Up</h1>

        <div className="sign-up-block__form__container">
          <label htmlFor="first-name">First Name</label>
          <input
            type="first-name"
            id="first-name"
            placeholder="First Name"
            className="sign-up-block__form__container__first-name onboarding-input"
            required
            value={inputValues.firstName}
            onChange={(e) =>
              setInputValues((prevValues: any) => ({
                ...prevValues,
                firstName: e.target.value,
              }))
            }
          />
        </div>

        <div className="sign-up-block__form__container">
          <label htmlFor="last-name">Last Name</label>
          <input
            type="last-name"
            id="last-name"
            placeholder="Last Name"
            className="sign-up-block__form__container__last-name onboarding-input"
            required
            value={inputValues.lastName}
            onChange={(e) =>
              setInputValues((prevValues: any) => ({
                ...prevValues,
                lastName: e.target.value,
              }))
            }
          />
        </div>

        <div className="sign-up-block__form__container">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            id="email"
            placeholder="email@example.com"
            className="sign-up-block__form__container__email onboarding-input"
            required
            value={inputValues.email}
            onChange={(e) =>
              setInputValues((prevValues: any) => ({
                ...prevValues,
                email: e.target.value,
              }))
            }
          />
          {emailError === 'emailInUse' ? (
            <div className="email-already-in-use-error">
              Email already in use
            </div>
          ) : emailError === 'invalidEmail' ? (
            <div className="email-already-in-use-error">Invalid Email</div>
          ) : null}
        </div>

        <div className="sign-up-block__form__container">
          <label
            htmlFor="password"
            className="sign-up-block__form__container__password-label"
          >
            Password
          </label>
          <span>
            <img
              src={passwordVisible ? eyeOpenIcon : eyeIcon}
              className="eye-icon"
              id="eyeIcon"
              onClick={togglePasswordVisibility}
            />
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              placeholder="Create your password"
              className={`sign-up-block__container__password onboarding-input ${
                passwordError ? 'error' : ''
              }`}
              value={inputValues.password}
              onChange={(e) => {
                setPassword(e.target.value);
                setInputValues((prevValues: any) => ({
                  ...prevValues,
                  password: e.target.value,
                }));
              }}
            />
          </span>
          <p>{passwordError}</p>

          <p>
            <span
              className={
                uppercase
                  ? 'successful'
                  : uppercase === false
                  ? 'failed'
                  : 'original'
              }
            >
              One uppercase
            </span>
            ,{' '}
            <span
              className={
                greaterThan7
                  ? 'successful'
                  : greaterThan7 === false
                  ? 'failed'
                  : 'original'
              }
            >
              more than 7 characters
            </span>
            ,{' '}
            <span
              className={
                specialCharacter
                  ? 'successful'
                  : specialCharacter === false
                  ? 'failed'
                  : 'original'
              }
            >
              one special character
            </span>
          </p>
        </div>

        <button type="submit" className="sign-up-block__form__submit">
          Launch
        </button>
      </form>
      <div className="sign-up-block__sign-in">
        <p>Already have an account?</p>
        <Link to="/">Sign in</Link>
      </div>
    </div>
  );
};

export default SignUpBlock;
