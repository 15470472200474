// Fundamentals
import React, { useState } from 'react';

// Styles
import './totalLeads.scss';

//Components

//Images
import spacemen from '../../../../assets/analytics/spacemen.png';
import totalLeadsSaasImage from '../../../../assets/analytics/analytics-dashbaord-total-leads-saas.svg';
import Cookies from 'js-cookie';

interface ILeads {
  leads: number;
  loading: boolean;
  error: boolean;
  progress: any[];
}

const TotalLeads = ({ leads, loading, error, progress }: ILeads) => {
  const [showPercentage, setShowPercentage] = useState(false);

  const mouseEnter = function () {
    setShowPercentage(true);
  };

  const mouseLeave = function () {
    setShowPercentage(false);
  };

  return (
    <div className="meetings-scheduled-container">
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-total-leads">
          <div className="loading-block loading-text-large"></div>
          <div className="loading-block loading-text-small"></div>
          <div className="loading-block loading-text-small loading-interest"></div>
          <div className="loading-block loading-image-total-leads"></div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later
        </div>
      ) : (
        <div className="meetings-scheduled-container__left-text">
          <div>
            <div>
              <h4>
                Total Interested <br />
                Leads
              </h4>
              <p className="meetings-scheduled-container__left-text__total-leads">
                {leads}
              </p>
              {progress[0]?.display_goal === true ? (
                <div
                  className="meetings-scheduled-container__left-text__progress"
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                >
                  {showPercentage ? (
                    <div className="meetings-scheduled-container__left-text__progress__percentage">
                      {((leads / progress[0]?.amount_promised) * 100).toFixed(
                        0
                      )}
                      %
                    </div>
                  ) : null}
                  <progress
                    max={progress[0].amount_promised}
                    value={leads}
                  ></progress>
                  <p>Lead Target: {progress[0].amount_promised}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {loading ? null : (
        <img
          src={
            Cookies.get('clientType') === 'SaaS'
              ? totalLeadsSaasImage
              : spacemen
          }
          alt=""
          className={` ${
            error || leads === 0
              ? 'meetings-scheduled-container__calendar'
              : 'null'
          } ${
            Cookies.get('clientType') === 'SaaS'
              ? 'meetings-scheduled-container__saas-image'
              : 'meetings-scheduled-container__spacemen'
          }`}
        />
      )}
    </div>
  );
};

export default TotalLeads;
