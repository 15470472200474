import React, { useState, useCallback, useEffect } from 'react';
import './billingTab.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Cookies from 'js-cookie';
import axios from 'axios';
import auth from '../../../firebase/init';
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';
import { SpinnerCircular } from 'spinners-react';

type ExtraCost = number | 'Contact Us';

interface Option {
  label: string;
  cost: ExtraCost;
  stripeId?: string;
  packageId?: string;
}

//Options for agency client amount
const clientOptions: Option[] = [
  { label: '3', cost: 0 },
  { label: '4', cost: 19 },
  { label: '5', cost: 38 },
  { label: '6', cost: 57 },
  { label: '7', cost: 76 },
  { label: '8', cost: 95 },
  { label: '9', cost: 114 },
  { label: '10', cost: 133 },
  { label: '11', cost: 152 },
  { label: '12', cost: 171 },
  { label: '13', cost: 190 },
  { label: '14', cost: 209 },
  { label: '15', cost: 228 },
  { label: '15 +', cost: 'Contact Us' },
];

//Options for agency contacts amount
const contactOptions: Option[] = [
  { label: '0', cost: 0 },
  { label: '10000', cost: 10 },
  { label: '20000', cost: 20 },
  { label: '30000', cost: 30 },
  { label: '40000', cost: 40 },
  { label: '50000', cost: 50 },
  { label: '60000', cost: 60 },
  { label: '70000', cost: 70 },
  { label: '80000', cost: 80 },
  { label: '90000', cost: 90 },
  { label: '100000', cost: 100 },
  { label: '110000', cost: 110 },
  { label: '120000', cost: 120 },
  { label: '130000', cost: 130 },
  { label: '140000', cost: 140 },
  { label: '150000', cost: 150 },
  { label: '150000 +', cost: 'Contact Us' },
];

//Options for starter package
const starterOptions: Option[] = [
  {
    label: '10 000',
    cost: 19,
    stripeId: 's19',
    packageId: 'price_1PSyiZDjsNnSWp2Nkxd5KyFh',
  },
  {
    label: '25 000',
    cost: 29,
    stripeId: 's29',
    packageId: 'price_1PSyiZDjsNnSWp2NAl8z4sQH',
  },
  {
    label: '50 000',
    cost: 55,
    stripeId: 's55',
    packageId: 'price_1PSyiZDjsNnSWp2NRq7MWBc7',
  },
  {
    label: '75 000',
    cost: 89,
    stripeId: 's89',
    packageId: 'price_1PSyiZDjsNnSWp2NcrlOqNHn',
  },
  {
    label: '100 000',
    cost: 109,
    stripeId: 's109',
    packageId: 'price_1PSyiZDjsNnSWp2Ngh6kX4vS',
  },
  {
    label: '150 000',
    cost: 149,
    stripeId: 's149',
    packageId: 'price_1PSyiZDjsNnSWp2NMQeeCZjk',
  },
  {
    label: '200 000',
    cost: 219,
    stripeId: 's219',
    packageId: 'price_1PSyiZDjsNnSWp2NlA8jlKD6',
  },
  { label: '200 000 +', cost: 'Contact Us' },
];

const SliderComponent = ({
  options,
  value,
  onChange,
  label,
}: {
  options: Option[];
  value: number;
  onChange: (value: number | number[]) => void;
  label: string;
}) => {
  const marks = options.reduce((acc, option, index) => {
    acc[index] = option.label;
    return acc;
  }, {} as { [key: number]: string });

  return (
    <div className="billing-tab__slider">
      <p>{label}</p>
      <Slider
        min={0}
        max={options.length - 1}
        marks={marks}
        onChange={onChange}
        value={value}
        handleStyle={{
          borderColor: 'transparent',
          height: 20,
          width: 20,
          marginLeft: -10,
          marginTop: -8,
          backgroundColor: 'var(--accent-color)',
          opacity: 1,
        }}
      />
      <div className="billing-tab__slider__totals">
        <p>{options[value].label}</p>
        <p>
          {options[value].cost === 'Contact Us'
            ? 'Contact Us'
            : `Extra $${options[value].cost}/mo`}
        </p>
      </div>
    </div>
  );
};

const BillingCard = ({
  title,
  selected,
  onSelect,
  children,
  onUpdate,
  paymentSet,
}: {
  title: string;
  selected: boolean;
  onSelect: () => void;
  children?: React.ReactNode;
  onUpdate: () => void;
  paymentSet: boolean;
}) => (
  <div
    className={`billing-tab__card ${selected ? 'selected' : 'not-selected'}`}
  >
    <div>
      <h2>{title}</h2>
      {children}
    </div>
    {selected ? (
      <button
        disabled={!paymentSet}
        className="sequence-button"
        onClick={onUpdate}
      >
        Update
      </button>
    ) : (
      <button
        disabled={!paymentSet}
        className="sequence-button"
        onClick={onSelect}
      >
        Select
      </button>
    )}
  </div>
);

const BillingTab = ({ billingData }: any) => {
  const [valueOfClients, setValueOfClients] = useState<number>(0); //This sets the starter amount for the slider
  const [valueOfContacts, setValueOfContacts] = useState<number>(0); //This sets the starter amount for the slider
  const [valueOfStarterContact, setValueOfStarterContacts] =
    useState<number>(0); //This sets the starter amount for the slider
  const [selectedCard, setSelectedCard] = useState<string>(''); //Just to highlight which package they are currently on
  const [selectedClients, setSelectedClients] = useState(null); //Sets the amount to send in the POST request to update the agency package
  const [selectedContacts, setSelectedContacts] = useState(null); //Sets the amount to send in the POST request to update the agency package
  const [doubleCheckShowing, setDoubleCheckShowing] = useState(false); //Making sure they want to update package
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const trialEndDate = new Date(billingData?.trial_end).toLocaleDateString();
  const [paymentMethodSet, setPaymentMethodSet] = useState(false);

  useEffect(() => {
    setSelectedCard(billingData?.package); //Sets the selected package
    setValueOfClients(
      findIndexForOption(clientOptions, billingData?.total_clients)
    ); //Sets the clients amount on the agency price card
    setValueOfContacts(
      findIndexForOption(contactOptions, billingData?.additional_contacts)
    ); //Sets the contacts amount on the agency price card
    if (billingData?.package === 'Starter') {
      setValueOfStarterContacts(
        findIndexForOption(starterOptions, billingData?.total_contacts)
      );
    }
    setSelectedClients(billingData?.additional_clients); //Sets the selected package to send in the post to update package
    setSelectedContacts(billingData?.additional_contacts); //Sets the selected package to send in the post to update package
    if (billingData?.payment_method === false) {
      setPaymentMethodSet(false);
    } else {
      setPaymentMethodSet(true);
    }
  }, [billingData]);

  const findIndexForOption = (options: Option[], label: string | number) => {
    const normalizedLabel = label?.toString().replace(/\s/g, '');
    const index = options.findIndex(
      (option) => option.label.replace(/\s/g, '') === normalizedLabel
    );
    return index === -1 ? 0 : index; // Default to 0 if not found
  };

  const updatePackage = () => {
    setLoading(true);
    let data = {};
    if (selectedCard === 'Free') {
      data = {
        uuid: Cookies.get('parent'),
        products: [{ price: 'price_1PSyiZDjsNnSWp2NemYTy1q3', quantity: 1 }],
      };
    } else if (selectedCard === 'Agency') {
      data = {
        uuid: Cookies.get('parent'),
        products: [
          {
            price: 'price_1PTJIRDjsNnSWp2N8kn3SoNB',
            quantity: selectedClients,
          },
          {
            price: 'price_1PSznjDjsNnSWp2NULOot76s',
            quantity: selectedContacts,
          },
          { price: 'price_1PSzDmDjsNnSWp2NrVL7nRDU', quantity: 1 },
        ],
      };
    } else if (selectedCard === 'Starter') {
      data = {
        uuid: Cookies.get('parent'),
        products: [
          {
            price: starterOptions[valueOfStarterContact].packageId, //Figure out which package ID to send from the starteOptions object
            quantity: 1,
          },
        ],
      };
    }
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/subscription',
            headers: { Authorization: 'Bearer ' + idToken },
            data: data,
          })
            .then((response) => {
              showSuccessToast('Package updated successfully!');
              setDoubleCheckShowing(false);
              setLoading(false);
            })
            .catch((error) => {
              showErrorToast('Package Updated failed, please try again later.');
              setDoubleCheckShowing(false);
              setLoading(false);
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  //Updates the values based the slider
  const handleChangeClients = useCallback((value: number | number[]) => {
    if (typeof value === 'number') {
      setValueOfClients(value);
      setSelectedClients(value);
    }
  }, []);

  //Updates the values based the slider
  const handleChangeContacts = useCallback((value: number | number[]) => {
    if (typeof value === 'number') {
      setValueOfContacts(value);
      setSelectedContacts(contactOptions[value].label);
    }
  }, []);

  //Updates the values based the slider
  const handleChangeStarterContacts = useCallback(
    (value: number | number[]) => {
      if (typeof value === 'number') {
        setValueOfStarterContacts(value);
        setSelectedContacts(null);
        setSelectedClients(null);
      }
    },
    []
  );

  //Make sure the client wants to upgrade
  const requestDoubleCheck = () => {
    setDoubleCheckShowing(true);
  };

  const closeDoubleCheck = () => {
    setDoubleCheckShowing(false);
  };

  //Calculates the new price the client will pay
  const calculateTotalPrice = useCallback(() => {
    const basePrice = 49;

    const clientCost = clientOptions[valueOfClients].cost;
    const contactCost = contactOptions[valueOfContacts].cost;

    if (clientCost === 'Contact Us' || contactCost === 'Contact Us') {
      return 'Contact Us';
    }

    return basePrice + (clientCost as number) + (contactCost as number);
  }, [valueOfClients, valueOfContacts]);

  return (
    <div className="billing-tab">
      <BillingCard
        title="Free"
        selected={selectedCard === 'Free'}
        onSelect={() => {
          setSelectedCard('Free');
        }}
        onUpdate={requestDoubleCheck}
        paymentSet={paymentMethodSet}
      >
        <ol className="billing-tab__card__list">
          <li>1x Smartlead API integration</li>
          <li>Setup support</li>
          <li>Data on 5000 contacts</li>
        </ol>
      </BillingCard>

      <BillingCard
        title="Starter"
        selected={selectedCard === 'Starter'}
        onSelect={() => setSelectedCard('Starter')}
        onUpdate={requestDoubleCheck}
        paymentSet={paymentMethodSet}
      >
        <h3>
          Current Price: $
          {billingData?.status === 'trialing'
            ? '0'
            : billingData?.current_price}{' '}
          | New Price:{' '}
          {starterOptions[valueOfStarterContact].cost === 'Contact Us'
            ? 'Contact Us'
            : `$${starterOptions[valueOfStarterContact].cost}`}
        </h3>
        <div className="trial-end-date">
          {billingData?.status === 'trialing'
            ? `Trial period ends ${trialEndDate}`
            : null}
        </div>
        <SliderComponent
          options={starterOptions}
          value={valueOfStarterContact}
          onChange={handleChangeStarterContacts}
          label="Number of Contacts:"
        />
        <ol className="billing-tab__card__list">
          <li>1x Smartlead API integration</li>
          <li>Setup support</li>
        </ol>
      </BillingCard>

      <BillingCard
        title="Agency"
        selected={selectedCard === 'Agency'}
        onSelect={() => setSelectedCard('Agency')}
        onUpdate={requestDoubleCheck}
        paymentSet={paymentMethodSet}
      >
        <h3>
          Current Price: $
          {billingData?.status === 'trialing'
            ? '0'
            : billingData?.current_price}{' '}
          | New Price:{' '}
          {calculateTotalPrice() === 'Contact Us'
            ? ' Contact Us'
            : ` $${calculateTotalPrice()}`}
        </h3>
        <div className="trial-end-date">
          {billingData?.status === 'trialing'
            ? `Trial period ends ${trialEndDate}`
            : null}
        </div>

        <SliderComponent
          options={clientOptions}
          value={valueOfClients}
          onChange={handleChangeClients}
          label="Number of Clients:"
        />
        <SliderComponent
          options={contactOptions}
          value={valueOfContacts}
          onChange={handleChangeContacts}
          label="Number of extra contacts:"
        />
        <ol className="billing-tab__card__list">
          <li>1x Smartlead API integration</li>
          <li>Setup support</li>
          <li>
            Client Access (separate logins per client, they view only their
            campaigns) $19/mo per client
          </li>
          <li>Additional contacts at $10 per 10 000 contacts</li>
        </ol>
      </BillingCard>
      {doubleCheckShowing ? (
        <div className="billing-tab__double-check">
          <h3>Please confirm final package</h3>
          <div>
            <button
              className="sequence-button"
              onClick={() => {
                updatePackage();
              }}
            >
              Yes
            </button>
            {loading ? (
              <SpinnerCircular
                size={40}
                thickness={200}
                speed={100}
                color="var(--sliderEnd)"
                secondaryColor="var(--sliderStart)"
                className="sign-in-spinner"
              />
            ) : null}

            <button
              className="sequence-button"
              onClick={() => {
                closeDoubleCheck();
              }}
            >
              No
            </button>
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default BillingTab;
