import React, { useEffect, useState } from 'react';
import './showHideColumns.scss';

interface ILabel {
  label: string;
}

interface IShowHide {
  hideCountryColumn: boolean;
  setHideCountryColumn?: (hideCountry: boolean) => void;
  hidePhoneColumn: boolean;
  setHidePhoneColumn?: (hideNumber: boolean) => void;
}

const columnHeadings: string[] = ['Country', 'Phone'];

const ShowHideColumns = ({
  hideCountryColumn,
  setHideCountryColumn,
  hidePhoneColumn,
  setHidePhoneColumn,
}: IShowHide) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const Checkbox = ({ label }: ILabel) => {
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
      switch (label) {
        case 'Country':
          setSelectedIndex(!hideCountryColumn);
          break;
        case 'Phone':
          setSelectedIndex(!hidePhoneColumn);
          break;
        default:
        // Do nothing
      }
    }, [hideCountryColumn, hidePhoneColumn]);

    const handleSelection = () => {
      switch (label) {
        case 'Country':
          setHideCountryColumn(!hideCountryColumn);
          break;
        case 'Phone':
          setHidePhoneColumn(!hidePhoneColumn);
          break;
        default:
        // Do nothing
      }
    };

    return (
      <div className="checkbox-wrapper" onClick={handleSelection}>
        <label>
          <input type="checkbox" className={selectedIndex ? 'checked' : ''} />
          <p>{label}</p>
        </label>
      </div>
    );
  };

  const FiltersDropDown = () => {
    return (
      <div className="filters-drop-down-container">
        {columnHeadings.map((item, index) => {
          return <Checkbox key={index} label={item} />;
        })}
      </div>
    );
  };

  return (
    <div className="showHideColumns-component-container">
      <div
        className="filter-by-button"
        onClick={() => setDropDownVisible(!dropDownVisible)}
      >
        +/- Columns
      </div>
      {dropDownVisible ? <FiltersDropDown /> : null}
    </div>
  );
};

export default ShowHideColumns;
