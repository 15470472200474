// Fundamentals
import React from 'react';

// Styles
import './sequenceSettings.scss';
import SequenceToggle from '../../components/sequencesPage/sequenceToggle/sequenceToggle';

//Components

const SequencesSettings = () => {
  return (
    <div className="sequence-settings-container">
      <h1 className="sequence-settings-container__page-header">
        Sequence Settings
      </h1>
      <div className="sequence-settings-container__settings-body-top">
        <div className="sequence-settings-container__settings-body-top__setting">
          <p>Active only</p>
          <SequenceToggle />
        </div>
        <div className="sequence-settings-container__settings-body-top__setting">
          <p>Days running</p>
          <input
            type="text"
            placeholder="Monday, Tuesday, Wednesday, Thursday, Friday"
          />
        </div>
        <div className="sequence-settings-container__settings-body-top__setting">
          <p>Accepted email statuses</p>
          <input type="text" placeholder="Deliverable, Risky, Unknown" />
        </div>
      </div>
      <div className="sequence-settings-container__settings-body-bottom">
        <p>Sequence Copy</p>
        <div className="sequence-settings-container__settings-body-bottom__sequence-copy">
          <input
            type="text"
            placeholder="https://docs.google.com/document/d/1qmg7ktRO..."
          />
          <button className="sequence-button">Copy link</button>
          <button className="sequence-button">View doc</button>
        </div>
      </div>
      <div className="sequence-settings-container__settings-finalize-buttons">
        <button className="finalize-button-cancel cursor">Cancel</button>
        <button className="finalize-button-update cursor">Update</button>
      </div>
    </div>
  );
};

export default SequencesSettings;
