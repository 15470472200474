// Fundamentals
import React, { useEffect, useState } from 'react';
import auth from '../../../firebase/init';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { SpinnerCircular } from 'spinners-react';
import trailingSpaceRemover, {
  firstSpaceRemover,
} from '../../../utils/trailingSpaceRemover';

// Styles
import './teamMemberSettings.scss';

//Components
import TeamMemberInvitations from './invitations/teamMemberInvitations';
import Members from './members/members';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';

interface EmailDropdownProps {
  options: string[];
  selectedOption: string;
  onChange: (option: string) => void;
  isDropdownOpen: boolean;
  toggleDropdown: () => void;
}

const TeamMemberSettings = () => {
  const user = auth.currentUser;
  const options = ['Editor', 'Viewer'];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const [teamMemberDetails, setTeamMemberDetails] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/v2/team-members/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTeamMemberDetails(response.data.data);
            })
            .catch(() => {
              // Handle error
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  }, []);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const EmailDropdown = ({
    options,
    selectedOption,
    onChange,
    isDropdownOpen,
    toggleDropdown,
  }: EmailDropdownProps) => {
    const handleOptionClick = (option: string) => {
      onChange(option);
    };

    return (
      <div className="email-invite-dropdown">
        <div
          className="email-invite-dropdown__selected-option"
          onClick={toggleDropdown}
        >
          {selectedOption}
          <div className="email-invite-dropdown__selected-option__arrow-container">
            <div className={arrowClass}></div>
          </div>
        </div>
        {isDropdownOpen && (
          <ul className="email-invite-dropdown__dropdown-options">
            {options.map((option) => (
              <li
                key={option}
                className={`email-invite-dropdown__dropdown-options__dropdown-option ${
                  selectedOption === option ? 'selected' : ''
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const [selectedTeamButton, setSelectedTeamButton] = useState<string | null>(
    'Team Members'
  );

  const handleButtonClick = (buttonName: string) => {
    setSelectedTeamButton(buttonName);
  };

  const renderComponent = () => {
    switch (selectedTeamButton) {
      case 'Team Members':
        return <Members teamMemberDetails={teamMemberDetails} />;
      case 'Invitations':
        return <TeamMemberInvitations teamMemberDetails={teamMemberDetails} />;
      default:
        return null;
    }
  };

  const arrowClass = isDropdownOpen ? 'arrow arrow-up' : 'arrow';

  const inviteMember = (e: any) => {
    e.preventDefault();
    const endpoint =
      Cookies.get('clientType') === 'SaaS'
        ? 'api/v2/team/send'
        : 'api/team/send';
    if (user !== null) {
      const userEmail = user.email;
      const data = {
        uuid: Cookies.get('fuelStationId'),
        email: trailingSpaceRemover(firstSpaceRemover(email)),
        role: 'Editor',
        invited_by: userEmail,
      };
      setLoading(true);
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'POST',
              url: apiEndpoint + endpoint,
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then(() => {
                setLoading(false);
                showSuccessToast(
                  `${trailingSpaceRemover(
                    firstSpaceRemover(email)
                  )} was invited successfully!`
                );
              })
              .catch(() => {
                showErrorToast('Invitation failed.');
                setLoading(false);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    } else {
      showErrorToast('Auth token expired. Please log in again.');
    }
  };

  // Filter in rows with any null fields
  const filteredTeamMemberDetails = teamMemberDetails?.filter(
    (member: any) =>
      member.first_name === null ||
      member.last_name === null ||
      member.user_email === null
  );

  // Check if there are any nulls
  const hasNulls = filteredTeamMemberDetails?.length > 0;

  return (
    <div className="team-member-settings-container">
      {loading ? (
        <div className="team-member-settings-container__spinner-container">
          <SpinnerCircular
            size={30}
            thickness={200}
            speed={100}
            color="var(--sliderEnd)"
            secondaryColor="var(--sliderStart)"
            className="sign-in-spinner"
          />
        </div>
      ) : null}
      <ToastContainer />
      <h1>Invite Client Email to View Dashbaord</h1>
      <div className="team-member-settings-container__invite-container">
        <p>Email</p>
        <div className="team-member-settings-container__invite-container__invite">
          <form
            onSubmit={inviteMember}
            className="team-member-settings-container__invite-container__invite__email"
          >
            <input
              type="email"
              placeholder="Enter email address"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="sequence-button">
              Send Invite
            </button>
          </form>
        </div>
        <div className="team-member-settings-container__invite-container__explanation">
          They will receive an email to create their account for them to login
          and view this dashboard
        </div>
      </div>
      <div className="team-member-settings-container__members-invitations">
        <button
          className={`settings-button ${
            selectedTeamButton === 'Team Members' ? 'active-settings-tab' : ''
          }`}
          onClick={() => handleButtonClick('Team Members')}
        >
          Members
        </button>
        {hasNulls ? (
          <button
            className={`settings-button ${
              selectedTeamButton === 'Invitations' ? 'active-settings-tab' : ''
            }`}
            onClick={() => handleButtonClick('Invitations')}
          >
            Invitations
          </button>
        ) : null}
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
};

export default TeamMemberSettings;
