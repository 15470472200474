// Fundamentals
import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
} from 'recharts';

// Images
import increaseArrow from '../../../../assets/dashboard/analytics/increase-arrow.png';
import decreaseArrow from '../../../../assets/dashboard/analytics/decrease-arrow.png';

// Styles
import './performanceRates.scss';

// Components

const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload as DataPoint;

    return (
      <div className="custom-tooltip">
        <p className="label">{`Type: ${
          dataPoint.cumulative_leads ? 'Total Leads' : 'Forecast'
        }`}</p>
        <p className="label">{`Date: ${dataPoint.Date}`}</p>
        <p className="label">
          {`value: ${
            dataPoint?.cumulative_leads === 0
              ? 0
              : dataPoint?.cumulative_leads?.toFixed(0)
          }`}
        </p>
      </div>
    );
  }

  return null;
};

interface DataPoint {
  cumulated_forecasted_upper: number;
  cumulative_forecasted: number;
  cumulative_forecasted_lower: number;
  cumulative_leads: number;
  monthly_forecasted: number;
  monthly_observed: number;
  Date: string;
  value: number;
  type_of_value: string;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: DataPoint }>;
}

interface IData {
  predictiveLeadsData: any;
  emailRatesData: any;
  loading: boolean;
  error: boolean;
}

const PerformanceRates = ({
  predictiveLeadsData,
  emailRatesData,
  loading,
  error,
}: IData) => {
  const filteredData = predictiveLeadsData?.filter(
    (entry: any) =>
      entry.cumulative_forecasted !== null || entry.cumulative_interest !== 0
  );
  const newData =
    filteredData?.length > 0
      ? [
          {
            date: filteredData[0]?.date,
            cumulative_interest: 0,
            client: filteredData[0]?.client,
            cumulative_forecasted: filteredData[0]?.cumulative_forecasted,
          },
          ...filteredData,
        ]
      : [];

  return (
    <>
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-performance-rates">
          <div className="loading-block loading-container-performance-rates__header"></div>
          <div className="loading-container-performance-rates__middle">
            <div className="loading-block loading-container-performance-rates__middle__chart"></div>
            <div className="loading-block loading-container-performance-rates__middle__data"></div>
          </div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later
        </div>
      ) : predictiveLeadsData?.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <div className="response-rates-graph-container">
          <div className="response-rates-graph-container__disclaimer-graph">
            <h3>Total Leads</h3>
            <ResponsiveContainer
              height={240}
              className="response-rates-graph-container__disclaimer-graph__graph"
            >
              <LineChart
                data={newData}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid vertical={false} horizontal={false} />
                <XAxis
                  dataKey="Date"
                  type="category"
                  label={{
                    value: 'Date',
                    position: 'insideBottom',
                    fill: 'var(--blackTextToWhiteText)',
                  }}
                  tickLine={false}
                  stroke="var(--blackTextToWhiteText)"
                  height={50}
                  tick={{ fontSize: 12 }}
                />
                <YAxis
                  label={{
                    value: 'Total Leads',
                    angle: -90,
                    fill: 'var(--blackTextToWhiteText)',
                  }}
                  width={80}
                  axisLine={false}
                  tickLine={false}
                  stroke="var(--blackTextToWhiteText)"
                  tick={{ fontSize: 12 }}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  allowEscapeViewBox={{ x: true, y: true }}
                  position={{ y: 20 }}
                />
                <YAxis
                  label={{
                    value: 'Total Leads',
                    angle: -90,
                    fill: 'var(--blackTextToWhiteText)',
                  }}
                  width={80}
                  axisLine={false}
                  tickLine={false}
                  stroke="var(--blackTextToWhiteText)"
                  tick={{ fontSize: 12 }}
                />
                <Tooltip
                  content={<CustomTooltip />}
                  allowEscapeViewBox={{ x: true, y: true }}
                  position={{ y: 20 }}
                />
                <Line
                  type="monotone"
                  dataKey="cumulative_leads"
                  name="Total Leads"
                  dot={false}
                  stroke="var(--greyBorderToBlueBorder)"
                  strokeWidth={1.5}
                  connectNulls
                />
                <Line
                  type="monotone"
                  dataKey="cumulative_forecasted"
                  dot={false}
                  stroke="var(--greyBorderToBlueBorder)"
                  strokeDasharray="3 3"
                  strokeWidth={1.5}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="response-rates-graph-container__right-text-container">
            {emailRatesData?.map((values: any, key: number) => {
              return (
                <div key={key}>
                  <div className="response-rates-graph-container__right-text-container__right-text">
                    <div className="response-rates-graph-container__right-text-container__right-text__grid">
                      <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item">
                        <h5>Conversion Rate</h5>
                        <p>{values.conversion_rate}%</p>
                        <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item__change-value-container">
                          {values.conversion_change === 0 ? (
                            <span className="blue-line"></span>
                          ) : (
                            <img
                              src={
                                values.conversion_change > 0
                                  ? increaseArrow
                                  : decreaseArrow
                              }
                              alt=""
                            />
                          )}

                          <p>{values.conversion_change?.toFixed(2)}%</p>
                        </div>
                      </div>
                      <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item">
                        <h5>Reply Rate</h5>
                        <p>{values.reply_rate}%</p>
                        <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item__change-value-container">
                          {values.reply_change === 0 ? (
                            <span className="blue-line"></span>
                          ) : (
                            <img
                              src={
                                values.reply_change > 0
                                  ? increaseArrow
                                  : decreaseArrow
                              }
                              alt=""
                            />
                          )}

                          <p>{values.reply_change?.toFixed(2)}%</p>
                        </div>
                      </div>
                      <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item">
                        <h5>Interested Rate</h5>
                        <p>{values.interested_rate}%</p>
                        <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item__change-value-container">
                          {values.interested_change === 0 ? (
                            <span className="blue-line"></span>
                          ) : (
                            <img
                              src={
                                values.interested_change > 0
                                  ? increaseArrow
                                  : decreaseArrow
                              }
                              alt=""
                            />
                          )}

                          <p>{values.interested_change?.toFixed(2)}%</p>
                        </div>
                      </div>
                      <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item">
                        <h5>Contacts Emailed</h5>
                        <p>{values.total_contacted}</p>
                        <div className="response-rates-graph-container__right-text-container__right-text__grid__grid-item__change-value-container">
                          {values.total_contacted_change === 0 ? (
                            <span className="blue-line"></span>
                          ) : (
                            <img
                              src={
                                values.contacted_change > 0
                                  ? increaseArrow
                                  : decreaseArrow
                              }
                              alt=""
                            />
                          )}

                          <p>{values.contacted_change}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default PerformanceRates;
