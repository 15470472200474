// Fundamentals
import React from 'react';

// Styles
import './companyProfileSettings.scss';

//Images
import companyProfilePhoto from '../../../assets/settingsPages/company-profile-shot.svg';
import profileEdit from '../../../assets/settingsPages/profile-settings-edit.svg';
import profileDelete from '../../../assets/settingsPages/profile-settings-delete.svg';

const CompanyProfileSettings = () => {
  return (
    <div className="company-profile-settings-container">
      <h1>Company profile</h1>
      <div className="company-profile-settings-container__body">
        <div className="company-profile-settings-container__body__left-side">
          <img src={companyProfilePhoto} alt="company-profile-photo" />
          <div className="company-profile-settings-container__body__left-side__photo-options">
            <img src={profileEdit} alt="" />
            <img src={profileDelete} alt="" />
          </div>
        </div>
        <div className="company-profile-settings-container__body__right-side">
          <div className="company-profile-settings-container__body__right-side__name">
            <div>
              <label htmlFor="name">Company Name</label>
              <input type="text" placeholder="Grow BPO" />
            </div>
          </div>
          <div className="company-profile-settings-container__body__right-side__settings-option company-profile-settings-container__body__right-side__settings-option__email">
            <label htmlFor="companyName">Company Website</label>
            <input type="text" placeholder="www.companysite.com" />
          </div>
          <div className="company-profile-settings-container__body__right-side__settings-option company-profile-settings-container__body__right-side__settings-option__apollo-key">
            <label htmlFor="apollo">Apollo API Key</label>
            <input
              type="text"
              placeholder="qoawejfraopiwefoapwejfoajeofjaosfjaosidjf2"
            />
          </div>
          <div className="company-profile-settings-container__body__right-side__settings-option company-profile-settings-container__body__right-side__settings-option__contacts">
            <label htmlFor="contact">Total Contacts to Add per Day</label>
            <input type="text" placeholder="200" />
          </div>
          <button className="sequence-button">Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileSettings;
