import { createSlice } from '@reduxjs/toolkit';

const sequenceStatusSlice = createSlice({
  name: 'sequenceStatusActive',
  initialState: {
    leads: null,
    sequences: null,
    sequenceVolumes: null,
    totalLeads: null,
    topPersona: null,
    topSequence: null,
    emailRates: null,
    progressData: null,
    projectedLeads: null,
  },
  reducers: {
    setLeads(state, payload) {
      state.leads = payload;
    },
    setEmailRates(state, payload) {
      state.emailRates = payload;
    },
    setSequences(state, payload) {
      state.sequences = payload;
    },
    setSequenceVolumes(state, payload) {
      state.sequenceVolumes = payload;
    },
    setTotalLeads(state, payload) {
      state.totalLeads = payload;
    },
    setTopPersona(state, payload) {
      state.topPersona = payload;
    },
    setTopSequence(state, payload) {
      state.topSequence = payload;
    },
    setProgressData(state, payload) {
      state.progressData = payload;
    },
    setProjectedLeads(state, payload) {
      state.projectedLeads = payload;
    },
  },
});

export const sequenceStatusActions = sequenceStatusSlice.actions;

export default sequenceStatusSlice;
