import { createSlice } from '@reduxjs/toolkit';

const fuelStationIdSlice = createSlice({
  name: 'fuelStationId',
  initialState: {
    fuelId: null,
  },
  reducers: {
    setFuelId(state, payload) {
      state.fuelId = payload;
    },
  },
});

export const fuelStationIdActions = fuelStationIdSlice.actions;

export default fuelStationIdSlice;
