import React, { useEffect, useState } from 'react';

// Styles
import './analyticsDropdown.scss';

interface DropdownProps {
  options: string[];
  originalOption: string;
  clickHandler?: (selectedOption: string) => void;
  activeToggle?: boolean;
}

const AnalyticsDropdown = ({
  options,
  originalOption,
  clickHandler,
  activeToggle,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    setSelectedOption('All time');
  }, [activeToggle]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    clickHandler(option);
  };

  return (
    <div className={`dropdown ${isOpen ? 'open' : ''}`}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        <div className="dropdown-header-selected">
          {selectedOption || originalOption}
        </div>

        <div className={`arrow ${isOpen ? 'up' : ''}`}></div>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option) => (
            <div
              key={option}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnalyticsDropdown;
