import React, { useState } from 'react';

// Styles
import './contactPageDropdown.scss';

interface DropdownProps {
  options: string[];
  contactId: string;
  sequence: string;
  originalOption: string;
  name: string;
  domain: string;
  selectedContactData: any;
  leadId: number;
  campaignId: number;
  requestContactStageUpdate?: (
    option: string,
    contactId: string,
    name: string,
    domain: string,
    sequence: string,
    selectedContactData: any,
    leadId: number,
    campaignId: number
  ) => void;
}

const ContactPageDropdown = ({
  options,
  contactId,
  sequence,
  originalOption,
  name,
  domain,
  selectedContactData,
  requestContactStageUpdate,
  leadId,
  campaignId,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    requestContactStageUpdate(
      option,
      contactId,
      sequence,
      name,
      domain,
      selectedContactData,
      leadId,
      campaignId
    );
    setIsOpen(false);
  };

  return (
    <div className={`contact-page-dropdown ${isOpen ? 'open' : ''}`}>
      <div
        className="contact-page-dropdown__dropdown-header"
        onClick={toggleDropdown}
      >
        {selectedOption || originalOption}
        <div className={`arrow ${isOpen ? 'up' : ''}`}></div>
      </div>
      {isOpen && (
        <div className="contact-page-dropdown__dropdown-options">
          {options.map((option) => (
            <div
              key={option}
              className="contact-page-dropdown__dropdown-options__dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContactPageDropdown;
