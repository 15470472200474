import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { isLoggedIn: false, isAdmin: false, stripeId: null },
  reducers: {
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    adminLogin(state) {
      state.isAdmin = true;
    },
    adminLogout(state) {
      state.isAdmin = false;
    },
    setStripeId(state, payload) {
      state.stripeId = payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
