// Fundamentals
import React from 'react';
import { SpinnerCircular } from 'spinners-react';

// Styles
import './changeContactStageModal.scss';

// Assets
import logo from '../../../assets/sideBar/logo.png';

interface IChangeContactStage {
  stage: string;
  updatingStage: boolean;
  onCancel?: () => void;
  handleChangeContactStage?: () => void;
}

const ChangeContactStageModal = ({
  stage,
  onCancel,
  updatingStage,
  handleChangeContactStage,
}: IChangeContactStage) => {
  return (
    <div className="change-contact-stage-modal-container">
      <div>
        <img src={logo} alt="Fueltofly Logo" />
      </div>
      {updatingStage ? (
        <SpinnerCircular
          size={40}
          thickness={200}
          speed={100}
          color="var(--sliderEnd)"
          secondaryColor="var(--sliderStart)"
          className="sign-in-spinner"
        />
      ) : null}
      <p>Are you sure you want to mark this contact as &quot;{stage}&quot;?</p>
      <div className="confirm-dnc-modal__button-row">
        <button
          className="change-contact-stage-modal-container__button-row__button change-contact-stage-modal-container__button-row__button--cancel"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="change-contact-stage-modal-container__button-row__button change-contact-stage-modal-container__button-row__button--confirm"
          onClick={handleChangeContactStage}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ChangeContactStageModal;
