// Fundamentals
import React from 'react';

// Styles
import './topSequence.scss';

//Components

//Images
import topSequenceImage from '../../../../assets/analytics/top_step.png';
import topSequenceSassImage from '../../../../assets/analytics/top-sequence-saas.svg';
import Cookies from 'js-cookie';

interface ITopSequence {
  topSequence: any[];
  loading: boolean;
  error: boolean;
}

const TopSequence = ({ topSequence, loading, error }: ITopSequence) => {
  return (
    <div className="top-sequence-container">
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container">
          <div className="loading-block loading-text-large"></div>
          <div className="loading-block loading-text-small"></div>
          <div className="loading-block loading-text-small loading-interest"></div>
          <div className="loading-block loading-image-top-sequence"></div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later
        </div>
      ) : topSequence.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <>
          <div>
            <h4>Top Campaign</h4>
            <p>{topSequence[0]?.Sequence}</p>
          </div>

          <h5 className="top-sequence-container__background-blue">
            <p>
              {Math.abs(topSequence[0]?.possible_conversation_rate).toFixed(2)}%
              Interest
            </p>
          </h5>
        </>
      )}
      {loading ? null : (
        <img
          src={
            Cookies.get('clientType') === 'SaaS'
              ? topSequenceSassImage
              : topSequenceImage
          }
          alt=""
          className="top-sequence-container__image"
        />
      )}
    </div>
  );
};

export default TopSequence;
