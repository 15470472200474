// Fundamentals
import React, {
  ReactChild,
  ReactChildren,
  cloneElement,
  useEffect,
  useState,
} from 'react';

// Styles
import './sideBarLayout.scss';

// Child Components
import SideBar from '../sideBar/sideBar';
import TopBar from '../topBar/topBar';

interface ISideBarPage {
  isAdmin: boolean;
  children: ReactChild | ReactChildren;
}

const SideBarLayout = ({ children, isAdmin }: ISideBarPage) => {
  //Setting global search state to be updated from either the topBar or the contacts page
  const [globalSearch, setGlobalSearch] = useState(false);

  const enhancedChildren = React.Children.map(children, (child) => {
    // Checking if the child is a valid element before cloning
    if (React.isValidElement(child)) {
      return cloneElement(child, { globalSearch, setGlobalSearch });
    } //Cloning the children to inject the globalSearch and setGlobalSearch props
    return child;
  });

  return (
    <>
      <div className={`sidebar-layout-container`}>
        <SideBar isAdmin={isAdmin} />
        <div className="sidebar-layout-container__right-elements">
          <TopBar
            isAdmin={isAdmin}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
          />
          <div className="sidebar-layout-container__right-elements__page-container">
            {enhancedChildren}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBarLayout;
