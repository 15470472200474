// Fundamentals
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager, SortDirection } from 'ag-grid-enterprise';
import Modal from '../../components/Modal/modal';
import auth from '../../firebase/init';
import { SpinnerCircular } from 'spinners-react';
import trailingSpaceRemover, {
  firstSpaceRemover,
} from '../../utils/trailingSpaceRemover';

//Components
import {
  TitleToolTip,
  EmailToolTip,
  CompanyToolTip,
  IndustryToolTip,
  SequenceToolTip,
} from '../../components/contactsTable/toolTips/toolTips';
import { showErrorToast, showSuccessToast } from '../../utils/toast';
import ContactPageDropdown from '../../components/contactPageDropDown/contactPageDropdown';
import ContactsTableDropdown from '../../components/contactsTable/contactsTableDropdown/contactsTableDropdown';

// Packages
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';

// Styles
import './contactPage.scss';

//Images
import LinkedIn from '../../assets/contacts/linkedin-icon.svg';
import InfoIcon from '../../assets/contacts/info-icon.svg';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { searchActions } from '../../redux/searchSlice';
import { contactDataActions } from '../../redux/contact-data-slice';

// Utils
import useDidUpdateEffect from '../../utils/didUpdateHook';
import ChangeContactStageModal from '../../components/contactsTable/changeContactStageModal/changeContactStageModal';
import ShowHideColumns from '../../components/contactsTable/showHideColumns/showHideColumns';
import { convertToObject } from 'typescript';

LicenseManager.setLicenseKey(
  'CompanyName=FueltoFly (Pty) Ltd,LicensedApplication=FueltoFly Control Room Contacts Table,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-038076,SupportServicesEnd=8_February_2024_[v2]_MTcwNzM1MDQwMDAwMA==afa247be47b0d0bb6f13616df90979f1'
);

interface contactProps {
  globalSearch?: boolean;
  setGlobalSearch?: any;
}

interface IContactSearchSelector {
  payload: boolean;
}

interface IContactsDataSelector {
  payload: [];
}

interface IStringSelector {
  payload: string;
}

interface ISearchSlice {
  contactSearch: IContactSearchSelector;
  contactsData: IContactsDataSelector;
  interestedContactsData: IContactsDataSelector;
  searchType: IStringSelector;
  firstNameSearchValue: IStringSelector;
  lastNameSearchValue: IStringSelector;
  companySearchValue: IStringSelector;
}

interface IContactDataSlice {
  clientContactStages: IContactsDataSelector;
}

interface IStore {
  search: ISearchSlice;
  contactData: IContactDataSlice;
}

const ContactPage = ({ globalSearch, setGlobalSearch }: contactProps) => {
  const dispatch = useDispatch();
  const contactsGridRef = useRef<any>();
  const user = auth.currentUser;
  const checkboxes = document.querySelectorAll('.toggle-btn');
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [contactStage, setContactStage] = useState('');
  const [contactStages, setContactStages] = useState([
    'Interested',
    'Meeting Request',
    'Not Interested',
    'Do Not Contact',
    'Information Request',
    'Out Of Office',
    'Wrong Person',
  ]);
  const [leadId, setLeadId] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [fetchingContactData, setFetchingContactData] = useState(true);
  const [showChangeContactStageModal, setShowChangeContactStageModal] =
    useState(false);

  const [contactId, setContactId] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactCompanyDomain, setContactCompanyDomain] = useState('');
  const [contactSequence, setContactSequence] = useState('');
  const [contactData, setContactData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [updatingStage, setUpdatingStage] = useState(false);
  const [error, setError] = useState(false);
  const [fetchingRestOfContactData, setFetchingRestOfContactData] =
    useState(false);

  const [hideCountryColumn, setHideCountryColumn] = useState(true);
  const [hidePhoneColumn, setHidePhoneColumn] = useState(true);

  const [selectedEmail, setSelectedEmail] = useState(0);
  const modalRef = useRef(null);

  const [leadsFilter, setLeadsFilter] = useState(null);
  const initialRender = useRef(true);
  const firstRender = useRef(true);
  const dateOptions = [
    'Last 7 days',
    'Last 14 days',
    'Last 30 days',
    'All time',
  ];
  const [selectedDateOption, setSelectedDateOption] = useState('All time');

  const [activeFilter, setActiveFilter] = useState(['']); //Set back to Leads when initial filter comes back

  const [viewReplyLoading, setViewReplyLoading] = useState(null);
  const [viewReplyError, setViewReplyError] = useState(null);

  const [replying, setReplying] = useState(false);
  const [warningShowing, setWarningShowing] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [emailBody, setEmailBody] = useState('');
  const [cc, setCc] = useState('');
  const [emailSendingLoading, setEmailSendingLoading] = useState(false);
  const [sendEmailConformation, setSendEmailConformation] = useState(false);

  // Redux States
  const contactStagesRedux = useSelector(
    (state: IStore) => state.contactData.clientContactStages
  );
  const contactSearchRedux = useSelector(
    (state: IStore) => state.search.contactSearch
  );
  const contactsDataRedux = useSelector(
    (state: IStore) => state.search.contactsData
  );
  const interestedContactsDataRedux = useSelector(
    (state: IStore) => state.search.interestedContactsData
  );
  const searchTypeRedux = useSelector(
    (state: IStore) => state.search.searchType
  );
  const firstNameSearchValueRedux = useSelector(
    (state: IStore) => state.search.firstNameSearchValue
  );
  const lastNameSearchValueRedux = useSelector(
    (state: IStore) => state.search.lastNameSearchValue
  );
  const companySearchValueRedux = useSelector(
    (state: IStore) => state.search.companySearchValue
  );

  useEffect(() => {
    //Waits for the redux data to be set in the topBar component and then sets the rowData to that
    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the effect on initial render
    }
    setFetchingContactData(false);
    setRowData(contactsDataRedux?.payload);
  }, [contactsDataRedux]);

  useEffect(() => {
    // check if redux store already has contacts data
    if (contactsDataRedux?.payload?.length > 0) {
      setRowData(contactsDataRedux?.payload);
      setFetchingContactData(false);
    } else if (contactsDataRedux?.payload?.length < 1) {
      setFetchingContactData(false);
    }

    const handleEscapeKeyPress = (event: any) => {
      if (event.key === 'Escape') {
        setIsModalVisible(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  const resetFilters = () => {
    const firstNameFilterComponent =
      contactsGridRef.current.api?.getFilterInstance('First_Name');
    firstNameFilterComponent.setModel({
      values: null,
    });
    contactsGridRef.current.api?.onFilterChanged();
    const lastNameFilterComponent =
      contactsGridRef.current.api?.getFilterInstance('Last_Name');
    lastNameFilterComponent.setModel({
      values: null,
    });
    contactsGridRef.current.api?.onFilterChanged();
    const companyFilterComponent =
      contactsGridRef.current.api?.getFilterInstance('Company');
    companyFilterComponent.setModel({
      values: null,
    });
    contactsGridRef.current.api?.onFilterChanged();
  };

  const filterByFirstName = () => {
    setTimeout(() => {
      const firstNameFilterComponent =
        contactsGridRef.current.api?.getFilterInstance('First_Name');
      firstNameFilterComponent.setModel({
        values: [firstNameSearchValueRedux.payload],
      });
      contactsGridRef.current.api?.onFilterChanged();
    }, 0);
  };

  const filterByLastName = () => {
    setTimeout(() => {
      const lastNameFilterComponent =
        contactsGridRef.current.api?.getFilterInstance('Last_Name');
      lastNameFilterComponent.setModel({
        values: [lastNameSearchValueRedux.payload],
      });
      contactsGridRef.current.api?.onFilterChanged();
    }, 0);
  };

  const filterByCompany = () => {
    setTimeout(() => {
      const companyFilterComponent =
        contactsGridRef.current.api?.getFilterInstance('Company');
      companyFilterComponent.setModel({
        values: [companySearchValueRedux.payload],
      });
      contactsGridRef.current.api?.onFilterChanged();
    }, 0);
  };

  //Below code is for rendering the image
  const firstLetter = (params: any) => {
    const firstName = params.data.First_Name;
    const lastName = params.data.Last_Name;

    return (
      <div className="contact-page-container__first-two-letters">
        <div className="contact-page-container__first-two-letters__letters">
          {firstName[0]}
          {lastName[0]}
        </div>
      </div>
    );
  };

  const companyNameWebsite = (params: any) => {
    const companyName = params.data.Company;
    const companyWebsite = params.data.Website;

    return companyName.length > 0 ? (
      <div className="contact-page-container__company-name-website">
        <a href={companyWebsite} rel="noreferrer" target="_blank">
          {companyName}
        </a>
      </div>
    ) : (
      <div className="contact-page-container__company-name-website">
        <a href={companyWebsite} rel="noreferrer" target="_blank">
          {companyWebsite}
        </a>
      </div>
    );
  };

  const linkedIn = (params: any) => {
    const linkedInUrl = params.data.Person_Linkedin_Url;

    if (linkedInUrl) {
      return (
        <div>
          <a
            href={linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="contact-page-container__linked-in"
          >
            <img src={LinkedIn} alt="" />
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const toggleChangeContactStageModalVisibility = () => {
    setShowChangeContactStageModal(!showChangeContactStageModal);
  };

  const requestContactStageUpdate = (
    stage: string,
    contactId: string,
    sequence: string,
    name: string,
    domain: string,
    selectedContactData: any,
    leadId: number,
    campaignId: number
  ) => {
    // add a timestamp value to the contactData object
    const tempContactData = {
      ...selectedContactData,
      Stage: stage,
      timestamp: getTimeStamp(),
    };
    setLeadId(leadId);
    setCampaignId(campaignId);
    setContactId(contactId);
    setContactStage(stage);
    setContactSequence(sequence);
    setContactName(name);
    setContactCompanyDomain(domain);
    setContactData(tempContactData);
    toggleChangeContactStageModalVisibility();
  };

  const getTimeStamp = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };

  const updateContactStageOnTable = (leadId: number, newStage: string) => {
    // Make a deep copy so we can later mutate it
    const tempData = JSON.parse(JSON.stringify(rowData));
    tempData.map((contact: any, index: number) => {
      if (contact.smartlead_id == leadId) {
        contact = { ...contact, Stage: newStage };
        tempData[index] = contact;
      }
    });
    setRowData(tempData);
    // timeout to avoid the screen from going blank
    setTimeout(function () {
      contactsGridRef.current.api?.setRowData(tempData);
    }, 0);
  };

  const updateContactStage = () => {
    const data = {
      uuid: Cookies.get('fuelStationId'),
      client: Cookies.get('displayName'),
      lead_id: leadId,
      contact_ids: [contactId],
      sequence: contactSequence,
      campaign_id: campaignId,
      new_stage: contactStage,
      contact_data: [contactData],
    };
    setUpdatingStage(true);

    const logData = {
      contact_names: [contactName],
      client_name: Cookies.get('displayName'),
      user_email: user.email,
      apollo_contact_ids: [contactId],
      blacklisted_domains: [contactCompanyDomain],
    };

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/update-contact-stage',
            headers: { Authorization: 'Bearer ' + idToken },
            data: data,
          })
            .then((response) => {
              setUpdatingStage(false);
              toggleChangeContactStageModalVisibility();
              showSuccessToast('Contact stage updated successfully.');
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/blacklist-logs',
                headers: { Authorization: 'Bearer ' + idToken },
                data: logData,
              });
              updateContactStageOnTable(leadId, contactStage);
            })
            .catch(() => {
              setUpdatingStage(false);
              toggleChangeContactStageModalVisibility();
              showErrorToast('Contact stage update failed.');
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  const tableDropDown = (params: any) => {
    const currentLeadId = params.data.smartlead_id;
    const currentCampaignId = params.data.campaign_id;
    const currentStage = params.data.Stage;
    const currentContactId = params.data?.apollo_contact_id;
    const currentSequence = params.data.Sequence;
    const currentDomain = params.data.Website;
    const currentName = params.data.First_Name + ' ' + params.data.Last_Name;

    if (params.data.smartlead_apollo === 'smartlead') {
      return (
        <div className="contact-page-container__dropdowns__table-dropdown__item ">
          <ContactPageDropdown
            options={contactStages}
            contactId={currentContactId}
            sequence={currentSequence}
            originalOption={currentStage}
            domain={currentDomain}
            name={currentName}
            selectedContactData={params.data}
            leadId={currentLeadId}
            campaignId={currentCampaignId}
            requestContactStageUpdate={requestContactStageUpdate}
          />
        </div>
      );
    } else {
      return params.data.Stage;
    }
  };

  const getLast7DaysDate = () => {
    const today = new Date(); // create a new Date object for today
    const sevenDaysAgo = new Date(); // create a new Date object for 7 days ago
    return sevenDaysAgo.setDate(today.getDate() - 8);
  };

  const getLast14DaysDate = () => {
    const today = new Date(); // create a new Date object for today
    const sevenDaysAgo = new Date(); // create a new Date object for 7 days ago
    return sevenDaysAgo.setDate(today.getDate() - 15);
  };

  const getLast30DaysDate = () => {
    const today = new Date(); // create a new Date object for today
    const thirtyDaysAgo = new Date(); // create a new Date object for 30 days ago
    return thirtyDaysAgo.setDate(today.getDate() - 31);
  };

  const convertStringToDate = (dateAsString: string) => {
    if (dateAsString == null) {
      return 0;
    }

    // Backend returns dates in the format yyyy/mm/dd
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('/');
    const day = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    return cellDate;
  };

  const filterParams = {
    // provide comparator function
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    maxNumConditions: 1,
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dateAsString = cellValue;

      if (dateAsString == null) {
        return 0;
      }

      // Backend returns dates in the format yyyy/mm/dd
      // We create a Date object for comparison against the filter date
      const dateParts = dateAsString.split('/');
      const day = Number(dateParts[2]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[0]);
      const cellDate = new Date(year, month, day);

      // Now that both parameters are Date objects, we can compare
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2099,
    inRangeInclusive: true,
    filterOptions: [
      'equals',
      'notEqual',
      {
        displayKey: 'customLessThan',
        displayName: 'Before',
        predicate: ([filterValue]: any[], cellValue: string) =>
          convertStringToDate(cellValue) < filterValue,
      },
      {
        displayKey: 'customGreaterThan',
        displayName: 'After',
        predicate: ([filterValue]: any[], cellValue: string) =>
          convertStringToDate(cellValue) > filterValue,
      },
      {
        displayKey: 'customInRange',
        displayName: 'Between',
        predicate: ([fv1, fv2]: any[], cellValue: string) =>
          fv1 <= convertStringToDate(cellValue) &&
          fv2 >= convertStringToDate(cellValue),
        numberOfInputs: 2,
      },
      {
        displayKey: 'customLast7Days',
        displayName: 'Last 7 days',
        predicate: ([filterValue]: any[], cellValue: string) =>
          getLast7DaysDate() < convertStringToDate(cellValue),
        numberOfInputs: 0,
      },
      {
        displayKey: 'customLast14Days',
        displayName: 'Last 14 days',
        predicate: ([filterValue]: any[], cellValue: string) =>
          getLast14DaysDate() < convertStringToDate(cellValue),
        numberOfInputs: 0,
      },
      {
        displayKey: 'customLast30Days',
        displayName: 'Last 30 days',
        predicate: ([filterValue]: any[], cellValue: string) =>
          getLast30DaysDate() < convertStringToDate(cellValue),
        numberOfInputs: 0,
      },
    ],
  };

  const columnDefs = [
    {
      field: '',
      checkboxSelection: true,
      //   headerCheckboxSelection: true,
      maxWidth: 35,
    },
    {
      field: '',
      autoHeight: true,
      cellRenderer: firstLetter,
      filter: false,
      suppressMenu: true,
      sortable: false,
      minWidth: 60,
      maxWidth: 65,
    },
    {
      field: 'First_Name',
      headerName: 'First Name',
      filter: true,
      resizable: true,
    },
    {
      field: 'Last_Name',
      headerName: 'Last Name',
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Company',
      field: 'Company',
      filter: 'agSetColumnFilter',
      resizable: true,
      cellEditor: 'agRichSelectCellEditor',
      tooltipField: 'Company',
      cellRenderer: companyNameWebsite,
      tooltipComponent: CompanyToolTip,
    },
    {
      headerName: 'Size',
      field: 'Size',
      filter: 'agSetColumnFilter',
      resizable: true,
      maxWidth: 150,
      filterParams: {
        comparator: (a: any, b: any) => {
          const valA = parseInt(a);
          const valB = parseInt(b);
          if (valA === valB) return 0;
          return valA > valB ? 1 : -1;
        },
      },
    },
    {
      headerName: 'Title',
      field: 'Title',
      filter: true,
      resizable: true,
      tooltipField: 'Title',
      tooltipComponent: TitleToolTip,
    },
    {
      headerName: 'Campaign',
      field: 'Sequence',
      filter: true,
      resizable: true,
      tooltipField: 'Sequence',
      tooltipComponent: SequenceToolTip,
    },
    {
      headerName: 'Industry',
      field: 'Industry',
      filter: 'agSetColumnFilter',
      resizable: true,
      cellStyle: { textTransform: 'capitalize' },
      tooltipField: 'Industry',
      tooltipComponent: IndustryToolTip,
    },
    {
      headerName: 'Email',
      field: 'Email',
      filter: 'agSetColumnFilter',
      resizable: true,
      tooltipField: 'Email',
      tooltipComponent: EmailToolTip,
    },
    {
      headerName: 'Stage',
      field: 'Stage',
      filter: 'agSetColumnFilter',
      resizable: true,
      cellRenderer: tableDropDown,
      minWidth: 180,
      cellStyle: { overflow: 'visible' },
      filterParams: {
        values: [
          'Interested',
          'Not Interested',
          'Do Not Contact',
          'Out Of Office',
          'Contacted',
          'Unresponsive',
        ],
      },
    },
    {
      field: 'Person_Linkedin_Url',
      headerName: 'LinkedIn Url',
      headerClass: 'hidden-header',
      filter: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params: any) => linkedIn(params),
      maxWidth: 100,
      resizable: true,
      sortable: false,
    },
    {
      field: 'Date',
      filter: 'agDateColumnFilter',
      filterParams: filterParams,
      resizable: true,
      sortable: true,
      sort: 'desc' as SortDirection,
    },
    {
      headerName: '',
      field: 'Reply',
      // suppressMenu: true,
      filter: 'agSetColumnFilter',
      resizable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params: any) => renderEmailBodyCell(params),
    },
    {
      field: 'Country',
      filter: true,
      hide: true,
      resizable: true,
    },
    {
      field: 'Website',
      filter: true,
      hide: true,
      resizable: true,
    },
    {
      field: 'Phone_Number',
      headerName: 'Phone',
      filter: true,
      hide: true,
      resizable: true,
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      menuTabs: ['filterMenuTab'] as any,
      filterParams: {
        defaultToNothingSelected: true,
      },
    };
  }, []);

  const handleColumnVisibility = () => {
    const currentColumns = contactsGridRef.current?.api?.getColumnDefs();
    if (currentColumns?.length > 10) {
      currentColumns[14].hide = hideCountryColumn;
      currentColumns[16].hide = hidePhoneColumn;
      contactsGridRef.current.api?.setGridOption('columnDefs', currentColumns);
    }
  };

  useEffect(() => {
    handleColumnVisibility();
  }, [hideCountryColumn, hidePhoneColumn]);

  //Below code is to have the graph resize on windows resize
  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };

    // Add a window resize event listener
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [gridApi]);

  // Custom hook similar to component did update just that
  // this one doesn't run on 1st render
  useDidUpdateEffect(() => {
    if (contactSearchRedux?.payload) {
      switch (searchTypeRedux.payload) {
        case 'contact':
          setTimeout(() => {
            resetFilters();
            filterByFirstName();
            filterByLastName();
          }, 0);

          break;
        case 'company':
          setTimeout(() => {
            resetFilters();
            filterByCompany();
          }, 0);

          break;
        default:
        // Do nothing
      }
      // set contact search to false so that the next time the user
      // visits from sidebar link the table displays all contacts ie doesn't filter
      dispatch(searchActions.setContactSearch(false));
    }
  }, [contactSearchRedux?.payload]);

  const onGridReady = (params: any) => {
    setGridApi(params?.api);
    setGridColumnApi(params?.columnApi);
    params.api.sizeColumnsToFit(); //Sets the width of the columns to use the full width

    // If user navigated to contacts page via search bar then we
    // want to filter the table to only display the search result
    if (contactSearchRedux?.payload) {
      setActiveFilter([]);
      switch (searchTypeRedux.payload) {
        case 'contact':
          filterByFirstName();
          filterByLastName();
          break;
        case 'company':
          filterByCompany();
          break;
        default:
        // Do nothing
      }
      // set contact search to false so that the next time the user
      // visits from sidebar link the table displays all contacts ie doesn't filter
      dispatch(searchActions.setContactSearch(false));
      if (checkboxes) {
        //This is setting the toggles back to off
        const checkboxes = document.querySelectorAll('.toggle-btn');
        checkboxes.forEach((item: any) => {
          item.checked = false;
        });
      }
    } else {
      const stageFilterComponent =
        contactsGridRef.current.api?.getFilterInstance('Stage');
      // stageFilterComponent.setModel({
      //   values: ['Interested', 'Possible Interest'],
      // });
    }

    setTimeout(function () {
      params.api.onFilterChanged();
    }, 1000);
  };

  const paginationPageSize = 25; //Update to 7 when going live

  const handleOpenModal = (
    campaign_id: number,
    lead_id: number,
    stage: string
  ) => {
    setIsModalVisible(true);
    setViewReplyLoading(true);

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              `api/smartlead-reply/${campaign_id}/${lead_id}/${Cookies.get(
                'fuelStationId'
              )}`,
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              const modifiedData = {
                ...response.data.data,
                stage: stage,
                campaignId: campaign_id,
                leadId: lead_id, // Adding the stage prop
              };
              setModalContent(modifiedData);
              setViewReplyLoading(false);
            })
            .catch(() => {
              setViewReplyError(true);
              setViewReplyLoading(false);
              // console.log('Apo')
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  //Below is setting the filters values for the date selected
  const handleDateFilterClick = (selectedOption: string) => {
    if (gridApi) {
      const existingFilterModel = gridApi.getFilterModel();

      let dateFilterModel = {};

      switch (selectedOption) {
        case 'Last 7 days':
          dateFilterModel = {
            Date: {
              filterType: 'date',
              type: 'customLast7Days',
            },
          };
          break;
        case 'Last 14 days':
          dateFilterModel = {
            Date: {
              filterType: 'date',
              type: 'customLast14Days',
            },
          };
          break;
        case 'Last 30 days':
          dateFilterModel = {
            Date: {
              filterType: 'date',
              type: 'customLast30Days',
            },
          };
          break;
        case 'All time':
          dateFilterModel = {
            Date: {},
          };
          break;

        default:
        // Do nothing for other options
      }

      const newFilterModel = {
        ...existingFilterModel,
        ...dateFilterModel,
      };

      gridApi.setFilterModel(newFilterModel);
    }

    setSelectedDateOption(selectedOption);
  };

  useEffect(() => {
    // Use the gridApi to set the filter model when the sequence changes
    //!IMPORTANT - set timeout function to add a delay to this, otherwise it tries to run while grid still populating
    if (gridApi && leadsFilter) {
      const filterModel = {
        Stage: {
          filterType: 'set',
          values: leadsFilter,
        },
      };

      gridApi.setFilterModel(filterModel);
    }
  }, [leadsFilter, gridApi]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return; // Skip the effect on initial render
    } //Each time the global search is updated in the top bar (clear search is clicked) it will also clear the contacts table filter
    clearAllFilters();
  }, [globalSearch]);

  const clearAllFiltersDual = () => {
    clearAllFilters();
    setGlobalSearch((prevState: boolean) => !prevState);
  };

  const clearAllFilters = () => {
    contactsGridRef.current.api?.setFilterModel(null);
    gridApi?.setGridOption('rowData', rowData);
    setSelectedDateOption('All time');
    setActiveFilter([]);
    if (checkboxes) {
      //This is setting the toggles back to off
      checkboxes.forEach((item: any) => {
        item.checked = false;
      });
    }
  };

  const leadsFilterButton = () => {
    // Check if the current filter is set to Leads
    const isLeadsFilterActive = activeFilter.includes('Leads');

    // Toggle the filter based on its current state
    if (isLeadsFilterActive) {
      // If Leads filter is active, clear the filter
      setLeadsFilter([
        'Contacted',
        'Replied',
        'Interested',
        'Possible Interest',
        'Not Interested',
        'Unresponsive',
        'Do Not Contact',
        'Bad Data',
        'Changed Job',
        'Blocked',
        'Out of Office',
        'Website',
      ]);
    } else {
      // If Leads filter is not active, set the filter
      setLeadsFilter(['Interested', 'Possible Interest']);
    }

    // Handle date filter and toggle filter
    handleDateFilterClick('All time');
    toggleFilter('Leads');
  };

  const replyFilterButton = () => {
    // Filter rows based on the condition (show_reply === 'View reply')
    const filteredRows = rowData.filter(
      (row) => row.show_reply === 'View_reply'
    );
    // Check if the current filter is set to Replied
    const isReplyFilterActive = activeFilter.includes('Replied');

    // Toggle the filter based on its current state
    if (isReplyFilterActive) {
      // If Leads filter is active, clear the filter
      gridApi.setGridOption('rowData', rowData);
    } else {
      // If Leads filter is not active, set the filter
      gridApi.setGridOption('rowData', filteredRows);
    }

    toggleFilter('Replied');
  };

  //Warning to inform the user the data will be lost
  const toggleReplyModal = () => {
    setWarningShowing(true);
  };

  const closeModal = () => {
    setIsModalVisible(!isModalVisible);
    setModalContent('');
    setSelectedEmail(0);
    setReplying(false);
    setWarningShowing(false);
    setEmailBody('');
    setCc('');
    setIsTyping(false);
  };

  const keepModalOpen = () => {
    setWarningShowing(false);
    setSendEmailConformation(false);
  };

  const toggleFilter = (filter: any) => {
    // Check if the filter is already active
    if (activeFilter.includes(filter)) {
      // If active, remove it
      setActiveFilter(activeFilter.filter((f) => f !== filter));
    } else {
      // If not active, add it
      setActiveFilter([...activeFilter, filter]);
    }
  };

  //Below code is to Display and Hide the hidden email body text
  const renderEmailBodyCell = (params: any) => {
    const campaign_id = params.data.campaign_id;
    const lead_id = params.data.smartlead_id;
    const stage = params.data.Stage;
    let showReply = false;
    params.data.smartlead_apollo === 'smartlead'
      ? (showReply = true)
      : (showReply = false);

    return (
      showReply && (
        <div className="contact-page-container__email-body">
          <button
            className="contact-page-container__email-body show-more-button"
            onClick={() => handleOpenModal(campaign_id, lead_id, stage)}
          >
            View Thread
          </button>
        </div>
      )
    );
  };

  const exportContactsReport = () => {
    const params = {
      columnKeys: [
        'First_Name',
        'Last_Name',
        'Company',
        'Size',
        'Title',
        'Sequence',
        'Industry',
        'Email',
        'Stage',
        'Person_Linkedin_Url',
        'Date',
        'Country',
        'Phone_Number',
        'Website',
      ],
    };
    contactsGridRef.current.api?.exportDataAsCsv(params);
  };

  const LoadingContainerTableRow = () => {
    return (
      <div className="loading-container-table__row">
        <div className="loading-container-table__row__column flex">
          <div className="loading-block loading-container-table__row__column__image"></div>
          <div className="loading-block loading-container-table__row__column__text"></div>
        </div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
      </div>
    );
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  //To check whether the to display the email inputs or not
  const replyToEmail = () => {
    setReplying(true);
  };

  const cancelReply = () => {
    setReplying(false);
  };

  //Updating the body value
  const emailBodyChange = (event: any) => {
    const text = event.target.value;
    setIsTyping(text.length > 0);
    setEmailBody(text);
  };

  //Updating the cc value
  const ccChange = (event: any) => {
    const text = event.target.value;
    setCc(text);
  };

  //Sending the email
  const sendEmail = () => {
    setEmailSendingLoading(true);
    const data = {
      reply_body: emailBody,
      campaign_id: modalContent.campaignId,
      lead_id: modalContent.leadId,
      cc_email: trailingSpaceRemover(firstSpaceRemover(cc)),
      fuelstation_id: Cookies.get('fuelStationId'),
    };
    setIsTyping(false);
    setSendEmailConformation(false);

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/smartlead-reply',
            headers: { Authorization: 'Bearer ' + idToken },
            data: data,
          })
            .then((response) => {
              showSuccessToast('Email sent succesfully');
              setEmailSendingLoading(false);
              closeModal();
            })
            .catch((error) => {
              setEmailSendingLoading(false);
              showErrorToast('Failed to send, please try again later');
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  const sendEmailValidation = (event: any) => {
    event.preventDefault();
    setSendEmailConformation(true);
  };

  // isModalVisible ?
  // const buttons = modalContent?.thread.map((item: any, index: number) => {
  //   let label;

  //   if (item.type === 'SENT' && !replyHit) {
  //     label = `Step:${index + 1}`;
  //   } else {
  //     label = `Reply on: ${formatDate(item.time)}`;
  //     replyHit = true; // Update replyHit when rendering a reply
  //   }
  //   return (
  //     <button
  //       key={index}
  //       onClick={() => setSelectedEmail(index)}
  //       className={index === selectedEmail ? 'selected-button' : ''}
  //     >
  //       <p>{label}</p>
  //     </button>
  //   );
  // });

  const handleReplyButtonClick = (index: number) => {
    setSelectedEmail(index);
    // Step 3: Scroll the modal to the top
    if (modalRef.current) {
      modalRef.current.scrollTop = 0;
    }
  };

  return (
    <div className="contact-page-container">
      <ToastContainer />
      <Modal
        size="small"
        visible={showChangeContactStageModal}
        toggleModalVisibility={toggleChangeContactStageModalVisibility}
      >
        <ChangeContactStageModal
          stage={contactStage}
          updatingStage={updatingStage}
          onCancel={toggleChangeContactStageModalVisibility}
          handleChangeContactStage={updateContactStage}
        />
      </Modal>
      <div className="contact-page-container__export-bar">
        <h2>Contacts</h2>
      </div>
      {fetchingContactData ? (
        <div className="loading-container-table">
          <LoadingContainerTableRow />
          <LoadingContainerTableRow />
          <LoadingContainerTableRow />
          <LoadingContainerTableRow />
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later.
        </div>
      ) : rowData?.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <div>
          <div className="contact-page-container__dropdowns">
            <div className="contact-page-container__dropdowns__flex">
              <span className="contact-page-container__dropdowns__flex__dropdown">
                <ContactsTableDropdown
                  options={dateOptions}
                  originalOption="All time"
                  clickHandler={handleDateFilterClick}
                  selectedOption={selectedDateOption}
                />
              </span>
              <button className="sequence-button" onClick={clearAllFiltersDual}>
                Clear Filters
              </button>

              {/* <button
                className={`sequence-button ${
                  activeFilter.includes('Leads') ? 'active-filter' : ''
                }`}
                onClick={leadsFilterButton}
              >
                Leads
              </button> */}
              <div className="contact-page-container__dropdowns__flex__toggle-buttons">
                <p>Interested</p>
                <div className="sequence-toggle-container dashboard-container__navbar__section-container__container__flex__active__toggle-button">
                  <label className="switch">
                    <input
                      className="toggle-btn"
                      type="checkbox"
                      // defaultChecked
                    />
                    <span
                      className="slider round"
                      onClick={leadsFilterButton}
                    ></span>
                  </label>
                </div>
              </div>
              <div className="contact-page-container__dropdowns__flex__toggle-buttons">
                <p>Replied</p>
                <div className="sequence-toggle-container dashboard-container__navbar__section-container__container__flex__active__toggle-button">
                  <label className="switch">
                    <input className="toggle-btn" type="checkbox" />
                    <span
                      className="slider round"
                      onClick={replyFilterButton}
                    ></span>
                  </label>
                </div>
              </div>
              {/* <button
                className={`sequence-button ${
                  activeFilter.includes('Replied') ? 'active-filter' : ''
                }`}
                onClick={replyFilterButton}
              >
                Replied
              </button> */}
            </div>
            <div className="contact-page-container__dropdowns__flex">
              <ShowHideColumns
                hideCountryColumn={hideCountryColumn}
                setHideCountryColumn={setHideCountryColumn}
                hidePhoneColumn={hidePhoneColumn}
                setHidePhoneColumn={setHidePhoneColumn}
              />
              <button
                className="sequence-button"
                onClick={exportContactsReport}
              >
                Export CSV
              </button>
            </div>
          </div>
          <div className="ag-theme-alpine-dark contact-page-container__table">
            <AgGridReact
              ref={contactsGridRef}
              columnDefs={columnDefs}
              suppressMenuHide={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              pagination={true}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={false}
              onGridReady={onGridReady}
              domLayout={'autoHeight'}
            />
          </div>
          <Modal
            size={replying ? 'large' : 'medium'}
            visible={isModalVisible}
            toggleModalVisibility={replying ? toggleReplyModal : closeModal}
          >
            <div className="modal-content">
              <div
                className="close"
                onClick={replying ? toggleReplyModal : closeModal}
              ></div>
              {viewReplyLoading ? (
                <div className="contact-page__spinner-container">
                  <SpinnerCircular
                    size={40}
                    thickness={200}
                    speed={100}
                    color="var(--sliderEnd)"
                    secondaryColor="var(--sliderStart)"
                    className="sign-in-spinner"
                  />
                </div>
              ) : viewReplyError ? (
                <div className="contact-page__error-container">
                  Failed To Load Reply
                </div>
              ) : (
                <div>
                  {modalContent && modalContent?.thread ? (
                    <div className="email-reply-modal">
                      {emailSendingLoading ? (
                        <div className="email-reply-modal__email-body__reply__loading">
                          <SpinnerCircular
                            size={40}
                            thickness={200}
                            speed={100}
                            color="var(--sliderEnd)"
                            secondaryColor="var(--sliderStart)"
                            className="sign-in-spinner email-loading"
                          />
                        </div>
                      ) : null}
                      {warningShowing && replying ? (
                        <div className="email-reply-modal__check">
                          <div className="email-reply-modal__check__content">
                            <p>
                              Changes will be lost, do you still wish to exit?
                            </p>
                            <div>
                              <button
                                className="sequence-button"
                                onClick={closeModal}
                              >
                                Yes
                              </button>
                              <button
                                className="sequence-button"
                                onClick={keepModalOpen}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {sendEmailConformation && replying ? (
                        <div className="email-reply-modal__check">
                          <div className="email-reply-modal__check__content">
                            <h3>Are you sure you want to send?</h3>
                            <div>
                              <button
                                className="sequence-button"
                                onClick={sendEmail}
                              >
                                Yes
                              </button>
                              <button
                                className="sequence-button"
                                onClick={keepModalOpen}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="email-reply-modal__email">
                        <div className="email-reply-modal__email__to-from">
                          <span>
                            <b>To:</b> {modalContent.to}
                          </span>
                          <span>
                            <b>From:</b> {modalContent.from}
                          </span>
                        </div>
                        <div className="email-reply-modal__email__subject">
                          <p>
                            <b>Subject:</b> {modalContent?.thread[0]?.subject}
                          </p>
                        </div>
                      </div>
                      <div
                        className="email-reply-modal__email-body"
                        ref={modalRef}
                      >
                        <div style={{ width: replying ? '50%' : '100%' }}>
                          {modalContent?.thread[selectedEmail].body
                            .split('\n')
                            .map((line: string, index: number) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                                <br />
                              </React.Fragment>
                            ))}
                        </div>
                        {replying ? (
                          <form
                            className="email-reply-modal__email-body__reply"
                            id="replyForm"
                            onSubmit={sendEmailValidation}
                          >
                            <h3>Reply:</h3>
                            <div className="email-reply-modal__email-body__reply__email">
                              <input
                                type="email"
                                placeholder="CC your primary email"
                                onChange={ccChange}
                                value={cc}
                              />
                              <div>
                                <img src={InfoIcon} alt="" />
                              </div>
                            </div>

                            <textarea
                              className="email-reply-modal__email-body__reply__text-area"
                              name=""
                              id=""
                              placeholder="Type your response here"
                              onChange={emailBodyChange}
                              value={emailBody}
                            ></textarea>
                            <div className="email-reply-modal__email-body__reply__info">
                              <img src={InfoIcon} alt="" />
                              Your signature will be included automatically
                            </div>
                            <input
                              type="submit"
                              id="form-submit"
                              style={{ display: 'none' }}
                            />
                          </form>
                        ) : null}
                      </div>
                      <div className="email-reply-modal__buttons">
                        <div className="email-reply-modal__buttons__step-reply">
                          {modalContent?.thread.map(
                            (item: any, index: number) => {
                              let label;
                              if (
                                item.type === 'SENT' &&
                                !item.email_seq_number
                              ) {
                                label = `Reply on ${formatDate(item.time)}`;
                              } else if (item.type === 'REPLY') {
                                label = `Reply on ${formatDate(item.time)}`;
                              } else if (item.type === 'SENT') {
                                label = `Step ${item.email_seq_number}`;
                              }

                              return (
                                <button
                                  key={index}
                                  onClick={() => handleReplyButtonClick(index)}
                                  className={
                                    index === selectedEmail
                                      ? 'selected-button'
                                      : ''
                                  }
                                >
                                  <p>{label}</p>
                                </button>
                              );
                            }
                          )}
                        </div>
                        {modalContent.stage === 'Interested' ||
                        modalContent.stage === 'Not Interested' ||
                        modalContent.stage === 'Out of Office' ||
                        modalContent.stage === 'Meeting Request' ||
                        modalContent.stage === 'Unresponsive' ? (
                          <div className="email-reply-modal__buttons__flex">
                            {replying ? (
                              <div>
                                <button onClick={cancelReply}>Cancel</button>
                                <button
                                  className={`email-reply-modal__buttons__reply ${
                                    isTyping ? null : 'grey'
                                  }`}
                                  type="submit"
                                  form="replyForm"
                                  value="Submit"
                                  disabled={isTyping ? false : true}
                                >
                                  Send
                                </button>
                              </div>
                            ) : (
                              <button
                                className="email-reply-modal__buttons__reply"
                                onClick={replyToEmail}
                              >
                                Reply
                              </button>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : !modalContent?.thread ? (
                    <div className="no-smartlead-reply">
                      No reply found on Smartlead
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ContactPage;
