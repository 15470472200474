import React, { useState } from 'react';
import './forgotPassword.scss';
import auth from '../../firebase/init';
import logo from '../../assets/signIn/logo.png';
import ctrlLogo from '../../assets/sideBar/ctrl-logo.svg';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [resetEmailFailed, setResetEmailFailed] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);

  const resetPassword = (e: Event) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setEmailSent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/invalid-email') {
          setInvalidEmail(true);
        } else if (errorCode === 'auth/user-not-found') {
          setUserNotFound(true);
        } else {
          setResetEmailFailed(true);
        }
      });
  };

  return (
    <div className="forgot-password-page-container">
      <div className="forgot-password-form-container">
        <div className="forgot-password-heading-section">
          <img src={ctrlLogo} alt="Ctrlroom Logo" />
        </div>
        <form className="sign-in-form">
          <label className="input-item">
            <input
              type="text"
              name="email"
              placeholder=" "
              onChange={(e) => setEmail(e.target.value)}
            />
            <span>Email</span>
          </label>
          <button type="submit" onClick={() => resetPassword(event)}>
            Reset Password
          </button>
        </form>
        {emailSent ? (
          <p className="email-sent-text">
            If the email provided is associated with an account, you will
            receive an email shortly.
          </p>
        ) : null}
        {resetEmailFailed ? (
          <p className="reset-email-failed-text">
            Something went wrong. Please try again later.
          </p>
        ) : null}
        {invalidEmail ? (
          <p className="reset-email-failed-text">
            Please provide a valid email address.
          </p>
        ) : null}
        {userNotFound ? (
          <p className="reset-email-failed-text">
            User with given email address doesn&#39;t exist. Please try again
            with another email.
          </p>
        ) : null}
        <div className="back-to-sign-in">
          <p onClick={() => navigate('/')}>Back to Log In</p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
