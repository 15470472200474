// Fundamentals
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Packages
import { SpinnerCircular } from 'spinners-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

// Styles
import './analyticsDashboard.scss';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../../redux/auth-slice';

//Components
import TotalLeads from '../../../components/dashboards/analytics/totalLeads/totalLeads';
import AnalyticsDropdown from '../../../components/dashboards/analytics/analyticsDropdown/analyticsDropdown';
import TopSequence from '../../../components/dashboards/analytics/topSequence/topSequence';
import TopPersona from '../../../components/dashboards/analytics/topPersona/topPersona';
import LeadsGraph from '../../../components/dashboards/analytics/leadsGraph/leadsGraph';
import PerformanceRates from '../../../components/dashboards/analytics/performanceRates/performanceRates';
import VolumesGraph from '../../../components/dashboards/analytics/volumesGraph/volumesGraph';
import CopyModal from '../../../components/copyModal/copyModal';
import auth from '../../../firebase/init';

//Images
import docs from '../../../assets/clientPage/docs.png';
import sequenceIcon from '../../../assets/dashboard/dashboard-sequence-icon.svg';
import monthIcon from '../../../assets/dashboard/dashboard-month-icon.svg';
import SequencesFilter from '../../../components/dashboards/analytics/sequencesFilter/sequencesFilter';
import ViewCopy from '../../../components/dashboards/analytics/viewCopy/viewCopy';
import { analyticsActions } from '../../../redux/analytics/analyticsSlice';
import { sequenceStatusActions } from '../../../redux/analytics/sequenceStatusSlice';
import { clientActions } from '../../../redux/client-slice';
import Modal from '../../../components/Modal/modal';

const dateOptions = [
  'All time',
  'Last 7 days',
  'Last 14 days',
  'Last 30 days',
  'Custom',
];

interface ISelector {
  type: string;
  payload: [];
}

interface IAnalyticsSlice {
  sequences: ISelector;
  leads: ISelector;
  emailRates: ISelector;
  projectedLeads: ISelector;
  sequenceVolumes: ISelector;
  totalLeads: ISelector;
  topPersona: ISelector;
  topSequence: ISelector;
  progressData: ISelector;
}

interface IType {
  type: string;
  payload: string;
}

interface IClientTypeSlice {
  clientType: IType;
}

interface IStore {
  analytics: IAnalyticsSlice;
  sequenceStatusActive: ISequenceStatusSlice;
  clienttype: IClientTypeSlice;
}

interface ISequenceStatusSlice {
  sequences: ISelector;
  leads: ISelector;
  sequenceVolumes: ISelector;
  totalLeads: ISelector;
  topPersona: ISelector;
  topSequence: ISelector;
  emailRates: ISelector;
  progressData: ISelector;
  projectedLeads: ISelector;
}

interface DashboardProps {
  isAdmin: boolean;
}

const Dashboard = ({ isAdmin }: DashboardProps) => {
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leadsData, setLeadsData] = useState([]);
  const [leadsDataLoading, setLeadsDataLoading] = useState(null);
  const [leadsDataError, setLeadsDataError] = useState(null);
  const [totalLeads, setTotalLeads] = useState(null);
  const [totalLeadsLoading, setTotalLeadsLoading] = useState(null);
  const [totalLeadsError, setTotalLeadsError] = useState(null);
  const [sequencesList, setSequencesList] = useState([]);
  const [sequenceVolumes, setSequenceVolumes] = useState(null);
  const [sequenceVolumesLoading, setSequenceVolumesLoading] = useState(null);
  const [sequenceVolumesError, setSequenceVolumesError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emailRatesData, setEmailRatesData] = useState(null);
  const [emailRatesLoading, setEmailRatesLoading] = useState(null);
  const [emailRatesError, setEmailRatesError] = useState(null);
  const [predictiveLeadsData, setPredictiveLeadsData] = useState(null);
  const [predictiveLeadsLoading, setPredictiveLeadsLoading] = useState(null);
  const [predictiveLeadsError, setPredictiveLeadsError] = useState(null);
  const [copies, setCopies] = useState([]);
  const [copyModalVisible, setCopyModalVisible] = useState(false);
  const [topPersonaData, setTopPersonaData] = useState(null);
  const [topPersonaLoading, setTopPersonaLoading] = useState(false);
  const [topPersonaError, setTopPersonaError] = useState(null);
  const [topSequenceData, setTopSequenceData] = useState([]);
  const [topSequenceLoading, setTopSequenceLoading] = useState(false);
  const [topSequenceError, setTopSequenceError] = useState(false);

  const [progressData, setProgressData] = useState([]);
  const [progressDataLoading, setProgressDataLoading] = useState(false);
  const [progressDataError, setProgressDataError] = useState(false);
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [calendarDate, setCalendarDate] = useState<any>(new Date());
  const [selectDateRange, setSelectDateRange] = useState(true);
  const [calendarType, setCalendarType] = useState('');
  const [today, setToday] = useState(new Date());
  const [previousMonthDate, setPreviousMonthDate] = useState(null);

  const [activeToggle, setActiveToggle] = useState(false);
  const initialRender = useRef(true);
  const firstRender = useRef(true);

  const [displayingLeads, setDisplayingLeads] = useState(false);

  const [customDateSelection, setCustomDateSelection] = useState({
    date_start: '',
    date_end: '',
  });
  const [allTimeHit, setAllTimeHit] = useState(false);

  //Sequence filter list variable created to use on this page, it gets set to the filter list received by the dropdown when specific sequences are selected
  const [sequenceFilterList, setSequenceFilterList] = useState([]);

  //Full analytics data
  const sequencesRedux = useSelector(
    (state: IStore) => state.analytics.sequences
  );
  const sequenceVolumesRedux = useSelector(
    (state: IStore) => state.analytics.sequenceVolumes
  );
  const leadsRedux = useSelector((state: IStore) => state.analytics.leads);
  const totalLeadsRedux = useSelector(
    (state: IStore) => state.analytics.totalLeads
  );
  const projectedLeadsRedux = useSelector(
    (state: IStore) => state.analytics.projectedLeads
  );
  const emailRatesRedux = useSelector(
    (state: IStore) => state.analytics.emailRates
  );
  const topPersonaRedux = useSelector(
    (state: IStore) => state.analytics.topPersona
  );
  const topSequenceRedux = useSelector(
    (state: IStore) => state.analytics.topSequence
  );
  const progressDataRedux = useSelector(
    (state: IStore) => state.analytics.progressData
  );

  // //Sequence Status data
  const sequencesStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.sequences
  );
  const sequenceVolumesStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.sequenceVolumes
  );
  const emailRatesStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.emailRates
  );
  const leadsStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.leads
  );
  const totalLeadsStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.totalLeads
  );
  const projectedLeadsStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.projectedLeads
  );
  const topPersonaStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.topPersona
  );
  const topSequenceStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.topSequence
  );
  const progressDataStatusRedux = useSelector(
    (state: IStore) => state.sequenceStatusActive.progressData
  );

  useEffect(() => {
    // Check if client was redirected from v1 portal
    // if (localStorage.getItem('loggedIn') == 'yes') {
    //   Cookies.set('displayName', localStorage.getItem('displayName'), {
    //     expires: 1,
    //   });
    //   Cookies.set('loggedIn', 'yes', { expires: 1 });
    // }

    if (leadsRedux?.payload) {
      setLeadsData(leadsRedux?.payload);
      setSequencesList(sequencesRedux?.payload);
      setSequenceFilterList(sequencesRedux?.payload);
      setSequenceVolumes(sequenceVolumesRedux?.payload);
      setTotalLeads(totalLeadsRedux?.payload);
      setPredictiveLeadsData(projectedLeadsRedux?.payload);
      setEmailRatesData(emailRatesRedux?.payload);
      setTopPersonaData(topPersonaRedux?.payload);
      setTopSequenceData(topSequenceRedux?.payload);
      setProgressData(progressDataRedux?.payload);
    } else {
      getAllTimeData();
    }

    getCopies();
  }, []);

  // const getFuelStationName = () => {
  //   Cookies.set('inconsistentClientName', 'true', { expires: 1 });
  //   auth.onAuthStateChanged((user) => {
  //     user
  //       .getIdToken(/* forceRefresh */ true)
  //       .then(function (idToken) {
  //         // Get sequences count or sums
  //         axios({
  //           method: 'GET',
  //           url:
  //             apiEndpoint + 'api/fuelstation-name/' +
  //             Cookies.get('fuelStationId'),
  //           headers: { Authorization: 'Bearer ' + idToken },
  //         })
  //           .then((response) => {
  //             Cookies.set('fuelStationId', response.data.data.fuelstation_id, {
  //               expires: 1,
  //             });
  //             getAllTimeData();
  //           })
  //           .catch(() => {
  //             //
  //           });
  //       })
  //       .catch(function (error) {
  //         // Handle error
  //       });
  //   });
  // };

  const setLoadingDataTrue = () => {
    setTopPersonaLoading(true);
    setTopSequenceLoading(true);
    setTotalLeadsLoading(true);
    setEmailRatesLoading(true);
    setSequenceVolumesLoading(true);
    setLeadsDataLoading(true);
  };

  const setLoadingDataFalse = () => {
    setTopPersonaLoading(false);
    setTopSequenceLoading(false);
    setTotalLeadsLoading(false);
    setEmailRatesLoading(false);
    setSequenceVolumesLoading(false);
    setLeadsDataLoading(false);
  };

  const getAllTimeData = () => {
    setLoadingDataTrue();
    const currentDate = formatDate(new Date());

    const data = {
      client: Cookies.get('fuelStationId'),
      date_start: '2000-01-01',
      date_end: currentDate,
    };

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // Send token to your backend via HTTPS

          // Get sequences count or sums
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/sequences-overview/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              if (
                response.data.data.length == 0 &&
                Cookies.get('inconsistentClientName') == 'false'
              ) {
                // getFuelStationName();
              }
              setLeadsData(response.data.data);
              setLeadsDataLoading(false);
              dispatch(analyticsActions.setLeads(response.data.data));
            })
            .catch(() => {
              setLeadsDataLoading(false);
              setLeadsDataError(true);
            });

          // Email rates data
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/smartlead-email-rates',
            headers: { Authorization: 'Bearer ' + idToken },
            data: data,
          })
            .then((response) => {
              setEmailRatesLoading(false);
              setEmailRatesData(response.data.data);
              dispatch(analyticsActions.setEmailRates(response.data.data));
            })
            .catch(() => {
              setEmailRatesLoading(false);
              setEmailRatesError(true);
            });

          // Progress data
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-client-goal/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setProgressDataLoading(false);
              setProgressData(response.data.data);
              dispatch(analyticsActions.setProgressData(response.data.data));
            })
            .catch(() => {
              setProgressDataLoading(false);
              setProgressDataError(true);
            });

          // Projected Leads Graph
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-projected-leads/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setPredictiveLeadsLoading(false);
              setPredictiveLeadsData(response.data.data);
              dispatch(analyticsActions.setProjectedLeads(response.data.data));
            })
            .catch(() => {
              setPredictiveLeadsLoading(false);
              setPredictiveLeadsError(true);
            });

          // Sequence Volumes
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-sequences-volumes/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setSequenceVolumes(response.data.data);
              dispatch(analyticsActions.setSequenceVolumes(response.data.data));
              getSequences(response.data.data);
              setSequenceVolumesLoading(false);
            })
            .catch(() => {
              setSequenceVolumesLoading(false);
              setSequenceVolumesError(true);
            });

          // Total Leads
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-total-leads/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTotalLeads(response.data.data.leads);
              dispatch(
                analyticsActions.setTotalLeads(response.data.data.leads)
              );
              setTotalLeadsLoading(false);
            })
            .catch(() => {
              setTotalLeadsLoading(false);
              setTotalLeadsError(true);
            });

          // Top Persona
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-top-persona/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTopPersonaData(response.data.data);
              dispatch(analyticsActions.setTopPersona(response.data.data));
              setTopPersonaLoading(false);
            })
            .catch(() => {
              setTopPersonaLoading(false);
              setTopPersonaError(true);
            });

          // Top Sequence
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-top-sequence/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTopSequenceData(response.data.data);
              dispatch(analyticsActions.setTopSequence(response.data.data));
              setTopSequenceLoading(false);
            })
            .catch(() => {
              setTopSequenceLoading(false);
              setTopSequenceError(true);
            });
        })

        .catch(function (error) {
          // Handle error
        });
    });
  };

  //Below sets the sequence list for all sequences
  const getSequences = (sequences: any) => {
    const temporaryList: any[] = [];
    const sequenceNamesList: string[] = [];
    sequences.map((sequence: any) => {
      // Extract only the desired properties from each sequence object
      const { Sequence, sequence_status } = sequence;

      // Create a new object with only the desired properties
      const simplifiedSequence = {
        Sequence,
        sequence_status,
      };

      // Push the simplified object to the temporaryList
      temporaryList.push(simplifiedSequence);
      sequenceNamesList.push(Sequence);
    });

    // const sequenceNamesList = temporaryList.map((item) => item.Sequence);

    setSequencesList(temporaryList);
    setSequenceFilterList(sequenceNamesList);
    dispatch(analyticsActions.setSequences(temporaryList));
    return sequenceNamesList;
  };

  //Below sets the sequence list for active sequences
  const getSequencesStatusList = (sequences: any) => {
    const temporaryList: any[] = [];
    const sequenceNamesList: string[] = [];
    sequences.map((sequence: any) => {
      // Extract only the desired properties from each sequence object
      const { Sequence, sequence_status } = sequence;

      // Create a new object with only the desired properties
      const simplifiedSequence = {
        Sequence,
        sequence_status,
      };

      // Push the simplified object to the temporaryList
      temporaryList.push(simplifiedSequence);
      sequenceNamesList.push(Sequence);
    });

    setSequencesList(temporaryList);
    setSequenceFilterList(sequenceNamesList);
    dispatch(sequenceStatusActions.setSequences(temporaryList));
    return sequenceNamesList;
  };

  const handleFilterSequences = (filterList: string[]) => {
    const currentDate = formatDate(new Date());
    const data = {
      client: Cookies.get('fuelStationId'),
      list_of_sequences: filterList.toString(),
      date_start: customDateSelection.date_start
        ? customDateSelection.date_start
        : null,
      date_end: customDateSelection.date_end
        ? customDateSelection.date_end
        : currentDate,
    };

    setSequenceFilterList(filterList);

    if (filterList.length > 0) {
      setLoadingDataTrue();
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-sequences-volumes',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setSequenceVolumes(response.data.data);
                setSequenceVolumesLoading(false);
              })
              .catch(() => {
                setSequenceVolumesLoading(false);
              });

            // Total Leads
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-total-leads',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTotalLeads(response.data.data.leads);
                setTotalLeadsLoading(false);
              })
              .catch(() => {
                setTotalLeadsLoading(false);
              });

            // Email rates data
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-email-rates',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setEmailRatesLoading(false);
                setEmailRatesData(response.data.data);
              })
              .catch(() => {
                setEmailRatesLoading(false);
                setError(true);
              });

            // // Get leads graph data
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/sequences-overview',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setLeadsData(response.data.data);
                setLeadsDataLoading(false);
              })
              .catch(() => {
                setLeadsDataLoading(false);
                setLeadsDataError(true);
              });

            // Top Persona
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-top-persona',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTopPersonaData(response.data.data);
                setTopPersonaLoading(false);
              })
              .catch(() => {
                setTopPersonaLoading(false);
                setTopPersonaError(true);
              });

            // Top Sequence
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-top-sequence',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTopSequenceData(response.data.data);
                setTopSequenceLoading(false);
              })
              .catch(() => {
                setTopSequenceLoading(false);
                setTopSequenceError(true);
              });

            // Projected Leads
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-projected-leads',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setPredictiveLeadsLoading(false);
                setPredictiveLeadsData(response.data.data);
              })
              .catch(() => {
                setPredictiveLeadsLoading(false);
                setPredictiveLeadsError(true);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  };

  //Below is for filtering on active or all sequences
  const handleFilterSequencesStatus = () => {
    const currentDate = formatDate(new Date());
    setCustomDateSelection({ date_start: null, date_end: null });

    //Below checks if the toggle is set to active or not
    if (!activeToggle) {
      if (leadsRedux?.payload) {
        //If toggle is not set to active it checks if the full data redux has data and sets it to that
        setLeadsData(leadsRedux?.payload);
        setSequencesList(sequencesRedux?.payload);
        setSequenceFilterList(getSequences(sequencesRedux?.payload));
        setSequenceVolumes(sequenceVolumesRedux?.payload);
        setTotalLeads(totalLeadsRedux?.payload);
        setPredictiveLeadsData(projectedLeadsRedux?.payload);
        setEmailRatesData(emailRatesRedux?.payload);
        setTopPersonaData(topPersonaRedux?.payload);
        setTopSequenceData(topSequenceRedux?.payload);
      } else {
        //Otherwise it runs getAllTimeData function
        getAllTimeData();
      }
    } else {
      const data = {
        client: Cookies.get('fuelStationId'),
        sequence_status: 'Active',
        date_start: '2000-01-01',
        date_end: currentDate,
      };

      setLoadingDataTrue();

      //Below checks if the redux data exists before running API calls
      if (leadsStatusRedux?.payload) {
        setLeadsData(leadsStatusRedux?.payload);
        setSequencesList(sequencesStatusRedux?.payload);
        setSequenceFilterList(
          getSequencesStatusList(sequencesStatusRedux?.payload)
        );
        setPredictiveLeadsData(projectedLeadsStatusRedux?.payload);
        setSequenceVolumes(sequenceVolumesStatusRedux?.payload);
        setTotalLeads(totalLeadsStatusRedux?.payload);
        setEmailRatesData(emailRatesStatusRedux?.payload);
        setTopPersonaData(topPersonaStatusRedux?.payload);
        setTopSequenceData(topSequenceStatusRedux?.payload);
        //Below it to set all the loading signs back to false once they all load
        setLoadingDataFalse();
      } else {
        auth.onAuthStateChanged((user) => {
          user
            .getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-sequences-volumes',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setSequenceVolumes(response.data.data);
                  setSequenceVolumesLoading(false);
                  getSequencesStatusList(response.data.data);
                  dispatch(
                    sequenceStatusActions.setSequenceVolumes(response.data.data)
                  );
                })
                .catch(() => {
                  setSequenceVolumesLoading(false);
                  setSequenceVolumesError(true);
                });

              // Total Leads
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-total-leads',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setTotalLeads(response.data.data.leads);
                  setTotalLeadsLoading(false);
                  dispatch(
                    sequenceStatusActions.setTotalLeads(
                      response.data.data.leads
                    )
                  );
                })
                .catch(() => {
                  setTotalLeadsLoading(false);
                  setTotalLeadsError(true);
                });
              // Projected Leads
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-projected-leads',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setPredictiveLeadsLoading(false);
                  setPredictiveLeadsData(response.data.data);
                  dispatch(
                    sequenceStatusActions.setProjectedLeads(response.data.data)
                  );
                })
                .catch(() => {
                  setPredictiveLeadsLoading(false);
                  setPredictiveLeadsError(true);
                });

              // Top Persona
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-top-persona',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setTopPersonaData(response.data.data);
                  setTopPersonaLoading(false);
                  dispatch(
                    sequenceStatusActions.setTopPersona(response.data.data)
                  );
                })
                .catch(() => {
                  setTopPersonaLoading(false);
                  setTopPersonaError(true);
                });

              // Top Sequence
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-top-sequence',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setTopSequenceData(response.data.data);
                  setTopSequenceLoading(false);
                  dispatch(
                    sequenceStatusActions.setTopSequence(response.data.data)
                  );
                })
                .catch(() => {
                  setTopSequenceLoading(false);
                  setTopSequenceError(true);
                });

              //Leads Graph
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/sequences-overview',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setLeadsData(response.data.data);
                  setLeadsDataLoading(false);
                  dispatch(sequenceStatusActions.setLeads(response.data.data));
                })
                .catch(() => {
                  setLeadsDataLoading(false);
                  setLeadsDataError(true);
                });

              // Email rates data
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-email-rates',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setEmailRatesLoading(false);
                  setEmailRatesData(response.data.data);
                  dispatch(
                    sequenceStatusActions.setEmailRates(response.data.data)
                  );
                })
                .catch(() => {
                  setEmailRatesLoading(false);
                  setEmailRatesError(true);
                });
            })
            .catch(function (error) {
              // Handle error
            });
        });
      }
    }
  };

  const convertStringToDate = (dateAsString: string) => {
    if (dateAsString == null) {
      return 0;
    }

    // Backend returns dates in the format yyyymmdd
    // We create a Date object for comparison against the filter date
    const day = Number(dateAsString.slice(6, 8));
    const month = Number(dateAsString.slice(4, 6)) - 1;
    const year = Number(dateAsString.slice(0, 4));
    const cellDate = new Date(year, month, day);

    return cellDate;
  };

  const getLast7DaysDate = () => {
    // Calculate in milliseconds in case the day goes to last month
    const result = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    return result;
  };

  const getLast14DaysDate = () => {
    // Calculate in milliseconds in case the day goes to last month
    const result = new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000);
    return result;
  };

  const getLast30DaysDate = () => {
    // Calculate in milliseconds in case the day goes to last month
    const result = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    return result;
  };

  const handleDateFilterClick = (selectedOption: string) => {
    const currentDate = formatDate(new Date());
    switch (selectedOption) {
      case 'Last 7 days':
        const last7DaysDate = getLast7DaysDate();
        filterDataByDate(last7DaysDate, null);
        setCustomDateSelection({
          date_start: formatDate(last7DaysDate),
          date_end: '',
        });
        break;
      case 'Last 14 days':
        const last14DaysDate = getLast14DaysDate();
        filterDataByDate(last14DaysDate, null);
        setCustomDateSelection({
          date_start: formatDate(last14DaysDate),
          date_end: '',
        });
        break;
      case 'Last 30 days':
        const last30DaysDate = getLast30DaysDate();
        filterDataByDate(last30DaysDate, null);
        setCustomDateSelection({
          date_start: formatDate(last30DaysDate),
          date_end: '',
        });
        break;
      case 'All time':
        setCustomDateSelection({
          date_start: '2000-01-01',
          date_end: currentDate,
        });
        setAllTimeHit(!allTimeHit);
        break;
      case 'Custom':
        setDisplayCalendar(true);
        setSelectDateRange(true);
        setCalendarType('Custom');
        break;

      default:
      // Do nothing
    }
  };

  useEffect(() => {
    //I put it in a useEffect to make sure the states had updated first
    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the effect on initial render
    }
    handleFilterSequences(sequenceFilterList);
  }, [allTimeHit]);

  const toggleCalendarVisibility = () => {
    setDisplayCalendar(!displayCalendar);
  };

  const handleCalendarFilter = () => {
    switch (calendarType) {
      case 'Custom':
        const date_start = formatDate(calendarDate[0]);
        const date_end = formatDate(calendarDate[1]);
        const data = {
          client: Cookies.get('fuelStationId'),
          date_start: date_start,
          date_end: date_end,
          list_of_sequences: sequenceFilterList.toString(),
        };
        toggleCalendarVisibility();
        filterDataByDate(new Date(), data);
        setCustomDateSelection({ date_start: date_start, date_end: date_end });
        break;

      default:
      // Do nothing
    }
  };

  const getCopies = () => {
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url: apiEndpoint + 'api/client/' + Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setCopies(response.data.data.copies);
              if (response.data.data.copies.length > 0) {
                dispatch(
                  authActions.setStripeId(
                    response.data.data.copies[0].client__stripe_id
                  )
                );
                dispatch(
                  clientActions.setCalendlyLink(
                    response.data.data.copies[0].client__calendly_link
                  )
                );
              }
            })
            .catch(() => {
              setError(false);
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  };

  const filterDataByDate = (date: any, calendarData: any) => {
    const currentDate = formatDate(new Date());
    const formattedDate = formatDate(date);

    setTotalLeadsLoading(true);
    setEmailRatesLoading(true);
    setSequenceVolumesLoading(true);
    setLeadsDataLoading(true);

    //The page initially loads with sequenceFilterList being an object with Sequence and Sequence_status.
    //When sequences are selected in the dropdown the sequenceFilterList changes to an array of strings

    const data = {
      client: Cookies.get('fuelStationId'),
      date_start: formattedDate,
      date_end: currentDate,
      list_of_sequences: sequenceFilterList.toString(),
    };

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/sequences-overview',
            headers: { Authorization: 'Bearer ' + idToken },
            data: calendarData ? calendarData : data,
          })
            .then((response) => {
              setLeadsData(response.data.data);
              setLeadsDataLoading(false);
            })
            .catch(() => {
              setLeadsDataLoading(false);
              //
            });

          axios({
            method: 'POST',
            url: apiEndpoint + 'api/smartlead-sequences-volumes',
            headers: { Authorization: 'Bearer ' + idToken },
            data: calendarData ? calendarData : data,
          })
            .then((response) => {
              setSequenceVolumes(response.data.data);
              setSequenceVolumesLoading(false);
            })
            .catch(() => {
              setSequenceVolumesLoading(false);
            });

          // Total Leads
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/smartlead-total-leads',
            headers: { Authorization: 'Bearer ' + idToken },
            data: calendarData ? calendarData : data,
          })
            .then((response) => {
              setTotalLeads(response.data.data.leads);
              setTotalLeadsLoading(false);
            })
            .catch(() => {
              setTotalLeadsLoading(false);
            });

          // Projected Leads
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/smartlead-projected-leads',
            headers: { Authorization: 'Bearer ' + idToken },
            data: calendarData ? calendarData : data,
          })
            .then((response) => {
              setPredictiveLeadsLoading(false);
              setPredictiveLeadsData(response.data.data);
            })
            .catch(() => {
              setPredictiveLeadsLoading(false);
              setPredictiveLeadsError(true);
            });

          // Email rates data
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/smartlead-email-rates',
            headers: { Authorization: 'Bearer ' + idToken },
            data: calendarData ? calendarData : data,
          })
            .then((response) => {
              setEmailRatesLoading(false);
              setEmailRatesData(response.data.data);
            })
            .catch(() => {
              setEmailRatesLoading(false);
              setError(true);
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  const leadsToggleChange = () => {
    setDisplayingLeads(!displayingLeads);
  };

  const handlePersonasLinkClick = () => {
    if (isAdmin) {
      navigate(`/admin/personas/${Cookies.get('fuelStationId')}`);
    } else {
      navigate(`/personas/${Cookies.get('fuelStationId')}`);
    }
  };

  const activeToggleChange = () => {
    setActiveToggle(!activeToggle);
  };

  useEffect(() => {
    // After after toggle function is run and the state changes, the function below will run
    if (firstRender.current) {
      firstRender.current = false;
      return; // Skip the effect on initial render
    }
    handleFilterSequencesStatus();
  }, [activeToggle]);

  useEffect(() => {
    const previousMonth = new Date(today);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    setPreviousMonthDate(previousMonth);
  }, []);

  return (
    <div className="dashboard-container">
      {loading ? (
        // Show the spinner while loading is true
        <div className="dashboard-container__spinner-container">
          <SpinnerCircular
            size={40}
            thickness={200}
            speed={100}
            color="var(--sliderEnd)"
            secondaryColor="var(--sliderStart)"
            className="sign-in-spinner"
          />
        </div>
      ) : null}
      {copyModalVisible && (
        <CopyModal
          toggleModal={setCopyModalVisible}
          isOpen={copyModalVisible}
          copies={copies}
        />
      )}
      <Modal
        size="calendar"
        visible={displayCalendar}
        toggleModalVisibility={toggleCalendarVisibility}
      >
        <div className="dashboard-container__calendar-container">
          <Calendar
            onChange={setCalendarDate}
            value={calendarDate}
            selectRange={selectDateRange}
            className="calendar-background"
            showDoubleView={true}
            minDetail="decade"
            showNeighboringMonth={false}
            maxDate={new Date()}
            goToRangeStartOnSelect={false}
            defaultActiveStartDate={previousMonthDate}
            activeStartDate={previousMonthDate}
            onActiveStartDateChange={(data) => {
              if (data.action !== 'onChange') {
                setPreviousMonthDate(data.activeStartDate);
              }
            }}
          />
          <div className="dashboard-container__calendar-container__button-row">
            <button onClick={toggleCalendarVisibility}>Cancel</button>
            <button onClick={handleCalendarFilter}>Apply Filter</button>
          </div>
        </div>
      </Modal>
      <nav className="dashboard-container__navbar">
        <div className="dashboard-container__navbar__section-container">
          <div className="dashboard-container__navbar__section-container__section-item">
            <img src={sequenceIcon} alt="" />
            <SequencesFilter
              options={sequencesList}
              originalOption="Campaigns"
              clickHandler={handleFilterSequences}
              activeToggle={activeToggle}
            />
          </div>
          <div className="dashboard-container__navbar__section-container__section-item">
            <img src={monthIcon} alt="" />
            <AnalyticsDropdown
              options={dateOptions}
              originalOption="All time"
              clickHandler={handleDateFilterClick}
              activeToggle={activeToggle}
            />
          </div>
          <div className="dashboard-container__navbar__section-container__container">
            {/* <h5>Sequence Status</h5> */}
            <div className="dashboard-container__navbar__section-container__container__flex">
              <div className="dashboard-container__navbar__section-container__container__flex__active">
                <div className="sequence-toggle-container dashboard-container__navbar__section-container__container__flex__active__toggle-button">
                  <label className="switch">
                    <input type="checkbox" />
                    <span
                      className="slider round"
                      onClick={activeToggleChange}
                    ></span>
                  </label>
                </div>
              </div>
              <p>{activeToggle ? 'Active' : 'All'}</p>
            </div>
          </div>
        </div>
        {Cookies.get('clientType') === 'SaaS' ? null : (
          <div className="dashboard-container__navbar__section-container">
            <div className="dashboard-container__navbar__section-container__section-item">
              <img src={docs} alt="" />
              <ViewCopy
                copies={copies}
                copyModalVisible={copyModalVisible}
                setCopyModalVisible={setCopyModalVisible}
              />
            </div>
          </div>
        )}
      </nav>

      <div className="dashboard-container__graph-panels">
        <div className="dashboard-container__graph-panels__panel total-leads-hover">
          <TotalLeads
            leads={totalLeads}
            loading={totalLeadsLoading}
            error={totalLeadsError}
            progress={progressData}
          />
        </div>
        <div className="dashboard-container__graph-panels__panel">
          <TopSequence
            topSequence={topSequenceData}
            loading={topSequenceLoading}
            error={topSequenceError}
          />
        </div>
        <div
          className="dashboard-container__graph-panels__panel analytics-top-persona"
          onClick={handlePersonasLinkClick}
        >
          <TopPersona
            isAdmin={isAdmin}
            topPersonaData={topPersonaData}
            loading={topPersonaLoading}
            error={topPersonaError}
          />
        </div>
      </div>

      <div className="dashboard-container__tri-graphs">
        <div className="dashboard-container__tri-graphs__volumes row-span">
          <h2>Volumes</h2>
          <div className="dashboard-container__tri-graphs__volumes__volumes-graph-container">
            <div className="dashboard-container__tri-graphs__volumes__volumes-graph-container__graph">
              <VolumesGraph
                data={sequenceVolumes}
                loading={sequenceVolumesLoading}
                error={sequenceVolumesError}
              />
            </div>
          </div>
        </div>

        <div className="dashboard-container__tri-graphs__last-two-weeks-graphs">
          <h2>Performance</h2>
          <div className="dashboard-container__tri-graphs__last-two-weeks-graphs__last-two-weeks-container">
            <div className="dashboard-container__tri-graphs__last-two-weeks-graphs__last-two-weeks-container__graph">
              <PerformanceRates
                emailRatesData={emailRatesData}
                predictiveLeadsData={predictiveLeadsData}
                loading={emailRatesLoading}
                error={emailRatesError}
              />
            </div>
          </div>
        </div>

        <div className="dashboard-container__tri-graphs__leads-graph">
          <div className="dashboard-container__navbar__section-container__container__flex leads-toggle">
            <div className="dashboard-container__navbar__section-container__container__flex__active">
              <div className="sequence-toggle-container dashboard-container__navbar__section-container__container__flex__active__toggle-button">
                <label className="switch">
                  <input type="checkbox" />
                  <span
                    className="slider round"
                    onClick={leadsToggleChange}
                  ></span>
                </label>
              </div>
            </div>
            <p>{displayingLeads ? 'Leads' : 'Overview'}</p>
          </div>

          <div className="dashboard-container__tri-graphs__leads-graph__graph-container">
            <div className="dashboard-container__tri-graphs__leads-graph__graph-container__graph">
              <LeadsGraph
                data={leadsData}
                loading={leadsDataLoading}
                error={leadsDataError}
                leadsOnlyToggle={displayingLeads}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
