import { createSlice } from '@reduxjs/toolkit';

const personasSlice = createSlice({
  name: 'personas',
  initialState: {
    tableData: null,
    industryData: null,
    businessSizeData: null,
    titleData: null,
    sequences: null,
  },
  reducers: {
    setTableData(state, payload) {
      state.tableData = payload;
    },
    setIndustryData(state, payload) {
      state.industryData = payload;
    },
    setBusinessSizeData(state, payload) {
      state.businessSizeData = payload;
    },
    setTitleData(state, payload) {
      state.titleData = payload;
    },
    setSequences(state, payload) {
      state.sequences = payload;
    },
  },
});

export const personasActions = personasSlice.actions;

export default personasSlice;
