import { createSlice } from '@reduxjs/toolkit';

const copyDraftSlice = createSlice({
  name: 'copyDraft',
  initialState: { copyIndex: null },
  reducers: {
    updateCopyIndex(state, payload) {
      state.copyIndex = payload;
    },
  },
});

export const copyDraftActions = copyDraftSlice.actions;

export default copyDraftSlice;
