import React, { useEffect, useState } from 'react';
import './signIn.scss';
import logo from '../../assets/sideBar/ctrl-logo.svg';
import auth from '../../firebase/init';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { authActions } from '../../redux/auth-slice';
import { SpinnerCircular } from 'spinners-react';
import Cookies from 'js-cookie';
import { showErrorToast } from '../../utils/toast';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { clientTypeActions } from '../../redux/clientType-slice';

interface IType {
  type: string;
  payload: any;
}

interface IClientTypeSlice {
  clientType: IType;
}

interface IStore {
  clienttype: IClientTypeSlice;
}

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientTypeRedux = useSelector(
    (state: IStore) => state.clienttype.clientType
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fetchingClientData, setFetchingClientData] = useState(false);
  const [accountNotRegistered, setAccountNotRegistered] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_URL;

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '2ed1615c-62a9-481c-bbeb-5030aecd4776';
    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();

    const loginString = Cookies.get('loggedIn');
    const displayName = Cookies.get('displayName');
    const photoURL = Cookies.get('photoURL');
    const admin = Cookies.get('admin');

    if (loginString && displayName && photoURL) {
      navigate(`/analytics/${displayName}`);
    } else if (loginString && admin) {
      navigate('/admin');
    }
  }, []);

  const signIn = (e: Event) => {
    e.preventDefault();
    setFetchingClientData(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            // navigate to admin page if user is an admin
            const parent: any = idTokenResult.claims.uuid;
            dispatch(authActions.adminLogin());
            dispatch(authActions.login());
            Cookies.set('loggedIn', 'yes', { expires: 1 });
            Cookies.set('isAdmin', 'yes', { expires: 1 });
            Cookies.set('parent', parent, { expires: 1 });
            Cookies.set('clientType', 'SaaS', { expires: 1 });
            dispatch(clientTypeActions.setClientType('SaaS'));
            navigate('/admin');
          } else {
            auth.onAuthStateChanged((user) => {
              user
                .getIdTokenResult()
                .then((idTokenResult) => {
                  const child: any = idTokenResult.claims.uuid;
                  dispatch(authActions.login());
                  Cookies.set('loggedIn', 'yes', { expires: 1 });
                  Cookies.set('child', child, { expires: 1 });
                  Cookies.set('fuelStationId', child, {
                    expires: 1,
                  });
                  Cookies.set('inconsistentClientName', 'false', {
                    expires: 1,
                  });
                  Cookies.set('displayName', user.displayName, {
                    expires: 1,
                  });
                  Cookies.set('loggedIn', 'yes', { expires: 1 });

                  axios({
                    method: 'GET',
                    url: apiEndpoint + 'api/v2/client/' + child,
                    // headers: { Authorization: 'Bearer ' + idToken },
                  })
                    .then((response) => {
                      Cookies.set(
                        'parentStatus',
                        response.data.data.parent_subscription_status,
                        { expires: 1 }
                      );
                      Cookies.set(
                        'parentPackage',
                        response.data.data.parent_package,
                        { expires: 1 }
                      );
                      if (response.data.data.client[0].ftf_client === false) {
                        Cookies.set('clientType', 'SaaS', { expires: 1 });
                        dispatch(clientTypeActions.setClientType('SaaS'));
                      } else {
                        Cookies.set('clientType', 'dark', { expires: 1 });
                        dispatch(clientTypeActions.setClientType('dark'));
                      }
                    })
                    .catch((error) => {
                      // console.log(error);
                    })
                    .finally(() => {
                      if (
                        Cookies.get('parentPackage') !== 'Agency' &&
                        Cookies.get('clientType') === 'SaaS'
                      ) {
                        navigate(`/package-upgrade`);
                      } else if (
                        Cookies.get('parentStatus') === 'active' ||
                        Cookies.get('parentStatus') === 'trialing' ||
                        Cookies.get('clientType') !== 'SaaS'
                      ) {
                        navigate(`/analytics/${user.displayName}`);
                      } else {
                        navigate(`/package-upgrade`);
                      }
                    });

                  // Get sequences count or sums
                  // axios({
                  //   method: 'GET',
                  //   url: apiEndpoint + 'api/master-ids/' + user.displayName,
                  //   headers: { Authorization: 'Bearer ' + idToken },
                  // })
                  //   .then((response) => {
                  //     if (response.data.data.error === 'Client Not Found') {
                  //       showErrorToast('Client Not Found');
                  //       setFetchingClientData(false);
                  //     } else {
                  //       Cookies.set('fuelStationId', response.data.data.id, {
                  //         expires: 1,
                  //       });
                  //       Cookies.set('inconsistentClientName', 'false', {
                  //         expires: 1,
                  //       });
                  //       Cookies.set('displayName', user.displayName, {
                  //         expires: 1,
                  //       });
                  //       Cookies.set('loggedIn', 'yes', { expires: 1 });
                  //       dispatch(authActions.login());
                  //       navigate(`/analytics/${user.displayName}`);
                  //     }
                  //   })
                  //   .catch(() => {
                  //     //
                  //   });
                })
                .catch(function (error) {
                  // Handle error
                });
            });
          }
        });
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        setFetchingClientData(false);
        if (errorCode === 'auth/wrong-password') {
          showErrorToast('Invalid email or password. Please try again.');
        } else if (errorCode === 'auth/user-not-found') {
          showErrorToast(
            'We couldn’t find your account. Email product@fueltofly.com to create one or ask a team member to invite you.'
          );
        } else {
          showErrorToast('An error occured, please notify us via our chatbot.');
        }
      });
  };

  return (
    <div className="sign-in-page-container">
      <ToastContainer />
      <div className="sign-in-form-container">
        <div className="sign-in-heading-section">
          <img src={logo} alt="Fueltofly Logo" />
        </div>
        <form className="sign-in-form">
          <label className="input-item">
            <input
              type="text"
              name="email"
              placeholder=" "
              onChange={(e) => setEmail(e.target.value)}
            />
            <span>Email</span>
          </label>
          <label className="input-item">
            <input
              type="password"
              name="password"
              placeholder=" "
              onChange={(e) => setPassword(e.target.value)}
            />
            <span>Password</span>
          </label>
          {fetchingClientData ? (
            <div className="sign-in-spinner-container">
              <SpinnerCircular
                size={30}
                thickness={200}
                speed={100}
                color="#D5EF10"
                secondaryColor="#5A6600"
                className="sign-in-spinner"
              />
            </div>
          ) : null}
          <button type="submit" onClick={() => signIn(event)}>
            Log In
          </button>
        </form>
        <div className="forgot-password">
          <p onClick={() => navigate('/forgot-password')}>Forgot password?</p>
        </div>
        <div className="forgot-password">
          <p>
            <a href="mailto:support@ctrlroom.ai">
              *Email support@ctrlroom.ai to get a signup link.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
