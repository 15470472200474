// Fundamentals
import React, { useState } from 'react';

// Styles
import './faq.scss';

//Components

//Images

const Faq = () => {
  const [expandedItem, setExpandedItem] = useState(null);

  const toggleItem = (index: number) => {
    if (index === expandedItem) {
      // Clicking on an already expanded item collapses it
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  const replaceNewlinesWithBreaks = (text: any) => {
    return text.split('\n').map((line: any, index: any) => (
      <React.Fragment key={index}>
        {line}
        <br />
        <br />
      </React.Fragment>
    ));
  };

  const items = [
    {
      title:
        "There's a public holiday coming up. Please could you pause outreach on those specific days?",
      content:
        'Our sending tool automatically picks this up and won’t contact prospects if there’s a Public Holiday. Will turn off campaigns for good measure though.',
    },
    {
      title: 'Can you change/add a sender?',
      content:
        'Although it is not recommended to change a sender as it will put a delay on sequencing and can stifle results, it is possible. Please provide us with the email address. Please note that we charge $250 per sender.',
    },
    {
      title: 'When can I expect the first sequence to go live?',
      content:
        'After filling in the Sequence Request form, it generally takes 5-10 business days for it to go live, but this also depends on the back log.',
    },
    {
      title:
        'When will the next step go off & what is the number of audience on it?',
      content:
        'The schedule differs, but generally: \nStep 1: Day 1 \nStep 2: Day 3/4 \nStep 3: Day 10-12 \nStep 4: Day 14/15. \nWe start off by sending 25 emails then start increasing the volumes pretty quickly. Our usual daily ramp-up schedule pushes to get to over 1000 emails/day in week 3. First 10 weekdays: 25, 50, 75, 125,175, 250, 325, 425, 525, 650.',
    },
    {
      title: 'Can I make changes to a sequence or request a new one?',
      content:
        'You can make changes to a sequence or request a new one by logging in your client portal {insert client portal link + password}.',
    },
    {
      title: 'Responses landing in my spam folder?',
      content:
        "It could be because we pushed up your volumes but this does happen sometimes from the forwarding Gmail account when it has to forward across more emails than before. \nSo do keep an eye on your spam folder for the replies. \nIt doesn't necessarily mean that the outbound emails are landing in spam, because the original outbound email is sent from a Sendgrid smtp whereas the reply forwarded to your inbox is from a google smtp.",
    },
    {
      title: 'What is A/B Testing:',
      content:
        'Each step of a sequence will always have 2-4 variants – having more than one version of an email going out at each step allows us to make data-backed iterations to copy and prospecting after a certain time period. \n Note that variant A for step 1 will not always be paired with variant A from subsequent steps. \nProspects receive a random variant at each step, which allows for randomized, unbiased testing and we write the copy with this in mind.',
    },
    {
      title: 'Importance of a 4 step sequence:',
      content:
        'Step 1 (initial outreach) on day 1.\nStep 2 (follow-up) on day 3.\nStep 3 (additional information) on day 9.\nStep 4 (break-up email) on day 14.\nThe reason for having more than one email in a sequence is that there are many reasons why someone might not respond to an email.They might be busy, forget to respond, or they might not be convinced yet. \nThe follow-up emails serve to remind them about the offer and provide more information to help them make a decision.',
    },
  ];

  return (
    <div className="faq-container">
      <h1>FAQ</h1>
      <div className="faq-container__questions">
        {items.map((item, index) => (
          <div key={index} className="faq-container__questions__accordion-item">
            <div
              className={`faq-container__questions__accordion-item__accordion-title ${
                index === expandedItem ? 'active' : ''
              }`}
              onClick={() => toggleItem(index)}
            >
              {item.title}
              <span className="faq-container__questions__accordion-item__accordion-title__symbol">
                {index === expandedItem ? '-' : '+'}
              </span>
            </div>
            {index === expandedItem && (
              <div className="faq-container__questions__accordion-item__accordion-content">
                {replaceNewlinesWithBreaks(item.content)}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
