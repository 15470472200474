import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'client',
  initialState: {
    calendlyLink: '',
    imageUrl: '',
    sequences: [],
    senders: [],
    copies: [],
    newClient: false,
    paymentMethods: true,
    dataStudioLink: '',
    dummyReport: false,
    client404: false,
    clientHasntSignedUp: false,
    betaModalVisible: true,
  },
  reducers: {
    setCalendlyLink(state, payload) {
      state.calendlyLink = payload;
    },
    setImageUrl(state, payload) {
      state.imageUrl = payload;
    },
    setSequences(state, payload) {
      state.sequences = payload;
    },
    setSenders(state, payload) {
      state.senders = payload;
    },
    setCopies(state, payload) {
      state.copies = payload;
    },
    setNewClient(state, payload) {
      state.newClient = payload;
    },
    setPaymentMethods(state, payload) {
      state.paymentMethods = payload;
    },
    setDataStudioLink(state, payload) {
      state.dataStudioLink = payload;
    },
    setDummyReport(state, payload) {
      state.dummyReport = payload;
    },
    setClient404(state, payload) {
      state.client404 = payload;
    },
    setClientHasntSignedUp(state, payload) {
      state.clientHasntSignedUp = payload;
    },
    setBetaModalVisible(state, payload) {
      state.betaModalVisible = payload;
    },
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice;
