// Fundamentals
import React, { useEffect, useState } from 'react';
import {
  Pie,
  PieChart,
  Legend,
  ResponsiveContainer,
  Cell,
  Tooltip,
} from 'recharts';

// Styles
import './volumesGraph.scss';
import { pathToFileURL } from 'url';
import Cookies from 'js-cookie';

//Components

//Images

// Interfaces
interface DataItem {
  name: string;
  value: number;
  campaignStatus: string;
}

interface ISequenceVolumeItem {
  Sequence: string;
  percentage_of_volumes: number;
}

interface IVolumes {
  data: ISequenceVolumeItem[];
  loading: boolean;
  error: boolean;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: DataItem }>;
}

interface CustomLegendProps {
  payload?: Array<{ payload: ISequenceVolumeItem }>;
}

const VolumesGraph = ({ data, loading, error }: IVolumes) => {
  const [colorOptions, setColorOptions] = useState([]);
  //Getting the values I want for the tooltip
  const volumeData =
    data?.map((item: any) => ({
      name: `${item.Sequence}`,
      value: parseFloat(item.percentage_of_volumes),
      campaignStatus: item.sequence_status,
    })) || [];

  const [activeName, setActiveName] = useState<string | null>(null);

  const handleLegendMouseEnter = (entry: any, index: number) => {
    setActiveName(entry.payload.name);
  };

  const handleLegendMouseLeave = () => {
    setActiveName(null);
  };

  useEffect(() => {
    Cookies.get('clientType') === 'SaaS'
      ? setColorOptions(saasColors)
      : setColorOptions(colors);
  }, []);

  //Setting the color options for the different values
  const colors = [
    '#7E4DE5',
    '#34CCCD',
    '#003939',
    '#0075FF',
    '#00326C',
    '#B257C2',
    '#3ECE5E',
    '#4510B6',
    '#1A0051',
    '#00811C',
    '#380541',
    '#721C81',
    '#001125',
    '#008586',
    '#002709',
  ];

  const saasColors = [
    '#FFD700',
    '#ADFF2F',
    '#2E8B57',
    '#FF6347',
    '#FF8C00',
    '#32CD32',
    '#808080',
    '#4682B4',
    '#00FA9A',
    '#FFFFFF',
    '#000000',
  ];

  const formatLegendText = (value: string) => (
    <span style={{ color: 'var(--blackTextToWhiteText)' }}>{value}</span>
  );

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { name, value, campaignStatus } = payload[0].payload;
      return (
        <div className="volumes-tooltip">
          <p className="label">
            Campaign: <b>{name}</b>
          </p>
          <p className="label">{`Volume: ${value}`}%</p>
          <p className="label">{`Status: ${campaignStatus}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderLegend = ({ payload }: CustomLegendProps) => {
    const data = payload;

    return (
      <div className={`volumes-graph-legend`}>
        {data.map((entry: any, index: number) => {
          const handleMouseEnter =
            data.length > 30
              ? undefined
              : () => handleLegendMouseEnter(entry, index);
          const handleMouseLeave =
            data.length > 30 ? undefined : handleLegendMouseLeave;

          return (
            <div
              key={`legend-${index}`}
              className={`volumes-graph-legend__container`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className="volumes-graph-legend__container__value-container"
                style={{
                  backgroundColor: colorOptions[index % colorOptions.length],
                }}
              ></div>

              <div>
                <span className="volumes-graph-legend__container__name">
                  {entry.payload.name}
                </span>
                <span className="volumes-graph-legend__container__date">
                  {entry.payload.date}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-volume">
          <div className="loading-block loading-container-volume__chart"></div>
          <div className="loading-block loading-container-volume__legend"></div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message-volume">
          Data could not be loaded. Please try again later
        </div>
      ) : data?.length < 1 ? (
        // Display error message when data is loaded but there is no data to display
        <div className="error-message-volume">No data to display yet</div>
      ) : (
        <div>
          {data ? (
            <div className="volumes-graph-container">
              <ResponsiveContainer
                width="100%"
                height={data.length > 16 ? 800 : 500}
              >
                <PieChart id="container">
                  <Pie
                    data={volumeData}
                    dataKey="value"
                    className="volumes-graph-container__graph"
                    stroke="black"
                  >
                    {volumeData?.map((dataItem: DataItem, index: number) => (
                      <Cell
                        key={`cell-${dataItem.name}`} // Use a unique key based on dataItem.name
                        fill={colorOptions[index % colorOptions.length]} // Fill color for all sections
                        stroke={activeName === dataItem.name ? 'white' : 'none'} // Outline color
                        strokeWidth={activeName === dataItem.name ? 2 : 0}
                        height={500}
                        width={500}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ fill: 'none' }}
                    wrapperStyle={{ zIndex: 1000 }}
                  />
                  <Legend
                    verticalAlign="bottom"
                    formatter={formatLegendText}
                    content={(props: any) => renderLegend(props)}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default VolumesGraph;
