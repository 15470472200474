//Fundamentals
import React, { useEffect, useState } from 'react';

// Styles
import './apiKey.scss';

//Images
import smartLeadLogo from '../../../assets/register/smartlead-icon.svg';
import backBtn from '../../../assets/register/back-btn.png';

interface ApiKey {
  onApiKeyComplete: any;
  onApiKeyBack(): any;
  inputValues: any;
  setInputValues: any;
}

const ApiKey = ({
  onApiKeyComplete,
  onApiKeyBack,
  inputValues,
  setInputValues,
}: ApiKey) => {
  const [apiKey, setApiKey] = useState('');
  const [greaterThan12, setGreaterThan12] = useState(false);

  const apiKeyCheck = inputValues.apiKey || '';
  const characterCount = apiKeyCheck.length;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Perform any necessary form handling logic here

    // Notify the parent component that sign-up is complete
    setGreaterThan12(apiKey.length !== 44);

    if (apiKey.length === 44 || characterCount === 44) {
      setGreaterThan12(false);
      onApiKeyComplete();
    }
  };

  const handleSkip = (e: any) => {
    e.preventDefault();
    // Perform any necessary form handling logic here

    onApiKeyComplete();
  };

  const handleBack = () => {
    // Call the onBack function passed as a prop to go back to the sign-up page
    onApiKeyBack();
  };

  return (
    <div className="api-key-block">
      <div className="api-key-block__header">
        <img
          src={backBtn}
          onClick={handleBack}
          className="api-key-block__header__back-btn"
        ></img>
        <div className="api-key-block__header__smartlead">
          <img src={smartLeadLogo} alt="" />
          <h2>Smartlead.ai</h2>
        </div>
      </div>

      <form action="#" onSubmit={handleSubmit} className="api-key-block__form">
        <div className="api-key-block__form__key">
          <label htmlFor="key">Smartlead API Key</label>
          {greaterThan12 ? (
            <span className="api-key-block__form__key__error">
              Smartlead key needs to be 44 characters in length
            </span>
          ) : null}
          <input
            type="text"
            placeholder="Add Smartlead API Key"
            className="onboarding-input"
            required
            value={inputValues.apiKey}
            onChange={(e) => {
              setApiKey(e.target.value);
              setInputValues((prevValues: any) => ({
                ...prevValues,
                apiKey: e.target.value,
              }));
            }}
          />
          <p>
            Learn{' '}
            <span>
              <a
                href="https://helpcenter.smartlead.ai/en/articles/125-full-api-documentation"
                target="_blank"
                rel="noopener noreferrer"
              >
                how to find your API Key
              </a>
            </span>
          </p>
          <p>Your key will be encrypted and securely stored on our servers</p>
        </div>
        <div className="api-key-block__submit">
          {/* <button className="api-key-block__submit__skip" onClick={handleSkip}>
            Skip
          </button> */}
          <button type="submit" className="api-key-block__submit__submit-btn">
            Connect
          </button>
        </div>
      </form>
    </div>
  );
};

export default ApiKey;
