// Fundamentals
import React from 'react';
import './trialExpired.scss';
import Cookies from 'js-cookie';
import auth from '../../firebase/init';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../redux/auth-slice';

const TrialExpiredPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logOut = () => {
    auth.signOut();
    window.location.reload();
    Cookies.remove('isAdmin');
    Cookies.remove('loggedIn');
    Cookies.remove('displayName');
    Cookies.remove('photoURL');
    Cookies.remove('fuelStationId');
    Cookies.remove('clientType');
    Cookies.remove('parentStatus');
    dispatch(authActions.logout());
    navigate('/');
  };
  return (
    <div className="trial-expired">
      <div className="expired-text">
        <p>
          Thank you for trying our service! To continue enjoying full access,
          please contact your agency to upgrade your package.
        </p>
        <button
          type="submit"
          className="sequence-button"
          onClick={() => logOut()}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default TrialExpiredPage;
