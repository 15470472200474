import { Color } from '../../models/colorModels';
import { ThemeType, Theme } from './themeModal';

export const THEMES: Record<ThemeType, Theme> = {
  light: {
    '--whiteToLightBlue': Color.WHITE,
    '--whiteToInherit': Color.WHITE,
    '--darkBlueTextToLightBlueText': Color.DARK_BLUE_TEXT,
    '--lightGreyToLightBlue': Color.LIGHT_GREY,
    '--lightGreyToDarkestBlue': Color.LIGHT_GREY,
    '--mediumGreyToMediumBlue': Color.MEDIUM_GREY,
    '--mediumGreyToLightBlue': Color.MEDIUM_GREY,
    '--darkGreyToDarkestBlue': Color.DARK_GREY,
    '--darkGreyToDarkBlue': Color.DARK_GREY,
    '--blackTextToBlueText': Color.BLACK_TEXT,
    '--blackTextToWhiteText': Color.BLACK_TEXT,
    '--greyTextToWhiteText': Color.GREY_TEXT,
    '--greyBorderToBlueBorder': Color.GREY_BORDER,
    '--whiteToSideBarBlue': Color.WHITE,
    '--blackSvgToWhiteSvg': Color.SVG_BLACK,
    '--darkestBlueToInherit': Color.DARKEST_BLUE,
    '--sliderStart': Color.SLIDER_START,
    '--sliderEnd': Color.SLIDER_END,
    '--activeMenu': Color.DARK_BLUE,
    '--blueToBlue': Color.DARKEST_BLUE,
    '--tableRow': Color.DARK_BLUE_TEXT,
    '--toolTip': Color.TOOLTIP,
    '--buttonHover': Color.BUTTON_HOVER,
  },

  dark: {
    '--whiteToLightBlue': Color.LIGHT_BLUE,
    '--whiteToInherit': Color.INHERIT_COLOR,
    '--darkBlueTextToLightBlueText': Color.BLUE_BORDER,
    '--lightGreyToLightBlue': Color.LIGHT_BLUE,
    '--lightGreyToDarkestBlue': Color.DARKEST_BLUE,
    '--mediumGreyToMediumBlue': Color.MEDIUM_BLUE,
    '--mediumGreyToLightBlue': Color.LIGHT_BLUE,
    '--darkGreyToDarkestBlue': Color.DARKEST_BLUE,
    '--darkGreyToDarkBlue': Color.DARK_BLUE,
    '--blackTextToBlueText': Color.BLUE_TEXT,
    '--blackTextToWhiteText': Color.WHITE,
    '--greyTextToWhiteText': Color.WHITE,
    '--greyBorderToBlueBorder': Color.BLUE_BORDER,
    '--whiteToSideBarBlue': Color.RIGHT_SIDE_BAR_BLUE,
    '--blackSvgToWhiteSvg': Color.SVG_WHITE,
    '--darkestBlueToInherit': Color.INHERIT_COLOR,
    '--sliderStart': Color.SLIDER_START,
    '--sliderEnd': Color.SLIDER_END,
    '--activeMenu': Color.DARK_BLUE,
    '--blueToBlue': Color.DARKEST_BLUE,
    '--tableRow': Color.DARK_BLUE_TEXT,
    '--toolTip': Color.TOOLTIP,
    '--buttonHover': Color.BUTTON_HOVER,
  },

  sass: {
    '--whiteToLightBlue': Color.SDARKER_GREY,
    '--whiteToInherit': Color.INHERIT_COLOR,
    '--darkBlueTextToLightBlueText': Color.SDARKEST_GREY,
    '--lightGreyToLightBlue': Color.SDARKER_GREY,
    '--lightGreyToDarkestBlue': Color.SDARKER_GREY,
    '--mediumGreyToMediumBlue': Color.SMEDIUM_GREY,
    '--mediumGreyToLightBlue': Color.SDARKER_GREY,
    '--darkGreyToDarkestBlue': Color.SDARKEST_GREY,
    '--darkGreyToDarkBlue': Color.SDARKEST_GREY,
    '--blackTextToBlueText': Color.BLUE_TEXT,
    '--blackTextToWhiteText': Color.WHITE,
    '--greyTextToWhiteText': Color.WHITE,
    '--greyBorderToBlueBorder': Color.SLIDER_END_YELLOW,
    '--whiteToSideBarBlue': Color.SMEDIUM_GREY,
    '--blackSvgToWhiteSvg': Color.SVG_WHITE,
    '--darkestBlueToInherit': Color.INHERIT_COLOR,
    '--sliderStart': Color.SLIDER_START_YELLOW,
    '--sliderEnd': Color.SLIDER_END_YELLOW,
    '--activeMenu': Color.SLIDER_START_YELLOW,
    '--blueToBlue': Color.SDARKEST_GREY,
    '--tableRow': Color.TABLE_YELLOW,
    '--toolTip': Color.TOOLTIP_SAAS,
    '--buttonHover': Color.BUTTON_HOVER_SAAS,
  },
};
