// Fundamentals
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Styles
import './industryGraph.scss';
import Cookies from 'js-cookie';

//Components

//Images

interface IndustryGraphProps {
  data: DataPoint[];
  loading: boolean; // Prop for loading state
  error: boolean;
  missingData: boolean;
}

interface DataPoint {
  Industry: string;
  interested_rate: number;
  not_interested_rate: number;
  total_contacted: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: DataPoint }>;
}

const IndustryGraph = ({
  data,
  loading,
  error,
  missingData,
}: IndustryGraphProps) => {
  const gradientId = 'notInterested';
  const [secondColor, setSecondColor] = useState('');
  const [thirdColor, setThirdColor] = useState('');

  useEffect(() => {
    if (Cookies.get('clientType') === 'SaaS') {
      setSecondColor('#5A6600');
      setThirdColor('rgba(90, 102, 0, 0.40)'); // Example of setting third color
    } else {
      setSecondColor('#00305C');
      setThirdColor('rgba(0, 48, 92, 0.40)'); // Example of setting third color
    }
  }, []);

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const {
        Industry,
        interested_rate,
        not_interested_rate,
        total_contacted,
      } = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Industry: ${Industry.charAt(
            0
          ).toUpperCase()}${Industry.slice(1)}`}</p>
          <p className="label">{`Interested Rate: ${interested_rate}%`}</p>
          <p className="label">{`Not Interested Rate: ${not_interested_rate}%`}</p>
          <p className="label">{`Total Contacted: ${total_contacted}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="industry-graph-container">
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-industry">
          <div className="loading-block loading-container-industry__graph"></div>
          <div className="loading-container-industry__flex">
            <div className="loading-block loading-container-industry__flex__label"></div>
            <div className="loading-block loading-container-industry__flex__label"></div>
            <div className="loading-block loading-container-industry__flex__label"></div>
            <div className="loading-block loading-container-industry__flex__label"></div>
          </div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later.
        </div>
      ) : data?.length < 1 && missingData ? (
        // Display error message when data could not be loaded
        <div className="loading-container-industry">
          <div className="missing-data loading-container-industry__graph"></div>
          <div className="loading-container-industry__flex">
            <div className="missing-data loading-container-industry__flex__label"></div>
            <div className="missing-data loading-container-industry__flex__label"></div>
            <div className="missing-data loading-container-industry__flex__label"></div>
            <div className="missing-data loading-container-industry__flex__label"></div>
          </div>
        </div>
      ) : data?.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <>
          <ResponsiveContainer>
            <BarChart
              height={300}
              data={data?.slice(0, 10)}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid vertical={false} stroke={secondColor} />
              <defs>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={secondColor} />
                  <stop offset="100%" stopColor={thirdColor} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="Industry"
                angle={-20}
                height={80}
                tickMargin={40}
                stroke="var(--blackTextToWhiteText)"
                style={{ fontSize: '10px', textTransform: 'capitalize' }}
                interval={0}
              />
              <YAxis
                type="number"
                allowDataOverflow={true}
                tickFormatter={(tick) => {
                  return `${tick}%`;
                }}
              />
              <Tooltip
                content={<CustomTooltip />}
                position={{ y: 60 }}
                cursor={{ fill: 'none' }}
              />
              <Bar dataKey="interested_rate" stackId="a" radius={[8, 8, 0, 0]}>
                {data.map((entry, index) =>
                  entry.not_interested_rate === 0 ? (
                    <Cell
                      key={`cell-${index}`}
                      fill={'var(--greyBorderToBlueBorder)'}
                    />
                  ) : (
                    <Cell
                      key={`cell-${index}`}
                      fill={'var(--greyBorderToBlueBorder)'}
                      radius={0}
                    />
                  )
                )}
              </Bar>
              <Bar
                dataKey="not_interested_rate"
                stackId="a"
                radius={[8, 8, 0, 0]}
                fill={`url(#${gradientId})`}
              >
                {data?.map((entry, index) => (
                  <Cell key={`cell-${index}`} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};

export default IndustryGraph;
