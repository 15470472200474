import React, { useState } from 'react';

// Styles
import './bestWorstDropdown.scss';

//Images
import sequenceIcon from '../../../../assets/dashboard/dashboard-sequence-icon.svg';

interface DropdownProps {
  options: string[];
  originalOption: any;
  clickHandler?: (sequence: string) => void;
}

const BestWorstDropdown = ({
  options,
  originalOption,
  clickHandler,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);

    if (clickHandler) {
      clickHandler(option);
    }
  };

  return (
    <div className={`best-worst-dropdown${isOpen ? 'open' : ''}`}>
      <div
        className="best-worst-dropdown__dropdown-header"
        onClick={toggleDropdown}
      >
        <div className="best-worst-dropdown__dropdown-header__image">
          <img src={sequenceIcon} alt="" />
          <p>{selectedOption || originalOption}</p>
        </div>
        <div
          className={`best-worst-dropdown__dropdown-header__arrow ${
            isOpen ? 'up' : ''
          }`}
        ></div>
      </div>
      {isOpen && (
        <div className="best-worst-dropdown__dropdown-options">
          {options?.map((option) => (
            <div
              key={option}
              className="best-worst-dropdown__dropdown-options__dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BestWorstDropdown;
