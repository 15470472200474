// Fundamentals
import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

// Styles
import './personasTable.scss';

//Images
import TableIcon from '../../../../assets/dashboard/personas/personas-table-person.svg';
import { SortDirection } from 'ag-grid-community';
import tableIconSaas from '../../../../assets/dashboard/personas/top-persona-person-saas.svg';
import Cookies from 'js-cookie';

interface IPersonasTable {
  sequences: any[];
  tableData: any;
  loading: boolean;
  error: boolean;
  missingData: boolean;
  filterTableSequences?: (filterList: string[]) => void;
}

const PersonasTable = ({
  tableData,
  loading,
  error,
  missingData,
}: IPersonasTable) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  //Below code is for rendering the image
  const imageCellRenderer = (params: any) => {
    return (
      <div className="personas-table-container__image-cell">
        <img
          src={Cookies.get('clientType') === 'SaaS' ? tableIconSaas : TableIcon}
          alt=""
          className="personas-table-container__image"
        />
        {params.value}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: 'Title',
      field: 'Title',
      filter: 'agSetColumnFilter',
      cellRenderer: imageCellRenderer,
      resizable: true,
      minWidth: 250,
    },
    {
      headerName: 'Industry',
      field: 'Industry',
      filter: 'agSetColumnFilter',
      resizable: true,
      cellStyle: { textTransform: 'capitalize' },
    },
    {
      headerName: 'Size',
      field: 'Size',
      filter: 'agSetColumnFilter',
      resizable: true,
      filterParams: {
        comparator: (a: any, b: any) => {
          const valA = parseInt(a);
          const valB = parseInt(b);
          if (valA === valB) return 0;
          return valA > valB ? 1 : -1;
        },
      },
    },
    {
      headerName: 'Open Rate',
      field: 'open_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        if (params.value != null) {
          return params.value + '%';
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Reply Rate',
      field: 'reply_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        if (params.value != null) {
          return params.value + '%';
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Interested Rate',
      field: 'possible_conversation_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        if (params.value != null) {
          return params.value + '%';
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Conversion Rate',
      field: 'Conversion_Rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        if (params.value != null) {
          return params.value + '%';
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Total Delivered',
      field: 'total_contacted',
      filter: 'agNumberColumnFilter',
      resizable: true,
      sort: 'desc' as SortDirection,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      menuTabs: ['filterMenuTab'] as any,
      filterParams: {
        defaultToNothingSelected: true,
      },
    };
  }, []);

  //Below code is to have the graph resize on windows resize
  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [gridApi]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit(); //Sets the width of the columns to use the full width
  };

  const paginationPageSize = 11;

  const LoadingContainerTableRow = () => {
    return (
      <div className="loading-container-table__row">
        <div className="loading-container-table__row__column flex">
          <div className="loading-block loading-container-table__row__column__image"></div>
          <div className="loading-block loading-container-table__row__column__text"></div>
        </div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
      </div>
    );
  };

  const MissingDataTableRow = () => {
    return (
      <div className="loading-container-table__row">
        <div className="loading-container-table__row__column flex">
          <div className="missing-data loading-container-table__row__column__image"></div>
          <div className="missing-data loading-container-table__row__column__text"></div>
        </div>
        <div className="missing-data loading-container-table__row__column"></div>
        <div className="missing-data loading-container-table__row__column"></div>
        <div className="missing-data loading-container-table__row__column"></div>
        <div className="missing-data loading-container-table__row__column__small"></div>
        <div className="missing-data loading-container-table__row__column__small"></div>
        <div className="missing-data loading-container-table__row__column__small"></div>
      </div>
    );
  };

  return (
    <div className="personas-table-container">
      <h2>Personas</h2>
      <div className="full-personas-table">
        {loading ? (
          // Show the spinner while loading is true
          <div className="loading-container-table">
            <LoadingContainerTableRow />
            <LoadingContainerTableRow />
            <LoadingContainerTableRow />
            <LoadingContainerTableRow />
          </div>
        ) : error ? (
          // Display error message when data could not be loaded
          <div className="error-message">
            Data could not be loaded. Please try again later.
          </div>
        ) : tableData?.length < 1 && missingData ? (
          <div className="loading-container-table">
            <MissingDataTableRow />
            <MissingDataTableRow />
            <MissingDataTableRow />
            <MissingDataTableRow />
          </div>
        ) : tableData?.length < 1 ? (
          // Display error message when data could not be loaded
          <div className="error-message">No data to display yet</div>
        ) : (
          <div className="ag-theme-alpine-dark personas-table-container__table">
            <AgGridReact
              columnDefs={columnDefs}
              suppressMenuHide={true}
              rowData={tableData}
              pagination={true}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={false}
              onGridReady={onGridReady}
              domLayout="autoHeight"
              rowHeight={72}
              defaultColDef={defaultColDef}
            ></AgGridReact>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonasTable;
