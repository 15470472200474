// Fundamentals
import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import auth from '../../../../firebase/init';
import axios from 'axios';
import Cookies from 'js-cookie';

// Styles
import './members.scss';

//Images

const Members = ({ teamMemberDetails }: any) => {
  const columnDefs = [
    {
      headerName: 'First Name',
      field: 'first_name',
      resizable: true,
      filter: true,
    },
    {
      headerName: 'Last Name',
      field: 'last_name',
      filter: true,
      resizable: true,
    },
    {
      headerName: 'Email',
      field: 'user_email',
      filter: true,
      resizable: true,
    },
  ];
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      cellStyle: { overflow: 'hidden' },
      menuTabs: ['filterMenuTab'] as any,
      filterParams: {
        defaultToNothingSelected: true,
      },
    };
  }, []);

  const paginationPageSize = 11;

  const filteredTeamMemberDetails = teamMemberDetails?.filter(
    (member: any) => member.first_name !== null || member.last_name !== null
  );
  return (
    <div className="members-container">
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          columnDefs={columnDefs}
          suppressMenuHide={true}
          rowData={filteredTeamMemberDetails}
          pagination={true}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={false}
          domLayout="autoHeight"
          rowHeight={72}
          defaultColDef={defaultColDef}
        />
      </div>
    </div>
  );
};

export default Members;
