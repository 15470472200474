// Fundamentals
import React from 'react';

// Styles
import './searchResults.scss';

interface INames {
  First_Name: string;
  Last_Name: string;
  Email: string;
}

interface ICompanies {
  Company: string;
}

interface ISearchProps {
  visible: boolean;
  searchText: string;
  filteredNames: INames[];
  filteredCompanies: ICompanies[];
  loadingContacts: boolean;
  handleContactClick?: (firstName: string, lastName: string) => void;
  handleCompanyClick?: (companyName: string) => void;
  toggleModalVisibility?: () => void;
}

const SearchResults = ({
  visible,
  searchText,
  filteredNames,
  filteredCompanies,
  loadingContacts,
  handleContactClick,
  handleCompanyClick,
  toggleModalVisibility,
}: ISearchProps) => {
  return (
    <div
      className={[
        'search-results-container',
        visible ? '' : 'search-results-container--hide',
      ].join(' ')}
      onClick={toggleModalVisibility}
    >
      <div
        className="topbar-container__searchbar-container__search-results"
        onClick={(event) => event.stopPropagation()}
      >
        <p className="topbar-container__searchbar-container__search-results--header">
          Contacts
        </p>
        <hr />

        {filteredNames.slice(0, 5).map((item, index) => {
          return (
            <div
              key={index}
              className="topbar-container__searchbar-container__search-results__search-item-row"
              onClick={() =>
                handleContactClick(item.First_Name, item.Last_Name)
              }
            >
              <div className="search-results-container__contact-initials">
                {item.First_Name.slice(0, 1) + item.Last_Name.slice(0, 1)}
              </div>
              <p className="topbar-container__searchbar-container__search-results__search-item--name">
                {item.First_Name + ' ' + item.Last_Name}
              </p>
              <p className="topbar-container__searchbar-container__search-results__search-item--email">
                {'  ' + item.Email}
              </p>
            </div>
          );
        })}
        {searchText.length == 0 && !loadingContacts && (
          <p className="search-results-container__awaiting-search-parameters">
            Awaiting search parameters...
          </p>
        )}
        {searchText.length > 0 &&
          !loadingContacts &&
          filteredNames.length == 0 && (
            <p className="search-results-container__no-results-text">
              No results found...
            </p>
          )}
        {loadingContacts && filteredNames.length == 0 && (
          <p className="search-results-container__no-results-text">
            Loading...
          </p>
        )}
        <hr />
        <p className="topbar-container__searchbar-container__search-results--header">
          Companies
        </p>
        <hr />

        {filteredCompanies.slice(0, 5).map((item, index) => {
          return (
            <div
              key={index}
              className="topbar-container__searchbar-container__search-results__search-item-row search-results-container__company-item"
              onClick={() => handleCompanyClick(item.Company)}
            >
              <div className="search-results-container__company-initials">
                {item.Company.slice(0, 1)}
              </div>
              <p className="topbar-container__searchbar-container__search-results__search-item ">
                {item.Company}
              </p>
            </div>
          );
        })}
        {searchText.length == 0 && !loadingContacts && (
          <p className="search-results-container__companies-awaiting-search-parameters">
            Awaiting search parameters...
          </p>
        )}
        {searchText.length > 0 &&
          !loadingContacts &&
          filteredCompanies.length == 0 && (
            <p className="search-results-container__companies-no-results-text">
              No results found...
            </p>
          )}
        {loadingContacts && filteredCompanies.length == 0 && (
          <p className="search-results-container__companies-no-results-text">
            Loading...
          </p>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
