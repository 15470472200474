import { createSlice } from '@reduxjs/toolkit';

const contactDataSlice = createSlice({
  name: 'contactData',
  initialState: {
    repFilterValue: null,
    repSelectedIndex: null,
    stageFilterValue: null,
    stageSelectedIndex: null,
    sequenceFilterValue: null,
    sequenceSelectedIndex: null,
    clientContactStages: null,
    // When a user clicks 1 of the filter buttons we want to hide the previously
    // visible filter drop down if it was still visible. The state below triggers
    // the collapsing of the other filter drop down
    recentlyClickedFilterButton: null,
  },
  reducers: {
    setRepFilterValue(state, payload) {
      state.repFilterValue = payload;
    },
    setRepSelectedIndex(state, payload) {
      state.repSelectedIndex = payload;
    },
    setStageFilterValue(state, payload) {
      state.stageFilterValue = payload;
    },
    setStageSelectedIndex(state, payload) {
      state.stageSelectedIndex = payload;
    },
    setSequenceFilterValue(state, payload) {
      state.sequenceFilterValue = payload;
    },
    setSequenceSelectedIndex(state, payload) {
      state.sequenceSelectedIndex = payload;
    },
    setClientContactStages(state, payload) {
      state.clientContactStages = payload;
    },
    setRecentlyClickedFilterButton(state, payload) {
      state.recentlyClickedFilterButton = payload;
    },
  },
});

export const contactDataActions = contactDataSlice.actions;

export default contactDataSlice;
