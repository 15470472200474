import { createSlice } from '@reduxjs/toolkit';

const variationSearchTermsSlice = createSlice({
  name: 'variationSearchTerms',
  initialState: {
    organizationNumEmployeesRanges: null,
    personTitles: null,
  },
  reducers: {
    setOrganizationNumEmployeesRanges(state, payload) {
      state.organizationNumEmployeesRanges = payload;
    },
    setPersonTitles(state, payload) {
      state.personTitles = payload;
    },
  },
});

export const variationSearchTermsActions = variationSearchTermsSlice.actions;

export default variationSearchTermsSlice;
