import React, { useEffect, useState } from 'react';
import CopyVariants from '../copyVariants/copyVariants';
import SequenceToggle from '../sequenceToggle/sequenceToggle';
import Variation from '../variations/variations';
import { useNavigate } from 'react-router-dom';

interface ISequenceData {
  sequence_name: string;
  copy_variants: any;
  variations: any;
}

interface ISequences {
  sequence: ISequenceData;
  isSidebarOpen: boolean;
  openSidebar: () => void;
}

const SequenceBlockItem = ({
  sequence,
  isSidebarOpen,
  openSidebar,
}: ISequences) => {
  const navigate = useNavigate();

  const [selectedStep, setSelectedStep] = useState('1');
  const [steps, setSteps] = useState([]);
  const [sequenceVolume, setSequenceVolume] = useState(0);
  const [sequenceContacts, setSequenceContacts] = useState(0);

  useEffect(() => {
    // Get copy variants steps
    const tempSteps: string[] = [];
    Object.keys(sequence.copy_variants).forEach((key: string) => {
      tempSteps.push(key);
    });
    setSteps(tempSteps);
    calculateVolumesAndContacts();
  }, []);

  const calculateVolumesAndContacts = () => {
    let sequenceVolume = 0;
    let sequenceContacts = 0;
    if (sequence.variations.length > 0) {
      sequence.variations.map((variation: any, index: number) => {
        if (variation.active) {
          sequenceVolume += variation.volume;
          sequenceContacts += variation.total_contacts;
        }
      });
      setSequenceVolume(sequenceVolume);
      setSequenceContacts(sequenceContacts);
    }
  };

  return (
    <div
      className={`sequence-container ${isSidebarOpen ? 'sidebar-open' : ''}`}
    >
      <section>
        <div className="sequence-container__sequence-header">
          <div className="sequence-container__sequence-header__sequence-left">
            <h2>{sequence.sequence_name}</h2>
            <SequenceToggle />
          </div>
          <div className="sequence-container__sequence-header__sequence-right">
            <p className="sequence-container__sequence-header__sequence-right__sequence-contacts sequence-button">
              {sequenceVolume}% {' ' + sequenceContacts + ' '} contacts
            </p>
            <button
              className="sequence-button"
              onClick={() => navigate('/sequence-settings')}
            >
              Options
            </button>
          </div>
        </div>
        <div className="sequence-container__sequence-body">
          <div className="sequence-container__sequence-body__sequence-body-top">
            <p>Select senders</p>
            <div className="sequence-container__sequence-body__sequence-body-top__container input-container-dropdown">
              <input
                type="email"
                placeholder="example@email.com"
                className="sequence-container__sequence-body__sequence-body-top__container__email"
              />
            </div>
            <button className="sequence-button">View Copy</button>
          </div>

          <div className="sequence-container__sequence-body__sequence-steps">
            <div className="sequence-container__sequence-body__sequence-steps__sequence-days">
              <p>Steps</p>
              <div className="sequence-container__sequence-body__sequence-steps__sequence-days__days-selector">
                {steps.map((step: string, index: number) => {
                  return (
                    <div key={index}>
                      <div
                        className={`sequence-container__sequence-body__sequence-steps__sequence-days__days-selector__sequence-day cursor ${
                          step === selectedStep ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedStep(step);
                        }}
                      >
                        Step {step}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="sequence-container__sequence-body__sequence-steps__steps-copy-variants">
              <CopyVariants copies={sequence.copy_variants[selectedStep]} />
            </div>
          </div>
          <div className="sequence-container__sequence-body__sequence-notes">
            <p>Notes</p>
            <div className="sequence-container__sequence-body__sequence-notes__sequence-internal-notes">
              Internal Notes, if added, appear here
            </div>
          </div>
          <button className="sequence-container__sequence-body__sequence-delivery-settings cursor">
            Show delivery settings
          </button>
        </div>
        {sequence.variations.map((variation: any, index: number) => {
          return (
            <Variation
              key={index}
              isSidebarOpen={isSidebarOpen}
              openSidebar={openSidebar}
              variation={variation}
            />
          );
        })}
      </section>
    </div>
  );
};

export default SequenceBlockItem;
