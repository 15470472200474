import React, { useEffect, useState } from 'react';
import axios from 'axios';
import auth from '../../firebase/init';
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../utils/toast';
import './renameSequences.scss';
import RenameSequencesDropdown from '../../components/renameSequencesDropdown/renameSequencesDropdown';
import { SpinnerCircular } from 'spinners-react';
import Cookies from 'js-cookie';

interface ISequence {
  name: string;
  id: string;
  status: string;
}

interface ILabel {
  sequence: ISequence;
}

interface Client {
  uuid: string;
  name: string;
}

interface RenameSequenceProps {
  clientList: Client[];
  sequenceNameList: any;
}

interface CheckboxProps {
  sequence: ISequence;
}

const RenameSequences = ({
  clientList,
  sequenceNameList,
}: RenameSequenceProps) => {
  const [sequences, setSequences] = useState([]);
  const [updatedSequenceNames, setUpdatedSequenceNames] = useState([]);
  const [sequencesToUpdateIndexes, setSequencesToUpdateIndexes] = useState([]);
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const sm = process.env.REACT_APP_SM;
  const [listOfClients, setListOfClients] = useState<string[]>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>('');
  const [sequencesToUpdateIDs, setSequencesToUpdateIDs] = useState<string>('');
  const [selectedClientUuid, setSelectedClientUuid] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const allClients: string[] = [];
    clientList.map((client: any, index: number) => {
      allClients.push(client.name);
    });
    setListOfClients(allClients);
    setSequences(sequenceNameList);
  }, [sequenceNameList]);

  const handleOptionSelect = (option: string) => {
    const selected = clientList.find((client) => client.name === option);
    setSelectedClientUuid(selected.uuid);
    setSelectedClient(option);
  };

  useEffect(() => {
    const temporaryList: any = [];
    sequencesToUpdateIndexes.map((index) => {
      temporaryList.push({
        id: sequences[index].id,
        name:
          selectedClient.trim().toLowerCase().replace(/\s+/g, '_') +
          ' - ' +
          sequences[index].name,
      });
    });
    setUpdatedSequenceNames(temporaryList);
  }, [sequencesToUpdateIDs, selectedClient, sequences]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredSequences = sequences?.filter((sequence) =>
    sequence.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectAll = () => {
    if (selectedClient) {
      const updatedIDs = filteredSequences
        .map((sequence) => sequence.id)
        .join(',');
      const updatedNames = filteredSequences.map((sequence) => ({
        id: sequence.id,
        name: `${selectedClient?.trim().toLowerCase().replace(/\s+/g, '_')} - ${
          sequence.name
        }`,
      }));
      setSequencesToUpdateIDs(updatedIDs);
      setUpdatedSequenceNames(updatedNames);
    } else {
      showErrorToast('Please select a client first.');
    }
  };

  const handleClearAll = () => {
    setSequencesToUpdateIDs('');
    setUpdatedSequenceNames([]);
  };

  const Checkbox = ({ sequence }: CheckboxProps) => {
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
      setSelectedIndex(sequencesToUpdateIDs.includes(sequence.id));
    }, [sequencesToUpdateIDs, sequence.id]);

    const handleSelection = () => {
      if (selectedClient) {
        const sequenceIDString = String(sequence.id);
        if (selectedIndex) {
          const updatedIDs = sequencesToUpdateIDs
            .split(',')
            .filter((id) => id !== sequenceIDString)
            .join(',');
          const updatedNames = updatedSequenceNames.filter(
            (name) => name.id !== sequenceIDString
          );
          setSequencesToUpdateIDs(updatedIDs);
          setUpdatedSequenceNames(updatedNames);
        } else {
          const updatedIDs = sequencesToUpdateIDs
            ? `${sequencesToUpdateIDs},${sequenceIDString}`
            : sequenceIDString;
          setSequencesToUpdateIDs(updatedIDs);
          setUpdatedSequenceNames([
            ...updatedSequenceNames,
            {
              id: sequenceIDString,
            },
          ]);
        }
      } else {
        showErrorToast('Please select a client first.');
      }
    };

    return (
      <div className="checkbox-wrapper" onClick={handleSelection}>
        <label>
          <input
            type="checkbox"
            className={selectedIndex ? 'checked' : ''}
            disabled={!selectedClient}
            checked={selectedIndex}
            readOnly
          />
          <p>
            {sequence.name}
            <span> - {sequence.status.toLowerCase()}</span>
          </p>

          {/* {selectedIndex && (
            <div className="checkbox-wrapper__draft">
              <p>
                <span></span>
                {`${selectedClient
                  ?.trim()
                  .toLowerCase()
                  .replace(/\s+/g, '_')} - ${sequence.name}`}
              </p>
            </div>
          )} */}
        </label>
      </div>
    );
  };

  const databaseUpdate = () => {
    const data = {
      child_id: selectedClientUuid,
      sm_client: Cookies.get('parent'),
      update_mode: 'both',
      key: sm,
    };

    axios({
      method: 'POST',
      url: 'https://smartleadupdater.whall.co.za/smartlead_update',
      data: data,
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const updateSequenceNames = () => {
    const data = {
      uuid: selectedClientUuid,
      campaign_ids: sequencesToUpdateIDs,
    };
    setLoading(true);

    auth.onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken(true)
          .then((idToken) => {
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/campaign-linking',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then(() => {
                databaseUpdate();
                setSelectedClient(null);
                setSequencesToUpdateIDs('');
                setUpdatedSequenceNames([]);
                setSelectedClientUuid(null);
                setLoading(false);
                showSuccessToast(
                  'Campaigns successfully linked. We are busy updating your dashboards please allow 10 minutes or more depending on how many leads are in your campaign.'
                );
              })
              .catch(() => {
                setLoading(false);
                showErrorToast(
                  'Failed to link campaign to client, please try again later.'
                );
              });
          })
          .catch((error) => {
            // Handle error
          });
      }
    });
  };

  return (
    <div className="rename-sequences-container">
      <ToastContainer />
      <div className="rename-sequences-container__step1">
        <p>Select Client:</p>
        <div className="rename-sequences-container__step1__dropdown">
          <RenameSequencesDropdown
            options={clientList}
            value={selectedClient ? selectedClient : null}
            onOptionSelect={handleOptionSelect}
          />
        </div>
      </div>
      <div className="rename-sequences-container__step1__info">
        <h3>Select Client Campaigns</h3>
        <p>i</p>
      </div>

      <input
        type="text"
        placeholder="Search sequences..."
        value={searchTerm}
        onChange={handleSearch}
        className="rename-sequences-container__search-bar"
      />
      {loading ? (
        <div>
          <SpinnerCircular
            size={40}
            thickness={200}
            speed={100}
            color="var(--sliderEnd)"
            secondaryColor="var(--sliderStart)"
            className="sign-in-spinner"
          />
        </div>
      ) : null}
      <div className="rename-sequences-container__step2">
        {filteredSequences
          ?.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
          .map((sequence, index) => (
            <div
              className="rename-sequences-container__step2__rename-element"
              key={index}
            >
              <Checkbox sequence={sequence} />
            </div>
          ))}
      </div>
      <button
        className="rename-sequences-container__update-button sequence-button"
        onClick={updateSequenceNames}
        disabled={loading}
      >
        Update Campaigns
      </button>
      <button
        className=" rename-sequences-container__update-button sequence-button"
        onClick={handleSelectAll}
      >
        Select All
      </button>

      <button className="sequence-button" onClick={handleClearAll}>
        Clear All
      </button>
    </div>
  );
};

export default RenameSequences;
