import { createSlice } from '@reduxjs/toolkit';

const clientTypeSlice = createSlice({
  name: 'clienttype',
  initialState: {
    clientType: null,
  },
  reducers: {
    setClientType(state, payload) {
      state.clientType = payload;
    },
  },
});

export const clientTypeActions = clientTypeSlice.actions;

export default clientTypeSlice;
