import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import auth from '../../../../firebase/init';

import './conversionRatesGraph.scss';
import axios from 'axios';
import Cookies from 'js-cookie';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { copiesActions } from '../../../../redux/analytics/copiesSlice';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: DataPoint }>;
}

interface DataPoint {
  name: string;
  [key: string]: string | number;
}

interface ConversionRatesProps {
  sequenceFilterValue: string;
}

interface CustomLegendProps {
  payload?: Array<{ payload: DataPoint }>;
}

interface ISelector {
  type: string;
  payload: Record<string, unknown>;
}

interface ICopiesSlice {
  copyRates: ISelector;
}

interface IStore {
  copies: ICopiesSlice;
}

const ConversionRatesGraph = ({
  sequenceFilterValue,
}: ConversionRatesProps) => {
  const dispatch = useDispatch();
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const [secondColor, setSecondColor] = useState('');
  const [thirdColor, setThirdColor] = useState('');

  const [copies, setCopies] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedSequence, setSelectedSequence] =
    useState<string>(sequenceFilterValue);

  const gradientId = 'possibleInterestGradient';

  const copyRatesRedux = useSelector((state: IStore) => state.copies.copyRates);

  useEffect(() => {
    if (Cookies.get('clientType') === 'SaaS') {
      setSecondColor('#5A6600');
      setThirdColor('rgba(90, 102, 0, 0.40)'); // Example of setting third color
    } else {
      setSecondColor('#00305C');
      setThirdColor('rgba(0, 48, 92, 0.40)'); // Example of setting third color
    }
  }, []);

  useEffect(() => {
    if (copyRatesRedux?.payload) {
      setCopies(copyRatesRedux.payload);
      // getSequences(copyRatesRedux.payload);
    } else {
      setLoading(true);

      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url:
                apiEndpoint +
                'api/smartlead-copy-rates/' +
                Cookies.get('fuelStationId'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                setCopies(response.data.data);
                dispatch(copiesActions.setCopyRates(response.data.data));
                setLoading(false);
                // getSequences(response.data.data);
              })
              .catch((error) => {
                setLoading(false);
                setError(true);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  }, []);

  useEffect(() => {
    setSelectedSequence(sequenceFilterValue);
  }, [sequenceFilterValue]);

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { name, ...sequences } = payload[0].payload;
      return (
        <div className="conversion-rates-tooltip ">
          {Object.entries(sequences).map(([key, value]) => (
            <p key={key}>
              {key === 'sequence'
                ? `Campaign: ${value}`
                : typeof value === 'number'
                ? ` ${key.replace(/_/g, ' ')}: ${value}%`
                : `${key.replace(/_/g, ' ')} : ${value}`}
              {}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const colors = ['var(--greyBorderToBlueBorder)', secondColor];

  const renderLegend = ({ payload }: CustomLegendProps) => {
    const data = payload;
    return (
      <div className="conversion-rates-graph-legend">
        {data?.map((entry: any, index: number) => (
          <div
            key={`legend-${index}`}
            className="conversion-rates-graph-legend__container"
          >
            <div
              className="conversion-rates-graph-legend__container__value-container"
              style={{ backgroundColor: colors[index % colors.length] }}
            ></div>
            <div>
              {entry?.dataKey === 'not_interested_rate' ? (
                <span>Not Interested</span>
              ) : (
                <span>Interested</span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const formatLegendText = (value: string) => (
    <span style={{ color: 'var(--blackTextToWhiteText)' }}>{value}</span>
  );

  return (
    <div className="conversion-rates-graph-container">
      <div className="conversion-rates-dropdown">
        <h2>Interest Rates</h2>
      </div>
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-conversion-rates">
          <div className="loading-container-conversion-rates__flex">
            <div className="loading-block loading-text-small"></div>
            <div className="loading-block loading-text-small"></div>
          </div>
          <div className="loading-block loading-container-conversion-rates__graph"></div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later
        </div>
      ) : copies?.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <div className="conversion-rates-graph-container__graph">
          <ResponsiveContainer width="98%" height={350}>
            <BarChart
              height={300}
              data={copies[selectedSequence]}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barCategoryGap={10}
            >
              <defs>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={secondColor} />
                  <stop offset="100%" stopColor={thirdColor} />
                </linearGradient>
              </defs>
              <CartesianGrid
                vertical={false}
                horizontal={false}
                stroke={secondColor}
              />
              <XAxis
                dataKey="variant_name"
                textAnchor="middle"
                tickMargin={20}
                height={45}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return ` ${tick}%`;
                }}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: 'none' }}
                wrapperStyle={{ zIndex: 1000 }}
                position={{ y: 60 }}
              />
              <Legend
                verticalAlign="top"
                height={50}
                formatter={formatLegendText}
                content={(props: any) => renderLegend(props)}
              />
              <Bar
                dataKey="interested_rate"
                stackId="a"
                fill="var(--greyBorderToBlueBorder)"
                radius={[8, 8, 0, 0]}
              >
                {copies[selectedSequence]?.map((entry: any, index: number) =>
                  entry.not_interested_rate === 0 ? (
                    <Cell
                      key={`cell-${index}`}
                      fill={'var(--greyBorderToBlueBorder)'}
                    />
                  ) : (
                    <Cell
                      key={`cell-${index}`}
                      fill={'var(--greyBorderToBlueBorder)'}
                      radius={0}
                    />
                  )
                )}
              </Bar>
              <Bar
                dataKey="not_interested_rate"
                stackId="a"
                fill={`url(#${gradientId})`}
                name="Not Interested Rate"
                radius={[8, 8, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default ConversionRatesGraph;
