import React, { useState } from 'react';
import './copyVariants.scss';

interface CopyVariantsItem {
  variant_name: string;
  copy_text: string;
  subject_line: string;
}

interface CopyVariantsProps {
  copies: CopyVariantsItem[];
}

const CopyVariants = ({ copies }: CopyVariantsProps) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  const handleClick = (index: number) => {
    const isActive = activeIndexes.includes(index);
    let newActiveIndexes: number[] = [];

    if (isActive) {
      newActiveIndexes = activeIndexes.filter(
        (activeIndex) => activeIndex !== index
      );
    } else {
      newActiveIndexes = [...activeIndexes, index];
    }

    setActiveIndexes(newActiveIndexes);
  };

  const renderItems = () => {
    return copies.map((item, index) => {
      const isActive = activeIndexes.includes(index);
      const contentClass = isActive
        ? 'copy-variants-item__content active'
        : 'copy-variants-item__content';
      const symbol = isActive ? '-' : '+';
      const formattedCopyText = item.copy_text
        .split('\n')
        .map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
            <br />
          </React.Fragment>
        ));

      return (
        <div key={index} className="copy-variants-item">
          <div
            className="copy-variants-item__copy-variants-header"
            onClick={() => handleClick(index)}
          >
            <div>
              <span className="copy-variants-item__copy-variants-header__first-character">
                {item.variant_name}:
                {/* Slicing to get only the first 2 characters to apply color styling */}
              </span>{' '}
              {item.subject_line}
            </div>
            <span className="copy-variants-item__copy-variants-header__copy-variants--symbol">
              {symbol}
            </span>
          </div>
          {isActive && <div className={contentClass}>{formattedCopyText}</div>}
        </div>
      );
    });
  };

  return <div className="copy-variants">{renderItems()}</div>;
};

export default CopyVariants;
