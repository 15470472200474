// Fundamentals
import React, { useState } from 'react';

// Styles
import './sequences.scss';

//Components
import SequenceBlock from '../../components/sequencesPage/sequenceBlock/sequenceBlock';
import KeyWordsSideBar from '../../components/sequencesPage/keyWordsSideBar/keyWordsSideBar';

const SequencesPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  return (
    <div className="sequences-page-container">
      <p className="sequences-page-container__page-header">
        Sequences and Variations
      </p>
      <SequenceBlock isSidebarOpen={isSidebarOpen} openSidebar={openSidebar} />
      {isSidebarOpen && <KeyWordsSideBar onCloseSidebar={closeSidebar} />}
    </div>
  );
};

export default SequencesPage;
