import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { copyDraftActions } from '../../../../redux/copy-draft-slice';

// Styles
import './viewCopy.scss';

interface ICopy {
  sequence_name: string;
  link: string;
}

interface ICopies {
  copies: ICopy[];
  copyModalVisible: boolean;
  setCopyModalVisible?: (selectedOption: boolean) => void;
}

const ViewCopy = ({
  copies,
  setCopyModalVisible,
  copyModalVisible,
}: ICopies) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const showCopyModal = (index: number) => {
    dispatch(copyDraftActions.updateCopyIndex(index));
    setCopyModalVisible(!copyModalVisible);
  };

  return (
    <div className={`dropdown-view ${isOpen ? 'open' : ''}`}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        <div className="dropdown-header-selected">View Copy</div>

        <div className={`arrow ${isOpen ? 'up' : ''}`}></div>
      </div>
      {isOpen && (
        <div className="dropdown-options view-copy">
          {copies.length < 1 ? (
            <p className="dropdown-options__no-copy">No data to display yet</p>
          ) : (
            copies.map((option, index) => (
              <div
                key={index}
                className="dropdown-option"
                onClick={() => showCopyModal(index)}
              >
                {option.sequence_name}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ViewCopy;
