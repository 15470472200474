import React, { ReactChild, ReactChildren } from 'react';

interface ITabWrapper {
  id: string;
  activeTab: string;
  children: ReactChild | ReactChildren;
}

const TabWrapper = ({ id, activeTab, children }: ITabWrapper) => {
  return activeTab === id ? <div className="tabWrapper">{children}</div> : null;
};

export default TabWrapper;
