// Fundamentals
import React, { useEffect, useRef, useState } from 'react';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Bar,
  Legend,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';

// Styles
import './leadsGraph.scss';
import Cookies from 'js-cookie';

//Components

//Images

//Interfaces
interface ILeads {
  Sequence: string;
  Possible_Conversation_Rate: number;
  Not_Interested_Rate: number;
}

interface IData {
  data: ILeads[];
  loading: boolean;
  error: boolean;
  leadsOnlyToggle: boolean;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: ILeads }>;
}

interface CustomLegendProps {
  payload?: Array<{ payload: ILeads }>;
}

const LeadsGraph = ({ data, loading, error, leadsOnlyToggle }: IData) => {
  const [hoveredXAxisLabel, setHoveredXAxisLabel] = useState(null);
  const [secondColor, setSecondColor] = useState('');
  const [thirdColor, setThirdColor] = useState('');
  const formatLegendText = (value: string) => (
    <span style={{ color: 'var(--blackTextToWhiteText)' }}>{value}</span>
  );

  const gradientId = 'possibleInterestGradient';
  const gradientIdRed = 'considerTurningOff';
  const gradientIdLight = 'leads';

  useEffect(() => {
    if (Cookies.get('clientType') === 'SaaS') {
      setSecondColor('#5A6600');
      setThirdColor('rgba(90, 102, 0, 0.40)'); // Example of setting third color
    } else {
      setSecondColor('#00305C');
      setThirdColor('rgba(0, 48, 92, 0.40)'); // Example of setting third color
    }
  }, []);

  const colors = ['var(--greyBorderToBlueBorder)', secondColor];
  const [showTurnOff, setShowTurnOff] = useState(false);

  const itemsPerPage = 30;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * itemsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    if (data && data.length > 0) {
      let stopLoop = false;
      data.forEach((item: any) => {
        if (
          !stopLoop &&
          item.Total_contacted > 1000 &&
          item.possible_conversation_rate < 0.25
        ) {
          setShowTurnOff(true);
          stopLoop = true;
        }
      });
    }
  }, [data]);

  const CustomTooltip = ({ active, payload }: CustomTooltipProps) => {
    if (active && payload && payload.length) {
      const { Sequence, ...sequences } = payload[0].payload;
      return (
        <div className="leads-tooltip ">
          <p>
            Campaign: <b>{Sequence}</b>
          </p>
          {leadsOnlyToggle
            ? Object.entries(sequences).map(([key, value]) => (
                <p key={key}>
                  {key === 'leads' || key === 'Total_contacted'
                    ? `${key.replace(/_/g, ' ')}: ${value.toFixed(0)}`
                    : key === 'sequence_status'
                    ? `Campaign Status: ${value}`
                    : null}
                </p>
              ))
            : Object.entries(sequences).map(([key, value]) => (
                <p key={key}>
                  {key === 'leads' ||
                  key === 'Total_contacted' ||
                  key === 'replied'
                    ? `${key.replace(/_/g, ' ')}: ${value.toFixed(0)}`
                    : key === 'sequence_status'
                    ? `Campaign Status: ${value}`
                    : `${key.replace(/_/g, ' ')}: ${value.toFixed(2)}%`}
                </p>
              ))}
        </div>
      );
    }

    return null;
  };

  const renderLegend = ({ payload }: CustomLegendProps) => {
    const data = payload;
    return (
      <div className="leads-graph-legend">
        {leadsOnlyToggle
          ? null
          : data.map((entry: any, index: number) => (
              <div
                key={`legend-${index}`}
                className="leads-graph-legend__container"
              >
                <div
                  className="leads-graph-legend__container__value-container"
                  style={{ backgroundColor: colors[index % colors.length] }}
                ></div>
                <div>
                  <span className="leads-graph-legend__container__name">
                    {entry.payload.name}
                  </span>
                </div>
              </div>
            ))}
        {leadsOnlyToggle ? null : (
          <>
            {showTurnOff ? (
              <div className="red-legend-oval">
                <div
                  className="leads-graph-legend__container__value-container"
                  style={{ backgroundColor: 'rgba(255,69,0 ,0.6)' }}
                ></div>
                <span>Consider Turning Off</span>
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  };

  const abbreviateSequence = (sequence: string) => {
    // Remove special characters from the sequence
    const cleanedSequence = sequence.replace(/[^a-zA-Z0-9 ]/g, '');

    // Split the cleaned sequence string by spaces and parentheses
    const words = cleanedSequence.split(/[ ()]+/);

    // If there's only one word, return the first 3 letters of that word
    if (words.length === 1) {
      if (data.length > 12) {
        return cleanedSequence.slice(0, 3) + '...';
      }
      return cleanedSequence;
    }

    // If there are multiple words, abbreviate as before
    const concatenatedAbbreviation = words
      .map((word) => word.charAt(0))
      .join('');

    if (data.length > 12) {
      return concatenatedAbbreviation.slice(0, 3) + '...';
    }

    return concatenatedAbbreviation;
  };

  const handleMouseOver = (event: any) => {
    setHoveredXAxisLabel(event.value);
  };

  const handleMouseOut = () => {
    setHoveredXAxisLabel(null);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < data.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-leads">
          <div className="loading-container-leads__header">
            <div className="loading-block"></div>
            <div className="loading-block"></div>
          </div>
          <div className="loading-block loading-container-leads__axis-label"></div>
          <div className="loading-block loading-container-leads__graph"></div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later
        </div>
      ) : data?.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <div className="leads-graph-container">
          <ResponsiveContainer height={data.length > 12 ? 380 : 320}>
            <BarChart
              data={data.length > 30 ? paginatedData : data}
              margin={{
                top: 10,
                right: 15,
                left: 10,
                bottom: 10,
              }}
            >
              <defs>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={secondColor} />
                  <stop offset="100%" stopColor={thirdColor} />
                </linearGradient>
                <linearGradient id={gradientIdRed} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="rgba(255,69,0, 0.3)" />
                  <stop offset="100%" stopColor="rgba(255,69,0, 0.5)" />
                </linearGradient>
                <linearGradient
                  id={gradientIdLight}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={secondColor} />
                  <stop
                    offset="100%"
                    stopColor="var(--greyBorderToBlueBorder)"
                  />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} stroke={secondColor} />
              <XAxis
                dataKey="Sequence"
                stroke="var(--blackTextToWhiteText)"
                axisLine={false}
                tickLine={false}
                angle={-25}
                height={data.length > 12 ? 100 : 80}
                tickMargin={50}
                style={{
                  fontSize: '10px',
                  zIndex: '100',
                }}
                interval={0}
                tickFormatter={(value) =>
                  hoveredXAxisLabel === value
                    ? value
                    : abbreviateSequence(value)
                }
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: 'none' }}
                wrapperStyle={{ zIndex: 1000 }}
              />
              <YAxis
                stroke="var(--blackTextToWhiteText)"
                axisLine={false}
                tickLine={false}
                tickFormatter={
                  leadsOnlyToggle
                    ? null
                    : (tick) => {
                        return ` ${tick}%`;
                      }
                }
                label={{
                  value: leadsOnlyToggle ? 'Leads' : 'Reply Rate',
                  angle: -90,
                  fill: 'var(--blackTextToWhiteText)',
                }}
                width={140}
              />
              <Legend
                verticalAlign="top"
                height={50}
                formatter={formatLegendText}
                content={(props: any) => renderLegend(props)}
              />
              <Bar
                name={leadsOnlyToggle ? 'Leads' : 'Interested'}
                dataKey={
                  leadsOnlyToggle ? 'leads' : 'possible_conversation_rate'
                }
                stackId="a"
                fill="var(--greyBorderToBlueBorder)"
                radius={[8, 8, 0, 0]}
              >
                {leadsOnlyToggle
                  ? data?.map((entry: any, index: number) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={`url(#${gradientIdLight})`}
                      />
                    ))
                  : data?.map((entry: any, index: number) =>
                      entry.possible_conversation_rate > 0.25 ? (
                        //If interested greater than 0.25 sets it to the blue
                        entry.not_interested_rate === 0 ? (
                          //If interested is 0 it leaves the border radius as is
                          <Cell
                            key={`cell-${index}`}
                            fill={'var(--greyBorderToBlueBorder)'}
                          />
                        ) : (
                          //If interested rate more than 0 updates the border radius
                          <Cell
                            key={`cell-${index}`}
                            fill={'var(--greyBorderToBlueBorder)'}
                            radius={0}
                          />
                        )
                      ) : entry.Total_contacted < 1000 ? (
                        //If less than 1000 contacted sets to the blue
                        <Cell
                          key={`cell-${index}`}
                          fill={'var(--greyBorderToBlueBorder)'}
                          radius={0}
                        />
                      ) : (
                        //If more that 1000 contacted sets it to the red color
                        <Cell key={`cell-${index}`} fill={`red`} radius={0} />
                      )
                    )}
              </Bar>
              {leadsOnlyToggle ? null : (
                <Bar
                  dataKey="not_interested_rate"
                  stackId="a"
                  fill={`url(#${gradientId})`}
                  radius={[8, 8, 0, 0]}
                  name="Not Interested"
                >
                  {data?.map((entry: any, index: number) =>
                    entry.possible_conversation_rate > 0.25 ? (
                      //If interested greater than 0.25 sets it to the blue
                      <Cell
                        key={`cell-${index}`}
                        fill={`url(#${gradientId})`}
                      />
                    ) : entry.Total_contacted < 1000 ? (
                      //If less than 1000 contacted sets to the blue
                      <Cell
                        key={`cell-${index}`}
                        fill={`url(#${gradientId})`}
                      />
                    ) : (
                      //If more that 1000 contacted sets it to the red color
                      <Cell
                        key={`cell-${index}`}
                        fill={`url(#${gradientIdRed})`}
                      />
                    )
                  )}
                </Bar>
              )}
            </BarChart>
          </ResponsiveContainer>
          {data.length > 30 ? (
            <div className="leads-graph-container__pages">
              <button
                className="leads-graph-container__pages__prev"
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
              >
                <span className="arrow-prev"></span>
              </button>
              <span className="leads-graph-container__pages__number">
                Page {currentPage + 1} of {totalPages}
              </span>
              <button
                className="leads-graph-container__pages__next"
                onClick={handleNextPage}
                disabled={(currentPage + 1) * itemsPerPage >= data.length}
              >
                <span className="arrow-next"></span>
              </button>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default LeadsGraph;
