import React, { useEffect, useState } from 'react';

// Styles
import './renameSequencesDropdown.scss';

interface ClientDropdown {
  name: string;
  uuid: string;
}

interface DropdownProps {
  options: ClientDropdown[];
  value: string | null;
  onOptionSelect: (option: string) => void;
}

const RenameSequencesDropdown = ({
  options,
  value,
  onOptionSelect,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onOptionSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={`billing-dropdown ${isOpen ? 'open' : ''}`}>
      <div className="billing-dropdown-header" onClick={toggleDropdown}>
        <div className="billing-dropdown-header-selected">
          {selectedOption || 'Select a Client'}
        </div>

        <div className={`arrow ${isOpen ? 'up' : ''}`}></div>
      </div>
      {isOpen && (
        <div className="billing-dropdown-options">
          {options.map((option, index) => (
            <div
              key={index}
              className="billing-dropdown-option"
              onClick={() => handleOptionClick(option.name)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RenameSequencesDropdown;
