// Fundamentals
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import auth from '../../../firebase/init';

// Styles
import './copyDashboard.scss';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../redux/auth-slice';

//Components
import ConversionRatesGraph from '../../../components/dashboards/copyDashboard/conversionRatesGraph/conversionRatesGraph';
import BestWorstDropdown from '../../../components/dashboards/copyDashboard/bestWorstDropdown/bestWorstDropDown';
import ViewCopy from '../../../components/dashboards/analytics/viewCopy/viewCopy';
import CopyModal from '../../../components/copyModal/copyModal';

//Images
import spaceman from '../../../assets/dashboard/copy/copy-dashboard-spaceman.png';
import meetingSaas from '../../../assets/dashboard/copy/meeting-saas.svg';
import CopyDashboardTable from '../../../components/dashboards/copyDashboard/copyDashboardTable/copyDashboardTable';
import { copiesActions } from '../../../redux/analytics/copiesSlice';
import { clientActions } from '../../../redux/client-slice';
import ScheduleCallModal from '../../../components/scheduleCallModal/scheduleCallModal';
import docs from '../../../assets/clientPage/docs.png';

interface ISelector {
  type: string;
  payload: Record<string, unknown>;
}

interface IClientSelector {
  type: string;
  payload: string;
}

interface ICopiesSlice {
  bestWorstCopy: ISelector;
}

interface IClientSlice {
  calendlyLink: IClientSelector;
}

interface IClientTypeSlice {
  clientType: IClientSelector;
}

interface IStore {
  copies: ICopiesSlice;
  client: IClientSlice;
  clienttype: IClientTypeSlice;
}

const CopyDashboard = () => {
  const dispatch = useDispatch();
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [copyData, setCopyData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sequences, setSequences] = useState([]);
  const [selectedSequence, setSelectedSequence] = useState<string>('');
  const [renderedData, setRenderedData] = useState<any[]>([]);
  const [scheduleCallModalVisible, setScheduleCallModalVisible] =
    useState(false);
  const [copies, setCopies] = useState([]);
  const [copyModalVisible, setCopyModalVisible] = useState(false);

  const bestWorstCopyRedux = useSelector(
    (state: IStore) => state.copies.bestWorstCopy
  );

  const calendlyLink = useSelector(
    (state: IStore) => state.client.calendlyLink
  );

  useEffect(() => {
    if (!calendlyLink?.payload) {
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url: apiEndpoint + 'api/client/' + Cookies.get('displayName'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                if (response.data.data.copies.length > 0) {
                  dispatch(
                    clientActions.setCalendlyLink(
                      response.data.data.copies[0].client__calendly_link
                    )
                  );
                }
              })
              .catch(() => {
                // Handle error
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }

    if (bestWorstCopyRedux?.payload) {
      setCopyData(bestWorstCopyRedux.payload);
      processData(bestWorstCopyRedux.payload);
    } else {
      setLoading(true);

      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url:
                apiEndpoint +
                'api/smartlead-copy-performance/' +
                Cookies.get('fuelStationId'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                setCopyData(response.data.data);
                setLoading(false);
                processData(response.data.data);
                dispatch(copiesActions.setBestWorstCopy(response.data.data));
              })
              .catch(() => {
                setLoading(false);
                setError(true);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
    getCopies();
  }, []);

  const getCopies = () => {
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url: apiEndpoint + 'api/client/' + Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setCopies(response.data.data.copies);
              if (response.data.data.copies.length > 0) {
                dispatch(
                  authActions.setStripeId(
                    response.data.data.copies[0].client__stripe_id
                  )
                );
                dispatch(
                  clientActions.setCalendlyLink(
                    response.data.data.copies[0].client__calendly_link
                  )
                );
              }
            })
            .catch(() => {
              setError(false);
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  const processData = (responseData: any) => {
    // Get sequence names for filter dropdown
    const tempSequences: string[] = [];
    const seenSequences = new Set();
    setSelectedSequence(sequences[0]);

    responseData.forEach((variant: any) => {
      const sequence = variant.sequence;
      if (!seenSequences.has(sequence)) {
        seenSequences.add(sequence);
        tempSequences.push(sequence);
      }
    });
    // Set unique sequence names and default selection
    setSequences(tempSequences);
  };

  const handleSequenceSelection = (sequence: string) => {
    setSelectedSequence(sequence);
  };

  useEffect(() => {
    if (copyData && copyData.length > 0) {
      // Find the first variant that matches sequences[0] and set it as the initial selectedSequence
      const initialSequence = copyData.find(
        (variant: any) => variant.sequence === sequences[0]
      );
      if (initialSequence) {
        setSelectedSequence(initialSequence.sequence);
      }
    }
  }, [copyData, sequences]);

  useEffect(() => {
    // When selectedSequence changes, update the rendered data
    if (selectedSequence) {
      const selectedVariants = copyData.filter(
        (variant: any) => variant.sequence === selectedSequence
      );

      // Sorting the data to have the best display first
      selectedVariants.sort((a: any, b: any) => {
        if (a.best_worst === 'best' && b.best_worst === 'worst') {
          return -1;
        } else if (a.best_worst === 'worst' && b.best_worst === 'best') {
          return 1;
        } else {
          return 0;
        }
      });

      setRenderedData(selectedVariants);
    } else {
      // If selectedSequence is empty, revert to the initial overAll data
      // setRenderedData(overAll);
    }
  }, [selectedSequence, copyData]);
  return (
    <div className="copy-dashboard-container">
      {copyModalVisible && (
        <CopyModal
          toggleModal={setCopyModalVisible}
          isOpen={copyModalVisible}
          copies={copies}
        />
      )}
      {calendlyLink.payload && scheduleCallModalVisible && (
        <ScheduleCallModal
          toggleModal={setScheduleCallModalVisible}
          isVisible={scheduleCallModalVisible}
        />
      )}
      <div className="copy-dashboard-container__top">
        <div className="best-worst-dropdown__dropdown">
          {sequences.length > 0 || loading ? (
            <BestWorstDropdown
              options={sequences}
              originalOption={selectedSequence}
              clickHandler={(sequence) => setSelectedSequence(sequence)}
            />
          ) : (
            // Render a placeholder for the dropdown
            <div>Loading...</div>
          )}
        </div>
        {Cookies.get('clientType') === 'SaaS' ? null : (
          <div className="dashboard-container__navbar__section-container">
            <div className="dashboard-container__navbar__section-container__section-item">
              <img src={docs} alt="" />
              <ViewCopy
                copies={copies}
                copyModalVisible={copyModalVisible}
                setCopyModalVisible={setCopyModalVisible}
              />
            </div>
          </div>
        )}
      </div>
      <div className="copy-dashboard-container__panels">
        <>
          <div className="copy-dashboard-container__panels__panel">
            {loading ? (
              // Show the spinner while loading is true
              <div className="loading-container-panel">
                <div className="loading-block loading-container-panel__interested"></div>
                <div className="loading-block loading-text-large"></div>
                <div className="loading-block loading-text-small"></div>
                <div className="loading-block loading-text-large"></div>
              </div>
            ) : error ? (
              // Display error message when data could not be loaded
              <div className="error-message">
                Data could not be loaded <br />
                Please try again later
              </div>
            ) : renderedData?.length < 1 ? (
              // Display error message when data could not be loaded
              <div className="error-message">No data to display yet</div>
            ) : (
              <>
                {renderedData.some((value) => value.best_worst === 'best') ? (
                  renderedData.map((value, index) => (
                    <div key={index}>
                      {value.best_worst === 'best' ? (
                        <div className="copy-dashboard-container__panels__panel__panel-data">
                          <h3>Best Performing Variant:</h3>
                          <h4>Step {value.variant_name}</h4>
                          <h3>Campaign: {value.sequence}</h3>
                          <div className="copy-dashboard-container__panels__panel__interested">
                            <p>{value.interested_rate}% interested</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="error-message">No data to display yet</div>
                )}
              </>
            )}
          </div>
        </>
        <>
          <div className="copy-dashboard-container__panels__panel">
            {loading ? (
              // Show the spinner while loading is true
              <div className="loading-container-panel">
                <div className="loading-block loading-container-panel__interested"></div>
                <div className="loading-block loading-text-large"></div>
                <div className="loading-block loading-text-small"></div>
                <div className="loading-block loading-text-large"></div>
              </div>
            ) : error ? (
              // Display error message when data could not be loaded
              <div className="error-message">
                Data could not be loaded <br />
                Please try again later
              </div>
            ) : renderedData?.length < 1 ? (
              // Display error message when data could not be loaded
              <div className="error-message">No data to display yet</div>
            ) : (
              <>
                {renderedData.some((value) => value.best_worst === 'worst') ? (
                  renderedData.map((value, index) => (
                    <div key={index}>
                      {value.best_worst === 'worst' ? (
                        <div className="copy-dashboard-container__panels__panel__panel-data">
                          <h3>Consider Turning Off:</h3>
                          <h4>Step {value.variant_name}</h4>
                          <h3>Campaign: {value.sequence}</h3>
                          <div className="copy-dashboard-container__panels__panel__interested">
                            <p>{value.interested_rate}% interested</p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div className="error-message">No data to display yet</div>
                )}
              </>
            )}
          </div>
        </>
        <div className="copy-dashboard-container__panels__panel">
          <div className="copy-dashboard-container__panels__panel__spaceman">
            {Cookies.get('clientType') === 'SaaS' ? null : (
              <div className="copy-dashboard-container__panels__panel__spaceman__left-text">
                <h4>Meet with your account manager</h4>
                <button
                  onClick={() =>
                    setScheduleCallModalVisible(!scheduleCallModalVisible)
                  }
                >
                  Book meeting
                </button>
              </div>
            )}
            <img
              className={` ${
                Cookies.get('clientType') === 'SaaS' ? 'saas-meeting' : null
              } copy-dashboard-container__panels__panel__spaceman__right-image`}
              src={
                Cookies.get('clientType') === 'SaaS' ? meetingSaas : spaceman
              }
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="copy-dashboard-container__graph-container">
        <div className="copy-dashboard-container__graph-container__graph">
          <ConversionRatesGraph sequenceFilterValue={selectedSequence} />
        </div>
      </div>

      <div className="copy-dashboard-container__table-container">
        <CopyDashboardTable sequence={selectedSequence} />
      </div>
    </div>
  );
};

export default CopyDashboard;
