import React, { useEffect, useState } from 'react';
import './reset-password.scss';
import auth from '../../firebase/init';
import logo from '../../assets/signIn/logo.png';
import { useNavigate } from 'react-router-dom';
import { confirmPasswordReset, updatePassword } from 'firebase/auth';
import ctrlLogo from '../../assets/sideBar/ctrl-logo.svg';
import eye from '../../assets/register/eye-open-icon.png';
import eyeClose from '../../assets/register/eye-icon.png';
import pattern from '../../assets/pattern.svg';

const ResetPassword = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
  const [passwordResetFailed, setPasswordResetFailed] = useState(false);
  const [expiredCode, setExpiredCode] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [uppercase, setUppercase] = useState(null);
  const [greaterThan7, setGreaterThan7] = useState(null);
  const [specialCharacter, setSpecialCharacter] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const checkPasswordCriteria = (event: Event) => {
    event.preventDefault();
    setUppercase(/(?=.*[A-Z])/.test(password));
    setGreaterThan7(password.length > 7);
    setSpecialCharacter(/(?=.*[!@#$%^&*()_+])/.test(password));
  };

  useEffect(() => {
    // Check if all password criteria are met
    if (uppercase && greaterThan7 && specialCharacter) {
      // If all criteria are met, trigger onSignUpComplete
      resetPassword(event);
    }
  }, [uppercase, greaterThan7, specialCharacter]);

  const resetPassword = (e: Event) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('oobCode');

    confirmPasswordReset(auth, code, password)
      .then(() => {
        // Success
        setPasswordResetSuccessful(true);
      })
      .catch((error) => {
        // Invalid code
        const errorCode = error.code;
        if (errorCode === 'auth/expired-action-code') {
          setExpiredCode(true);
        } else if (errorCode === 'auth/invalid-action-code') {
          setInvalidCode(true);
        } else if (errorCode === 'auth/weak-password') {
          setWeakPassword(true);
        } else {
          setPasswordResetFailed(true);
        }
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="reset-password-page-container">
      <div className="reset-password-form-container">
        <div className="reset-password-heading-section">
          <img src={ctrlLogo} alt="Fueltofly Logo" />
        </div>
        <form className="reset-password-form">
          <label className="input-item">
            <input
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              placeholder=" "
              onChange={(e) => setPassword(e.target.value)}
            />
            <span>New Password</span>
            <img
              src={passwordVisible ? eye : eyeClose}
              alt=""
              onClick={togglePasswordVisibility}
            />
          </label>
          <div className="reset-password-checks">
            <p
              className={
                uppercase
                  ? 'successful'
                  : uppercase === false
                  ? 'failed'
                  : 'original'
              }
            >
              One uppercase,
            </p>
            <p
              className={
                greaterThan7
                  ? 'successful'
                  : greaterThan7 === false
                  ? 'failed'
                  : 'original'
              }
            >
              more than 7 characters,
            </p>

            <p
              className={
                specialCharacter
                  ? 'successful'
                  : specialCharacter === false
                  ? 'failed'
                  : 'original'
              }
            >
              one special character
            </p>
          </div>
          {passwordResetSuccessful ? (
            <p className="password-reset-successful">
              Password reset successful. You can now login with your new
              password.
            </p>
          ) : null}
          {passwordResetFailed ? (
            <p className="password-reset-failed">
              Something went wrong. Please request another password reset email.
            </p>
          ) : null}
          {expiredCode ? (
            <p className="password-reset-failed">
              Password reset code has expired. Please request another password
              reset email.
            </p>
          ) : null}
          {invalidCode ? (
            <p className="password-reset-failed">
              Password reset code is invalid. Please request another password
              reset email.
            </p>
          ) : null}
          {weakPassword ? (
            <p className="password-reset-failed">
              Your new password is too weak. Please try again with a stronger
              password.
            </p>
          ) : null}
          <button type="submit" onClick={() => checkPasswordCriteria(event)}>
            Reset Password
          </button>
        </form>
        {/* <hr className="reset-password-hr" /> */}
        <div className="reset-password">
          <p onClick={() => navigate('/')}>Go to Log In</p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
