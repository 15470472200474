//Fundamentals
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Styles
import './completed.scss';

//Images
import spaceman from '../../../assets/register/completed-page.png';

const Completed = () => {
  return (
    <div className="completed">
      <div className="completed__center-block">
        <h1>We Have Lift Off</h1>
        <img src={spaceman} alt="" />

        <Link to="/">Let’s Go!</Link>
      </div>
    </div>
  );
};

export default Completed;
