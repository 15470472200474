// Fundamentals
import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Styles
import './keyWordsSideBar.scss';

//Images
import keywords from '../../../assets/keyWordsSideBar/tech-settings-keywords.svg';
import personasIcon from '../../../assets/keyWordsSideBar/tech-settings-personas.svg';
import sizeIcon from '../../../assets/keyWordsSideBar/tech-settings-size.svg';

interface keyWordsSideBarProps {
  onCloseSidebar: () => void;
}

interface ISearchItem {
  type: string;
  payload: string[];
}

interface IVariationSearchTermsSlice {
  organizationNumEmployeesRanges: ISearchItem;
  personTitles: ISearchItem;
}

interface IStore {
  variationSearchTerms: IVariationSearchTermsSlice;
}

const KeyWordsSideBar = ({ onCloseSidebar }: keyWordsSideBarProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const size = useSelector(
    (state: IStore) => state.variationSearchTerms.organizationNumEmployeesRanges
  );
  const personas = useSelector(
    (state: IStore) => state.variationSearchTerms.personTitles
  );

  const closeSidebar = () => {
    setIsOpen(false);
    onCloseSidebar(); // Call the callback function to notify the parent component
  };

  return (
    <div className={`tech-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="tech-sidebar__container">
        <div className="tech-sidebar__container__header">
          <h3>Sales Leaders in Tech</h3>
          <button
            className="tech-sidebar__container__header__sidebar-toggle"
            onClick={closeSidebar}
          >
            CLOSE
          </button>
        </div>
        <div className="tech-sidebar__container__content">
          <div className="tech-sidebar__container__content__option">
            <div className="tech-sidebar__container__content__option__header">
              <img src={keywords} alt="" />
              <h4>Keywords</h4>
            </div>
            <div className="tech-sidebar__container__content__option__body">
              <p>Lorem</p>
              <p>Lorem</p>
              <p>Lorem</p>
              <p>Lorem</p>
            </div>
          </div>
          <div className="tech-sidebar__container__content__option">
            <div className="tech-sidebar__container__content__option__header">
              <img src={personasIcon} alt="" />
              <h4>Personas</h4>
            </div>
            <div className="tech-sidebar__container__content__option__body">
              {personas.payload &&
                personas.payload.map((persona, index) => (
                  <p key={index}>{persona}</p>
                ))}
            </div>
          </div>
          <div className="tech-sidebar__container__content__option">
            <div className="tech-sidebar__container__content__option__header">
              <img src={sizeIcon} alt="" />
              <h4>Size</h4>
            </div>
            <div className="tech-sidebar__container__content__option__body">
              {size.payload &&
                size.payload.map((size, index) => <p key={index}>{size}</p>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyWordsSideBar;
