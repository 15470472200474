export enum Color {
  WHITE = '#FFF',
  LIGHT_GREY = '#F2F5F7',
  MEDIUM_GREY = '#E4E8EC',
  DARK_GREY = '	#cdd0d4',
  BLACK_TEXT = '#00080F',
  GREY_BORDER = '#E4E8EC',
  GREY_TEXT = '#1D2C39',
  SVG_WHITE = 'invert(0%)',
  SLIDER_START = 'rgba(0, 48, 92, 1)',
  SLIDER_END = 'rgba(46, 187, 188, 1)',

  LIGHT_BLUE = 'rgba(0, 48, 92, 0.32)',
  MEDIUM_BLUE = 'rgba(0, 19, 36, 0.64)',
  DARK_BLUE = '#001324',
  DARKEST_BLUE = '#00080F',
  RIGHT_SIDE_BAR_BLUE = '#001324',
  BLUE_TEXT = '#34CCCD',
  DARK_BLUE_TEXT = '#00305C',
  BLUE_BORDER = '#64D8D9',
  INHERIT_COLOR = 'inherit',
  SVG_BLACK = 'invert(100%)',
  TOOLTIP = 'rgb(0, 48, 92)',
  BUTTON_HOVER = 'rgba(52, 204, 205, 0.24)',

  SLIGHT_GREY = '#595959',
  SMEDIUM_GREY = '#262626',
  SDARK_GREY = '#0A0A0A',
  SDARKER_GREY = '#1A1A1A',
  SDARKEST_GREY = '#0A0A0A',
  SYELLOW_YELLOW = '#D5EF10',
  SLIDER_START_YELLOW = '#5A6600',
  SLIDER_END_YELLOW = '#D5EF10',
  TABLE_YELLOW = '#2D3300',
  TOOLTIP_SAAS = '#0D0D0D',
  BUTTON_HOVER_SAAS = '#5A6600',
}
