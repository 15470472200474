// Fundamentals
import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import auth from '../../../firebase/init';

// Styles
import './billingSettings.scss';
import PlanOptions from './planOptions/planOptions';

//Components
interface IStripeId {
  type: string;
  payload: string;
}

interface IAuthSlice {
  stripeId: IStripeId;
}

interface IStore {
  auth: IAuthSlice;
}

const BillingSettings = () => {
  const stripeId = useSelector((state: IStore) => state.auth.stripeId);
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const openStripeCustomerPortal = () => {
    const data = {
      customer_id: stripeId.payload,
      customer_url: window.location.href,
    };
    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'POST',
            url: apiEndpoint + 'api/billing',
            headers: { Authorization: 'Bearer ' + idToken },
            data,
          }).then((response) => {
            window.open(response.data.data);
          });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  return (
    <div className="billing-settings-container">
      {/* <h1>Your payment details</h1>
      <p>Your credit card is processed using a secure payment provider</p>

      <form className="billing-settings-container__payment-container">
        <div className="billing-settings-container__payment-container__payment-options">
          <div className="billing-settings-container__payment-container__payment-options__name">
            <div className="billing-settings-container__payment-container__payment-options__name__label-input-container label-container">
              <label htmlFor="name">First Name</label>
              <input type="text" name="name" id="name" placeholder="Lisa" />
            </div>
            <div className="billing-settings-container__payment-container__payment-options__name__label-input-container label-container">
              <label htmlFor="surname">Last name</label>
              <input
                type="text"
                name="surname"
                id="surname"
                placeholder="Smith"
              />
            </div>
          </div>
          <div className="billing-settings-container__payment-container__payment-options__card-details">
            <div className="label-input-container billing-settings-container__payment-container__payment-options__card-details__left-section label-container">
              <label htmlFor="card-number">Card number</label>
              <input
                type="text"
                name="card-number"
                id="card-number"
                placeholder="0000-0000-0000-0000"
              />
            </div>
            <section className="billing-settings-container__payment-container__payment-options__card-details__right-section">
              <div className="label-input-container label-container">
                <label htmlFor="cvc">CVC</label>
                <input type="text" name="cvc" id="cvc" placeholder="000" />
              </div>
              <div className="label-input-container label-container">
                <label htmlFor="date">MM/YY</label>
                <input type="text" name="date" id="date" placeholder="00/00" />
              </div>
            </section>
          </div>
          <div className="billing-settings-container__payment-container__payment-options__address">
            <div className="label-input-container label-container">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Address"
              />
            </div>
            <div className="billing-settings-container__payment-container__payment-options__address__bottom-section">
              <div className="billing-settings-container__payment-container__payment-options__address__bottom-section__label-input-container label-container">
                <label htmlFor="city">City</label>
                <input type="text" name="city" id="city" placeholder="City" />
              </div>
              <div className="billing-settings-container__payment-container__payment-options__address__bottom-section__label-input-container label-container">
                <label htmlFor="post-code">Post Code</label>
                <input
                  type="text"
                  name="post-code"
                  id="post-code"
                  placeholder="Postal Code"
                />
              </div>
              <div className="billing-settings-container__payment-container__payment-options__address__bottom-section__label-input-container label-container">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  placeholder="Country"
                />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="cursor">
          Save and update
        </button>
      </form>
      <PlanOptions /> */}
      <button
        type="submit"
        className="cursor"
        onClick={() => openStripeCustomerPortal()}
      >
        Open Billing Portal
      </button>
    </div>
  );
};

export default BillingSettings;
