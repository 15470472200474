import React, { useMemo } from 'react';
import { ITooltipParams } from 'ag-grid-community';

const TitleToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  if (data.Title.length > 16) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{data.Title}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const EmailToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  if (data.Email.length > 16) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{data.Email}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const SequenceToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  if (data.Sequence.length > 16) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{data.Sequence}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const CompanyToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  if (data.Company.length > 16) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>Name: {data.Company}</span>
          <br />
          <br />
          <span>Website: {data.Website}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const IndustryToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  if (data.Industry.length > 16) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
          textTransform: 'capitalize',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{data.Industry}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const ApolloIdsToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  let text = '';

  for (let i = 0; i < data.contact_ids.length; i++) {
    if (i == data.contact_ids.length - 1) {
      text += data.contact_ids[i];
    } else {
      text += data.contact_ids[i] + ', ';
    }
  }

  if (data.contact_ids.length > 0) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{text}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const DomainsToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  let text = '';

  for (let i = 0; i < data.companies_domains.length; i++) {
    if (i == data.companies_domains.length - 1) {
      text += data.companies_domains[i];
    } else {
      text += data.companies_domains[i] + ', ';
    }
  }

  if (data.companies_domains.length > 0) {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{text}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const ContactNamesToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  let text = '';

  for (let i = 0; i < data.contact_names.length; i++) {
    if (i == data.contact_names.length - 1) {
      text += data.contact_names[i];
    } else {
      text += data.contact_names[i] + ', ';
    }
  }

  if (data.contact_names.length > 0 && data.contact_names != 'N/A') {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{text}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

const CompanyNamesToolTip = (props: ITooltipParams) => {
  const data: any = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  let text = '';

  for (let i = 0; i < data.company_names.length; i++) {
    if (i == data.company_names.length - 1) {
      text += data.company_names[i];
    } else {
      text += data.company_names[i] + ', ';
    }
  }

  if (data.company_names.length > 0 && data.company_names != 'N/A') {
    return (
      <div
        style={{
          backgroundColor: '#001424',
          border: '1px solid #2fcdcd',
          borderRadius: '6px',
          padding: '10px',
          color: 'white',
        }}
      >
        <p style={{ fontWeight: '600', margin: '0px' }}>
          <span>{text}</span>
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export {
  TitleToolTip,
  EmailToolTip,
  CompanyToolTip,
  IndustryToolTip,
  ApolloIdsToolTip,
  DomainsToolTip,
  ContactNamesToolTip,
  CompanyNamesToolTip,
  SequenceToolTip,
};
