import React from 'react';
import { useSelector } from 'react-redux';
import './copyModal.scss';
import newtab from '../../assets/components/copyModal/new-tab-icon.png';

interface ICopy {
  sequence_name: string;
  link: string;
}

interface ICopyIndex {
  type: string;
  payload: number;
}

interface ICopyDraftSlice {
  copyIndex: ICopyIndex;
}

interface IStore {
  copyDraft: ICopyDraftSlice;
}

interface IToggleModal {
  toggleModal?: (isOpen: boolean) => void;
  isOpen: boolean;
  copies: ICopy[];
}

const CopyModal = ({ toggleModal, isOpen, copies }: IToggleModal) => {
  const copyIndex = useSelector((state: IStore) => state.copyDraft.copyIndex);

  const openDocs = () => {
    window.open(copies[copyIndex.payload].link, '_blank');
  };

  return (
    <div className="copy-modal-container" onClick={() => toggleModal(!isOpen)}>
      {/* event.stopPropagation to stop the modal from closing when user clicks on the card */}
      <div
        className="copy-modal-elements-container"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="top-row">
          <div className="zoom-text-container">
            <img
              src={newtab}
              className="new-tab-icon"
              alt="New Tab Icon"
              onClick={() => openDocs()}
            />
            <p className="zoom-text">Open in a new tab.</p>
          </div>
          {/* SVG For Close Icon */}
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            onClick={() => toggleModal(!isOpen)}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1199 13.04L25.5599 2.60001C25.8249 2.31566 25.9691 1.93957 25.9623 1.55096C25.9554 1.16236 25.798 0.79158 25.5232 0.516754C25.2483 0.241928 24.8776 0.0845237 24.489 0.0776672C24.1004 0.0708108 23.7243 0.215051 23.4399 0.480011L12.9999 10.92L2.55992 0.440002C2.27739 0.161522 1.89663 0.00540161 1.49992 0.00540161C1.10322 0.00540161 0.722454 0.161522 0.439926 0.440002C0.299636 0.57859 0.188249 0.74364 0.112228 0.925598C0.0362075 1.10756 -0.00292969 1.3028 -0.00292969 1.5C-0.00292969 1.6972 0.0362075 1.89244 0.112228 2.0744C0.188249 2.25636 0.299636 2.42141 0.439926 2.56L10.8699 13.04L0.439926 23.44C0.299636 23.5786 0.188249 23.7436 0.112228 23.9256C0.0362075 24.1076 -0.00292969 24.3028 -0.00292969 24.5C-0.00292969 24.6972 0.0362075 24.8924 0.112228 25.0744C0.188249 25.2564 0.299636 25.4214 0.439926 25.56C0.57737 25.7016 0.742279 25.8137 0.924545 25.8893C1.10681 25.965 1.3026 26.0027 1.49992 26C1.697 26.0009 1.89229 25.9625 2.07431 25.8869C2.25633 25.8114 2.42143 25.7002 2.55992 25.56L12.9999 15.12L23.4399 25.56C23.5774 25.7016 23.7423 25.8137 23.9245 25.8893C24.1068 25.965 24.3026 26.0027 24.4999 26C24.697 26.0009 24.8923 25.9625 25.0743 25.8869C25.2563 25.8114 25.4214 25.7002 25.5599 25.56C25.8384 25.2775 25.9945 24.8967 25.9945 24.5C25.9945 24.1033 25.8384 23.7225 25.5599 23.44L15.1199 13.04Z"
              fill="white"
              fillOpacity="0.2"
            />
          </svg>
        </div>
        <div className="copy-draft-container">
          <iframe src={copies[copyIndex.payload].link}></iframe>
        </div>
      </div>
    </div>
  );
};

export default CopyModal;
