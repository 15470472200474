// Fundamentals
import React from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { variationSearchTermsActions } from '../../../redux/variationSearchTermsSlice';

// Styles
import './variations.scss';
import SequenceToggle from '../sequenceToggle/sequenceToggle';

interface IApolloSearch {
  url: string;
  terms: any;
}

interface VariationProps {
  isSidebarOpen: boolean;
  openSidebar: () => void;
  variation: variationItem;
}

interface variationItem {
  variation_name: string;
  apollo_search: IApolloSearch;
  volume: number;
  total_contacts: number;
}

const Variation = ({
  isSidebarOpen,
  openSidebar,
  variation,
}: VariationProps) => {
  const dispatch = useDispatch();

  const setKeywords = () => {
    dispatch(
      variationSearchTermsActions.setOrganizationNumEmployeesRanges(
        variation.apollo_search.terms?.organization_num_employees_ranges
      )
    );
    dispatch(
      variationSearchTermsActions.setPersonTitles(
        variation.apollo_search.terms?.person_titles
      )
    );
    openSidebar();
  };

  return (
    <div>
      <div
        className={`variation-container ${isSidebarOpen ? 'sidebar-open' : ''}`}
      >
        <section>
          <div className="variation-container__variation-header">
            <div className="variation-container__variation-header__variation-left">
              <h2>{variation.variation_name}</h2>
              <SequenceToggle />
            </div>
            <div className="variation-container__variation-header__variation-right">
              <p className="variation-container__variation-header__variation-right__variation-contacts sequence-button">
                {variation.volume}% {' ' + variation.total_contacts + ' '}{' '}
                contacts
              </p>
            </div>
          </div>
          <div className="variation-container__variation-body">
            <div className="variation-container__variation-body__variation-body-top">
              <p>Apollo URL</p>
              <div className="variation-container__variation-body__variation-body-top__apollo-url">
                {variation.apollo_search.url}
              </div>
              <button className="sequence-button">Copy URL</button>
              <button className="sequence-button" onClick={setKeywords}>
                View Terms
              </button>
            </div>
            <div className="variation-container__variation-body__variation-notes">
              <p>Notes</p>
              <div className="variation-container__variation-body__variation-notes__variation-internal-notes">
                Internal Notes, if added, appear here
              </div>
            </div>

            <button className="variation-container__variation-body__variation-delivery-settings cursor">
              Show delivery settings
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Variation;
