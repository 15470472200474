import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchType: null,
    contactsData: null,
    interestedContactsData: null,
    contactSearch: null,
    companySearchValue: null,
    firstNameSearchValue: null,
    lastNameSearchValue: null,
  },
  reducers: {
    setSearchType(state, payload) {
      state.searchType = payload;
    },
    setContactsData(state, payload) {
      state.contactsData = payload;
    },
    setInterestedContactsData(state, payload) {
      state.interestedContactsData = payload;
    },
    setContactSearch(state, payload) {
      state.contactSearch = payload;
    },
    setCompanySearchValue(state, payload) {
      state.companySearchValue = payload;
    },
    setFirstNameSearchValue(state, payload) {
      state.firstNameSearchValue = payload;
    },
    setLastNameSearchValue(state, payload) {
      state.lastNameSearchValue = payload;
    },
  },
});

export const searchActions = searchSlice.actions;

export default searchSlice;
