// Fundamentals
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeType, Theme } from './themeModal';
import { THEMES } from './themeConfig';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

interface ThemeContextProps {
  themeType: ThemeType;
  theme: Theme;
  setCurrentTheme: Dispatch<SetStateAction<ThemeType>>;
}

interface IType {
  type: string;
  payload: string;
}

interface IClientTypeSlice {
  clientType: IType;
}

interface IStore {
  clienttype: IClientTypeSlice;
}

export const ThemeContext = createContext<ThemeContextProps>({
  themeType: 'sass',
  theme: THEMES['sass'],
} as ThemeContextProps);

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  //Client Type

  const clientTypeRedux = useSelector(
    (state: IStore) => state.clienttype.clientType
  );

  const [currentTheme, setCurrentTheme] = useState<ThemeType>('sass');

  useEffect(() => {
    Cookies.get('clientType') === 'SaaS'
      ? setCurrentTheme('sass')
      : setCurrentTheme('dark');
  }, [clientTypeRedux?.payload]);

  return (
    <ThemeContext.Provider
      value={{
        themeType: currentTheme,
        theme: THEMES[currentTheme],
        setCurrentTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
