import React, { Fragment, useEffect, useState } from 'react';
import auth from '../../firebase/init';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerCircular } from 'spinners-react';
import {
  createUserWithEmailAndPassword,
  updateProfile,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import axios from 'axios';
import './signUp.scss';
import { showErrorToast } from '../../utils/toast';
import { Link } from 'react-router-dom';
import trailingSpaceRemover, {
  firstSpaceRemover,
} from '../../utils/trailingSpaceRemover';
import logo from '../../assets/sideBar/ctrl-logo.svg';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [saasChild, setSaasChild] = useState(false);
  const [saasParent, setSaasParent] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [missingFields, setMissingFields] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [inviteLink, setInviteLink] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [mutableCompanyField, setMutableCompanyField] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [greaterThan7, setGreaterThan7] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    try {
      const invite: unknown = queryParams.get('invite');
      const pid = queryParams.get('pid');
      const apuuid = queryParams.get('apuuid');
      const name = queryParams.get('name');
      const email = queryParams.get('email');
      if (invite === 'false') {
        if (name.length == 0) {
          setMutableCompanyField(true);
        }
        setInviteLink(false);
        setCompanyName(name);
        setEmail(email);
      } else {
        setInviteLink(true);
        setCompanyName(name);
        setEmail(email);
        if (pid === 'true') {
          setSaasChild(true);
        } else if (apuuid === 'true') {
          setSaasParent(true);
        }
      }
    } catch {
      // Do nothing
    }
  }, []);

  const checkAllFieldsCompleted = () => {
    if (
      email &&
      firstName &&
      lastName &&
      companyName &&
      password &&
      confirmPassword
    ) {
      comparePasswords();
    } else {
      setMissingFields(true);
    }
  };

  const comparePasswords = () => {
    if (password === confirmPassword) {
      signUp();
    } else {
      setPasswordsDontMatch(true);
    }
  };

  const clearFormMessages = () => {
    setPasswordsDontMatch(false);
    setMissingFields(false);
    setEmailError('');
    setGreaterThan7(null);
  };

  const formInputHandler = (field: string, value: string) => {
    switch (field) {
      case 'firstName':
        clearFormMessages();
        setFirstName(value);
        break;
      case 'lastName':
        clearFormMessages();
        setLastName(value);
        break;
      case 'email':
        clearFormMessages();
        setEmail(value);
        break;
      case 'companyName':
        clearFormMessages();
        setCompanyName(value);
        break;
      case 'password':
        clearFormMessages();
        setPassword(value);
        break;
      case 'confirmPassword':
        clearFormMessages();
        setConfirmPassword(value);
        break;
      default:
      // Do Nothing
    }
  };

  // remove preceeding & trailing white space & replace " " with "-"
  const companyNameCleaner = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes
    return str;
  };

  const checkPasswordCriteria = (inputPassword: string) => {
    const localGreaterThan7 = inputPassword.length > 7;
    setGreaterThan7(inputPassword.length > 7);

    if (localGreaterThan7) {
      checkAllFieldsCompleted();
    }
  };

  const emailCheck = (e: Event) => {
    e.preventDefault();
    if (!/\S+@\S+\.\S+/.test(trailingSpaceRemover(firstSpaceRemover(email)))) {
      setEmailError('invalidEmail');
      return;
    }
    fetchSignInMethodsForEmail(
      auth,
      trailingSpaceRemover(firstSpaceRemover(email))
    )
      .then((signInMethods: any) => {
        if (signInMethods.length === 0) {
          setEmailError(null);
          checkPasswordCriteria(
            trailingSpaceRemover(firstSpaceRemover(password))
          );
        } else {
          setEmailError('emailInUse');
        }
      })
      .catch((error: string) => {
        showErrorToast('Something went wrong.');
      });
  };

  const createSaasUser = (type: string) => {
    const data = {
      client_name: companyNameCleaner(companyName),
      email: trailingSpaceRemover(firstSpaceRemover(email)),
      first_name: trailingSpaceRemover(firstSpaceRemover(firstName)),
      last_name: trailingSpaceRemover(firstSpaceRemover(lastName)),
      password: trailingSpaceRemover(firstSpaceRemover(password)),
    };

    let urlString = '';

    if (type === 'child') {
      urlString = 'accept';
    } else {
      urlString = 'admin-accept';
    }

    setRequestInProgress(true);
    axios({
      method: 'POST',
      url: apiEndpoint + 'api/v2/team/' + urlString,
      data: data,
    })
      .then(() => {
        setRequestInProgress(false);
        accountCreatedToast();
      })
      .catch(() => {
        setRequestInProgress(false);
        showErrorToast('Something went wrong.');
      });
  };

  const signUp = () => {
    const data = {
      client_name: companyNameCleaner(companyName),
      email: trailingSpaceRemover(firstSpaceRemover(email)),
      first_name: trailingSpaceRemover(firstSpaceRemover(firstName)),
      last_name: trailingSpaceRemover(firstSpaceRemover(lastName)),
      password: trailingSpaceRemover(firstSpaceRemover(password)),
    };

    // If inviteLink is true then client (Company) already exists in the database
    // So don't create it ie no axios call
    if (inviteLink) {
      if (saasChild) {
        createSaasUser('child');
      } else if (saasParent) {
        createSaasUser('parent');
      } else {
        setRequestInProgress(true);
        axios({
          method: 'POST',
          url: apiEndpoint + 'api/team/accept',
          data: data,
        })
          .then(() => {
            accountCreatedToast();
            setRequestInProgress(false);
          })
          .catch(() => {
            // Do Nothing. UpdateProfile catch
            setRequestInProgress(false);
            showErrorToast('Something went wrong.');
          });
      }
    } else {
      // else if inviteLink is false then create client in database
      setRequestInProgress(true);
      axios({
        method: 'POST',
        url: apiEndpoint + 'api/client',
        data: data,
      })
        .then((response) => {
          if (
            response.data.data === 'Domain already exists!' ||
            response.data.data === 'Client already exists!'
          ) {
            showErrorToast(
              'It appears your company has already signed up. Ask to be invited or contact Support.'
            );
            setRequestInProgress(false);
          } else {
            accountCreatedToast();
            setRequestInProgress(false);
          }
          // ..
        })
        .catch(() => {
          // Axios catch
          setRequestInProgress(false);
          showErrorToast('Something went wrong.');
        });
    }
  };

  const accountCreatedToast = () => {
    toast.success(
      <Fragment>
        Account created successfully!
        <br />
        <Link to="/">Log in now</Link>
      </Fragment>,
      {
        theme: 'dark',
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        className: 'success-toast',
      }
    );
  };

  const emailAlreadyInUseToast = () => {
    toast.warn(
      <Fragment>
        Email already registered. Consider{' '}
        <Link to="/forgot-password">resetting</Link> your password instead.
      </Fragment>,
      {
        theme: 'dark',
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        className: 'error-toast',
      }
    );
  };

  return (
    <div className="sign-up-page-container">
      <ToastContainer />
      <div className="sign-up-form-container">
        <img src={logo} alt="" />
        <p className="sign-up-form-heading">Create your account</p>
        <form className="sign-up-form">
          <div className="name-row">
            <label className={['input-item', 'first-name'].join(' ')}>
              <input
                type="text"
                name="firstName"
                placeholder=" "
                onChange={(e) => formInputHandler('firstName', e.target.value)}
              />
              <span>First name</span>
            </label>
            <label className={['input-item', 'last-name'].join(' ')}>
              <input
                type="text"
                name="lastName"
                placeholder=" "
                onChange={(e) => formInputHandler('lastName', e.target.value)}
              />
              <span>Last name</span>
            </label>
          </div>
          <label className="input-item">
            {inviteLink ? (
              <input
                type="text"
                name="email"
                placeholder=" "
                disabled={inviteLink}
                value={email}
                onChange={(e) => formInputHandler('email', e.target.value)}
              />
            ) : (
              <input
                type="text"
                name="email"
                placeholder=" "
                value={email}
                onChange={(e) => formInputHandler('email', e.target.value)}
              />
            )}
            <span>Your work email</span>
          </label>
          {emailError === 'emailInUse' ? (
            <p className="email-error-text">Email already in use</p>
          ) : null}
          {emailError === 'invalidEmail' ? (
            <p className="email-error-text">Invalid email</p>
          ) : null}
          <label className="input-item">
            <input
              type="text"
              name="companyName"
              placeholder=" "
              disabled={mutableCompanyField ? false : true}
              value={companyName}
              onChange={(e) => formInputHandler('companyName', e.target.value)}
            />
            <span>Company name</span>
          </label>
          <label className="input-item">
            <input
              type="password"
              name="password"
              placeholder=" "
              onChange={(e) => formInputHandler('password', e.target.value)}
            />
            <span>Password</span>
          </label>
          <label className="input-item">
            <input
              type="password"
              name="confirmPassword"
              placeholder=" "
              onChange={(e) =>
                formInputHandler('confirmPassword', e.target.value)
              }
            />
            <span>Confirm Password</span>
          </label>
          {requestInProgress ? (
            <div className="sign-up-form-container__spinner-container">
              <SpinnerCircular
                size={30}
                thickness={200}
                speed={100}
                color="var(--sliderEnd)"
                secondaryColor="var(--sliderStart)"
                className="sign-in-spinner"
              />
            </div>
          ) : null}
          {missingFields ? (
            <p className="missing-fields-text">Please fill in all the fields</p>
          ) : null}
          {passwordsDontMatch ? (
            <p className="missing-fields-text">Passwords don&#39;t match</p>
          ) : null}
          {greaterThan7 === false ? (
            <p className="missing-fields-text">
              Password must be at least 8 characters
            </p>
          ) : null}
          <button type="submit" onClick={() => emailCheck(event)}>
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
