//Fundamentals
import React, { useEffect, useState } from 'react';
import auth from '../../../firebase/init';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { showErrorToast } from '../../../utils/toast';

// Styles
import './teamMembers.scss';

//Images
import backBtn from '../../../assets/register/back-btn.png';

interface TeamMembers {
  onTeamMembersComplete: any;
  onTeamMembersBack: any;
  inputValues: any;
  setInputValues: any;
  updateTeamMembers: any;
}

const TeamMembers = ({
  onTeamMembersComplete,
  onTeamMembersBack,
  inputValues,
  setInputValues,
  updateTeamMembers,
}: TeamMembers) => {
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState('');
  const [emailAdded, setEmailAdded] = useState(false);

  const displayEmails =
    inputValues.teamMembers.length > 0 ? inputValues.teamMembers : emails;

  useEffect(() => {
    // Check if the length has increased
    if (inputValues.teamMembers.length < 1) {
      setEmailAdded(false);
    } else {
      setEmailAdded(true);
    }

    // Update the previous length for the next check
  }, [inputValues.teamMembers]);

  const handleInput = (event: any) => {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      handleAddEmail();
    }
  };

  const handleAddEmail = () => {
    const trimmedEmail = emailInput.trim();
    setError('');
    if (!/\S+@\S+\.\S+/.test(trimmedEmail)) {
      setError('Email is not valid');
      return;
    } else if (displayEmails.includes(trimmedEmail)) {
      setError('Duplicate email');
      return;
    } else {
      fetchSignInMethodsForEmail(auth, trimmedEmail)
        .then((signInMethods: any) => {
          if (signInMethods.length === 0) {
            setError('');
            setEmails([...emails, trimmedEmail]);
            setEmailInput('');
            updateTeamMembers([trimmedEmail]);
          } else {
            setError(' Email already in use');
          }
        })
        .catch((error: string) => {
          showErrorToast('Something went wrong.');
        });
    }
  };

  const addButtonClick = () => {
    handleAddEmail();
  };

  const removeEmail = (index: number) => {
    const updatedEmails = [...displayEmails];
    const removedEmail = updatedEmails.splice(index, 1)[0];

    // Update the parent component's state
    setInputValues((prevInputValues: any) => ({
      ...prevInputValues,
      teamMembers: updatedEmails,
    }));

    // Update the local state
    setEmails(updatedEmails);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Perform any necessary form handling logic here

    // Notify the parent component that sign-up is complete
    onTeamMembersComplete();
  };

  const handleBack = () => {
    // Call the onBack function passed as a prop to go back to the sign-up page
    onTeamMembersBack();
  };

  return (
    <div className="team-members-block">
      <div className="team-members-block__header">
        <img src={backBtn} onClick={handleBack}></img>
        <h1>Add Team Members</h1>
      </div>

      <form
        action="#"
        onSubmit={handleSubmit}
        className="team-members-block__form"
        autoComplete="off"
      >
        <div className="team-members-block__form__area">
          <label htmlFor="emailInput">Email Addresses:</label>
          <div className="team-members-block__form__area__email-name-container">
            {displayEmails.map((email: string, index: number) => (
              <div
                key={index}
                className="team-members-block__form__area__email-name-container__email"
              >
                <span>{email}</span>
                <span
                  onClick={() => removeEmail(index)}
                  className="team-members-block__form__area__email-name-container__email__close-btn"
                >
                  x
                </span>
              </div>
            ))}
          </div>
          {error !== '' ? (
            <p
              style={{ color: '#f72f35', fontWeight: '300', fontSize: '12px' }}
            >
              {error}
            </p>
          ) : null}
          <div className="team-members-block__form__area__input-container">
            <input
              id="emailInput"
              value={emailInput}
              placeholder="Add email addresses here"
              onChange={(e) => setEmailInput(e.target.value)}
              onKeyDown={handleInput}
              className={error ? 'error-color' : null}
            />
            <div
              className="team-members-block__form__area__input-container__add-btn"
              onClick={addButtonClick}
            >
              Add
            </div>
          </div>

          <p>
            Team members will receive invitations to access your admin dashboard
          </p>
        </div>

        <div className="team-members-block__submit">
          <button
            className="team-members-block__submit__skip"
            onClick={handleSubmit}
          >
            Skip
          </button>
          <button
            type="submit"
            className="team-members-block__submit__submit-btn"
            disabled={!emailAdded}
          >
            Invite
          </button>
        </div>
      </form>
    </div>
  );
};

export default TeamMembers;
