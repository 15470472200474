import { createSlice } from '@reduxjs/toolkit';

const personasStatusSlice = createSlice({
  name: 'personasStatusActive',
  initialState: {
    tableData: null,
    industryData: null,
    businessSizeData: null,
    titleData: null,
    sequences: null,
    topPersona: null,
  },
  reducers: {
    setTableData(state, payload) {
      state.tableData = payload;
    },
    setIndustryData(state, payload) {
      state.industryData = payload;
    },
    setBusinessSizeData(state, payload) {
      state.businessSizeData = payload;
    },
    setTitleData(state, payload) {
      state.titleData = payload;
    },
    setSequences(state, payload) {
      state.sequences = payload;
    },
    setTopPersona(state, payload) {
      state.topPersona = payload;
    },
  },
});

export const personasStatusActions = personasStatusSlice.actions;

export default personasStatusSlice;
