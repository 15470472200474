// Fundamentals
import React from 'react';

// Styles
import './sequence-new-search.scss';

//Components

const SequenceNewSearch = () => {
  return (
    <div className="new-search-page-container">
      <p className="new-search-page-container__page-header">
        Link a New Search
      </p>
      <div className="new-search-page-container__selections ">
        <div className="new-search-page-container__selections__selection sequence-name">
          <h4>Name this search</h4>
          <input type="text" placeholder="Sales Leaders 2.0" />
          <p>A good search name describes the demographic being targeted</p>
        </div>
        <div className="new-search-page-container__selections__selection existing-sequence">
          <h4>Link to existing sequence</h4>
          <div className="input-container-dropdown">
            <input type="text" placeholder="Sales Leaders in Tech" />
          </div>
        </div>
        <div className="new-search-page-container__selections__selection existing-sequence">
          <h4>Select senders</h4>
          <div className="input-container-dropdown">
            <input type="text" placeholder="example@email.com" />
          </div>
        </div>
        <div className="new-search-page-container__selections__selection apollo-search">
          <h4>Apollo Search URL</h4>
          <input
            type="text"
            placeholder="apollo.io/123u9o2n3jfjkablefoaiweulbf"
          />
        </div>
        <div className="new-search-page-container__selections__selection percentage">
          <h4>Percentage of budget</h4>
          <div>
            <input type="text" placeholder="50%" />
          </div>
          <p>50% available</p>
        </div>
        <div className="new-search-page-container__selections__selection notes">
          <h4>Sequence notes</h4>
          <input type="text" placeholder="Add internal notes" />
        </div>
      </div>
      <div className="new-search-page-container__options">
        <button className="sequence-button">Cancel</button>
        <button className="new-sequence-button cursor">Create Sequence</button>
      </div>
    </div>
  );
};

export default SequenceNewSearch;
