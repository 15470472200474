import React, { ReactChild, ReactChildren } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface IAuth {
  children: ReactChild | ReactChildren;
}

const ProtectedRoute = ({ children }: IAuth) => {
  const loginString = Cookies.get('loggedIn');

  if (loginString != 'yes') {
    return <Navigate to="/" replace />;
  }
  return <div>{children}</div>;
};

const AdminRoute = ({ children }: IAuth) => {
  const isAdmin = Cookies.get('isAdmin');
  const loginString = Cookies.get('loggedIn');

  if (isAdmin != 'yes' && loginString === 'yes') {
    return <Navigate to="/" replace />;
  } else if (isAdmin != 'yes' && loginString != 'yes') {
    return <Navigate to="/" replace />;
  } else if (isAdmin === 'yes' && loginString === 'yes') {
    return <div>{children}</div>;
  }
};

export { AdminRoute };
export default ProtectedRoute;
