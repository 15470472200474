import { toast } from 'react-toastify';

const showErrorToast = (toastMessage: string) => {
  toast.warn(toastMessage, {
    theme: 'dark',
    autoClose: false,
    position: toast.POSITION.TOP_CENTER,
    className: 'error-toast',
  });
};

const showSuccessToast = (toastMessage: string) => {
  toast.success(toastMessage, {
    theme: 'dark',
    autoClose: false,
    position: toast.POSITION.TOP_CENTER,
    className: 'success-toast',
    hideProgressBar: true,
  });
};

const showSmartleadToast = (toastMessage: string) => {
  toast.info(toastMessage, {
    theme: 'dark',
    autoClose: false,
    position: toast.POSITION.TOP_CENTER,
    className: 'smartlead-toast',
    hideProgressBar: true,
  });
};

export { showErrorToast, showSuccessToast, showSmartleadToast };
