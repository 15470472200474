import React, { ReactChild, ReactChildren } from 'react';
import './modal.scss';

interface IModal {
  size: string;
  visible: boolean;
  background?: string;
  children: ReactChild | ReactChildren;
  toggleModalVisibility?: () => void;
}

const Modal = ({
  size,
  visible,
  children,
  background = 'light',
  toggleModalVisibility,
}: IModal) => {
  return (
    <div
      className={[
        'modal-container',
        visible ? '' : 'modal-container--hide',
      ].join(' ')}
      onClick={toggleModalVisibility}
    >
      {/* event.stopPropagation to stop the modal from closing when user clicks on the modal */}
      <div
        className={[
          `modal-elements-container modal-elements-container--${size}`,
          background === 'dark' ? `modal-elements-container--dark` : '',
        ].join(' ')}
        onClick={(event) => event.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
