import { createSlice } from '@reduxjs/toolkit';

const copiesSlice = createSlice({
  name: 'copies',
  initialState: { bestWorstCopy: null, copyRates: null, copyTableData: null },
  reducers: {
    setBestWorstCopy(state, payload) {
      state.bestWorstCopy = payload;
    },
    setCopyRates(state, payload) {
      state.copyRates = payload;
    },
    setCopyTableData(state, payload) {
      state.copyTableData = payload;
    },
  },
});

export const copiesActions = copiesSlice.actions;

export default copiesSlice;
