import React, { useRef, useState, useMemo } from 'react';
import './dncTab.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ApolloIdsToolTip,
  DomainsToolTip,
  ContactNamesToolTip,
  CompanyNamesToolTip,
} from '../../contactsTable/toolTips/toolTips';
import { ColDef } from 'ag-grid-community';

interface IDNCTab {
  blacklistLogs: BlacklistLogs[];
}

interface BlacklistLogs {
  client: string;
  date: string;
  user: string;
  contact_ids: string[];
  companies_domains: string[];
  contact_names: string[];
  company_names: string[];
  action_type: string;
}

const DNCTab = ({ blacklistLogs }: IDNCTab) => {
  const logsGridRef = useRef<any>();

  // all ag grid rows assigned CSS class 'grid-background'
  const rowClass = 'grid-background';

  const [columnDefs, setColumnDefs] = useState<ColDef<BlacklistLogs, any>[]>([
    {
      field: 'client',
      headerName: 'Client Name',
      filter: true,
      floatingFilter: true,
      rowGroup: true,
      hide: true,
    },
    {
      field: 'action_type',
      headerName: 'Action Type',
      maxWidth: 140,
    },
    {
      field: 'user',
      headerName: 'Actioned By',
      filter: true,
      floatingFilter: true,
    },
    {
      field: 'contact_ids',
      headerName: 'Apollo Ids',
      filter: true,
      floatingFilter: true,
      pivot: true,
      tooltipField: 'contact_ids',
      tooltipComponent: ApolloIdsToolTip,
    },
    {
      field: 'companies_domains',
      headerName: 'Domains',
      filter: true,
      floatingFilter: true,
      tooltipField: 'companies_domains',
      tooltipComponent: DomainsToolTip,
    },
    {
      field: 'contact_names',
      headerName: 'Contact Names',
      filter: true,
      floatingFilter: true,
      tooltipField: 'contact_names',
      tooltipComponent: ContactNamesToolTip,
    },
    {
      field: 'company_names',
      headerName: 'Company Names',
      filter: true,
      floatingFilter: true,
      tooltipField: 'company_names',
      tooltipComponent: CompanyNamesToolTip,
    },
    {
      field: 'date',
      headerName: 'Date',
      filter: true,
      floatingFilter: true,
      rowGroup: true,
      hide: true,
    },
  ]);

  const gridOptions = {
    autoGroupColumnDef: {
      headerName: 'Client',
      minWidth: 200,
    },
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  return (
    <div className="dnc-logs-tab-container">
      <div className="dnc-logs-tab-container__heading-section">
        <p>Logs</p>
      </div>
      <div className="ag-theme-alpine-dark">
        <AgGridReact
          ref={logsGridRef}
          rowData={blacklistLogs}
          rowClass={rowClass}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          tooltipHideDelay={4000}
          pagination={true}
          paginationPageSize={50}
          suppressCellFocus={true}
          domLayout={'autoHeight'}
          gridOptions={gridOptions}
        />
      </div>
    </div>
  );
};

export default DNCTab;
