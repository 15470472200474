// Fundamentals
import React, { useEffect, useRef, useState } from 'react';
import auth from '../../../firebase/init';

// Styles
import './personasDashboard.scss';

// Packages
import axios from 'axios';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { showSmartleadToast } from '../../../utils/toast';
import 'react-toastify/dist/ReactToastify.css';

//Components
import IndustryGraph from '../../../components/dashboards/personasDashboard/industryGraph/industryGraph';
import BusinessSizeGraph from '../../../components/dashboards/personasDashboard/businessSizeGraph/businessSizeGraph';
import JobTitleGraph from '../../../components/dashboards/personasDashboard/jobTitleGraph/jobTitleGraph';
import PersonasTable from '../../../components/dashboards/personasDashboard/personasTable/personasTable';
import SequencesFilter from '../../../components/dashboards/analytics/sequencesFilter/sequencesFilter';
import { personasStatusActions } from '../../../redux/analytics/personasStatusSlice';

// Redux
import { useDispatch, useSelector } from 'react-redux';

//Images
import sequenceIcon from '../../../assets/dashboard/dashboard-sequence-icon.svg';
import ceoIcon from '../../../assets/dashboard/personas/personas-dashboard-ceo.svg';
import bankingIcon from '../../../assets/dashboard/personas/personas-dashboard-banking.svg';
import employeesIcon from '../../../assets/dashboard/personas/personas-dashboard-employees.svg';
import ceoIconSaas from '../../../assets/dashboard/personas/top-persona-person-saas.svg';
import bankingIconSaas from '../../../assets/dashboard/personas/top-persona-industry-saas.svg';
import employeesIconSaas from '../../../assets/dashboard/personas/top-persona-size-saas.svg';
import { personasActions } from '../../../redux/analytics/personasSlice';
import { analyticsActions } from '../../../redux/analytics/analyticsSlice';
import { sequenceStatusActions } from '../../../redux/analytics/sequenceStatusSlice';
import { clientActions } from '../../../redux/client-slice';
import { tab } from '@testing-library/user-event/dist/tab';

interface ISelector {
  type: string;
  payload: [];
}

interface IType {
  type: string;
  payload: string;
}

interface IPersonasSlice {
  tableData: ISelector;
  industryData: ISelector;
  businessSizeData: ISelector;
  titleData: ISelector;
  sequences: ISelector;
}

interface IPersonasStatusSlice {
  tableData: ISelector;
  industryData: ISelector;
  businessSizeData: ISelector;
  titleData: ISelector;
  sequences: ISelector;
  topPersona: ISelector;
}

interface IAnalyticsSlice {
  topPersona: ISelector;
}

interface IClientTypeSlice {
  clientType: IType;
}

interface IStore {
  personas: IPersonasSlice;
  analytics: IAnalyticsSlice;
  personasStatusActive: IPersonasStatusSlice;
  clienttype: IClientTypeSlice;
}

const PersonasDashboard = () => {
  const dispatch = useDispatch();
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [tableData, setTableData] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableError, setError] = useState(false);

  const [industryData, setIndustryData] = useState(null);
  const [industryLoading, setIndustryLoading] = useState(true);
  const [industryError, setIndustryError] = useState(null);

  const [businessData, setBusinessData] = useState(null);
  const [businessSizeLoading, setBusinessSizeLoading] = useState(true);
  const [businessSizeError, setBusinessSizeError] = useState(null);

  const [titleData, setTitleData] = useState(null);
  const [jobTitleLoading, setJobTitleLoading] = useState(true);
  const [jobTitleError, setJobTitleError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [sequenceFilterLoading, setSequenceFilterLoading] = useState(false);

  const [sequencesList, setSequencesList] = useState([]);

  const [topPersonaData, setTopPersonaData] = useState([]);
  const [topPersonaLoading, setTopPersonaLoading] = useState(true);
  const [topPersonaError, setTopPersonaError] = useState(null);

  const [activeToggle, setActiveToggle] = useState(false);
  const [sequenceFilterList, setSequenceFilterList] = useState([]);

  const initialRender = useRef(true);
  const firstRender = useRef(true);
  const [toastShown, setToastShown] = useState(false);
  const dataLoaded = useRef(false);

  //Full personas data
  const tableDataRedux = useSelector(
    (state: IStore) => state.personas.tableData
  );
  const industryDataRedux = useSelector(
    (state: IStore) => state.personas.industryData
  );
  const businessSizeDataRedux = useSelector(
    (state: IStore) => state.personas.businessSizeData
  );
  const titleDataRedux = useSelector(
    (state: IStore) => state.personas.titleData
  );
  const sequencesRedux = useSelector(
    (state: IStore) => state.personas.sequences
  );
  const topPersonaRedux = useSelector(
    (state: IStore) => state.analytics.topPersona
  );

  //Sequence Status data
  const tableDataStatusRedux = useSelector(
    (state: IStore) => state.personasStatusActive.tableData
  );
  const industryDataStatusRedux = useSelector(
    (state: IStore) => state.personasStatusActive.industryData
  );
  const businessSizeDataStatusRedux = useSelector(
    (state: IStore) => state.personasStatusActive.businessSizeData
  );
  const titleDataStatusRedux = useSelector(
    (state: IStore) => state.personasStatusActive.titleData
  );
  const sequencesStatusRedux = useSelector(
    (state: IStore) => state.personasStatusActive.sequences
  );
  const topPersonaStatusRedux = useSelector(
    (state: IStore) => state.analytics.topPersona
  );

  //Client Type
  const clientTypeRedux = useSelector(
    (state: IStore) => state.clienttype.clientType
  );

  const setLoadingFalse = () => {
    setJobTitleLoading(false);
    setTopPersonaLoading(false);
    setBusinessSizeLoading(false);
    setIndustryLoading(false);
  };

  useEffect(() => {
    // Check if the data is fully loaded
    const isDataLoaded =
      tableData?.length > 0 &&
      industryData?.length > 0 &&
      topPersonaData?.length > 0;

    if (firstRender.current) {
      firstRender.current = false;
      dataLoaded.current = isDataLoaded;
      return; // Skip the effect on initial render
    }

    // Run the effect only if the data loading state changes from false to true
    if (!dataLoaded.current && isDataLoaded) {
      dataLoaded.current = true; // Update ref to indicate data has loaded
    } else if (dataLoaded.current && !isDataLoaded) {
      dataLoaded.current = false; // Handle case where data becomes unloaded
      return; // Skip effect if data was loaded but now is unloaded
    }

    if (clientTypeRedux?.payload === 'SaaS' && !toastShown && !isDataLoaded) {
      showSmartleadToast(
        'We noticed that Industry, Job Title and Size are missing from your data. Please include these fields when importing into Smartlead to allow this page to function properly.'
      );
      setToastShown(true); // Mark toast as shown
    }
  }, [clientTypeRedux, toastShown, tableData, industryData, topPersonaData]);

  useEffect(() => {
    if (tableDataRedux?.payload && topPersonaRedux?.payload) {
      setTableData(tableDataRedux?.payload);
      setIndustryData(industryDataRedux?.payload);
      setSequenceFilterList(sequencesRedux?.payload);
      setBusinessData(businessSizeDataRedux?.payload);
      setTitleData(titleDataRedux?.payload);
      setSequencesList(sequencesRedux?.payload);
      setTopPersonaData(topPersonaRedux?.payload);
      setLoadingFalse();
    } else {
      getAllTimeData();
    }
  }, []);

  const getAllTimeData = () => {
    setTableLoading(true);
    setLoading(true);

    auth.onAuthStateChanged((user) => {
      user
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-personas/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTableData(response.data.data);
              dispatch(personasActions.setTableData(response.data.data));
              setTableLoading(false);
            })
            .catch(() => {
              setTableLoading(false);
              setError(true);
            });

          // Sequence Filter List
          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-sequences-volumes/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              getSequences(response.data.data);
            })
            .catch(() => {
              setSequenceFilterLoading(false);
            });

          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-industry-personas/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setIndustryData(response.data.data);
              dispatch(personasActions.setIndustryData(response.data.data));
              setIndustryLoading(false);
            })
            .catch(() => {
              setIndustryLoading(false);
              setIndustryError(true);
            });

          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-size-personas/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setBusinessData(response.data.data);
              dispatch(personasActions.setBusinessSizeData(response.data.data));
              setBusinessSizeLoading(false);
            })
            .catch(() => {
              setBusinessSizeLoading(false);
              setBusinessSizeError(true);
            });

          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-top-persona/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTopPersonaData(response.data.data);
              dispatch(analyticsActions.setTopPersona(response.data.data));
              setTopPersonaLoading(false);
            })
            .catch(() => {
              setTopPersonaLoading(false);
              setTopPersonaError(true);
            });

          axios({
            method: 'GET',
            url:
              apiEndpoint +
              'api/smartlead-title-personas/' +
              Cookies.get('fuelStationId'),
            headers: { Authorization: 'Bearer ' + idToken },
          })
            .then((response) => {
              setTitleData(response.data.data);
              dispatch(personasActions.setTitleData(response.data.data));
              setJobTitleLoading(false);
            })
            .catch(() => {
              setJobTitleLoading(false);
              setJobTitleError(true);
            });
        })
        .catch(function (error) {
          // Handle error
        });
    });
  };

  // returns unique items in a list
  const onlyUniqueItems = (value: any, index: any, self: string | any[]) => {
    return self.indexOf(value) === index;
  };

  //Below sets the sequence list for all sequences
  const getSequences = (sequences: any) => {
    const temporaryList: any[] = [];
    const sequenceNamesList: string[] = [];
    sequences.map((sequence: any) => {
      // Extract only the desired properties from each sequence object
      const { Sequence, sequence_status } = sequence;

      // Create a new object with only the desired properties
      const simplifiedSequence = {
        Sequence,
        sequence_status,
      };

      // Push the simplified object to the temporaryList
      temporaryList.push(simplifiedSequence);
      sequenceNamesList.push(Sequence);
    });

    // const sequenceNamesList = temporaryList.map((item) => item.Sequence);

    setSequencesList(temporaryList);
    setSequenceFilterList(sequenceNamesList);
    dispatch(personasActions.setSequences(temporaryList));
    return sequenceNamesList;
  };

  //Below sets the sequence list for active sequences
  const getSequencesStatusList = (sequences: any) => {
    const temporaryList: any[] = [];
    const sequenceNamesList: string[] = [];
    sequences.map((sequence: any) => {
      // Extract only the desired properties from each sequence object
      const { Sequence, sequence_status } = sequence;

      // Create a new object with only the desired properties
      const simplifiedSequence = {
        Sequence,
        sequence_status,
      };

      // Push the simplified object to the temporaryList
      temporaryList.push(simplifiedSequence);
      sequenceNamesList.push(Sequence);
    });

    setSequencesList(temporaryList);
    setSequenceFilterList(sequenceNamesList);
    dispatch(personasStatusActions.setSequences(temporaryList));
    return sequenceNamesList;
  };

  const handleFilterSequences = (filterList: string[]) => {
    const data = {
      client: Cookies.get('fuelStationId'),
      list_of_sequences: filterList.toString(),
    };

    setSequenceFilterList(filterList);
    if (filterList.length > 0) {
      setJobTitleLoading(true);
      setTopPersonaLoading(true);
      setBusinessSizeLoading(true);
      setIndustryLoading(true);
      setTableLoading(true);
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-industry-personas',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setIndustryData(response.data.data);
                setIndustryLoading(false);
              })
              .catch(() => {
                setIndustryLoading(false);
                setError(true);
              });

            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-personas',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTableData(response.data.data);
                setTableLoading(false);
              })
              .catch(() => {
                setTableLoading(false);
                setError(true);
              });

            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-size-personas',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setBusinessData(response.data.data);
                setBusinessSizeLoading(false);
              })
              .catch(() => {
                setBusinessSizeLoading(false);
                setError(true);
              });

            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-top-persona',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTopPersonaData(response.data.data);
                setTopPersonaLoading(false);
              })
              .catch(() => {
                setTopPersonaLoading(false);
                setTopPersonaError(true);
              });

            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-title-personas',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTitleData(response.data.data);
                setJobTitleLoading(false);
              })
              .catch(() => {
                setJobTitleLoading(false);
                setJobTitleError(true);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  };

  //Below is for filtering on active or all sequences
  const handleFilterSequencesStatus = () => {
    //Below checks if the toggle is set to active or not
    if (!activeToggle) {
      if (tableDataRedux?.payload) {
        //If toggle is not set to active it checks if the full data redux has data and sets it to that
        setTableData(tableDataRedux?.payload);
        setIndustryData(industryDataRedux?.payload);
        setBusinessData(businessSizeDataRedux?.payload);
        setTitleData(titleDataRedux?.payload);
        setSequencesList(sequencesRedux?.payload);
        setSequenceFilterList(getSequences(sequencesRedux?.payload));
        setTopPersonaData(topPersonaRedux?.payload);
        setLoadingFalse();
      } else {
        //Otherwise it runs getAllTimeData function
        getAllTimeData();
      }
    } else {
      const data = {
        client: Cookies.get('fuelStationId'),
        sequence_status: 'Active',
      };

      //Below checks if the redux data exists before running API calls
      if (tableDataStatusRedux?.payload) {
        setTableData(tableDataStatusRedux?.payload);
        setIndustryData(industryDataStatusRedux?.payload);
        setBusinessData(businessSizeDataStatusRedux?.payload);
        setTitleData(titleDataStatusRedux?.payload);
        setSequencesList(sequencesStatusRedux?.payload);
        setSequenceFilterList(
          getSequencesStatusList(sequencesStatusRedux?.payload)
        );
        setTopPersonaData(topPersonaStatusRedux?.payload);
        setLoadingFalse();
        //Below it to set all the loading signs back to false once they all load
      } else {
        setJobTitleLoading(true);
        setTopPersonaLoading(true);
        setBusinessSizeLoading(true);
        setIndustryLoading(true);
        setTableLoading(true);
        auth.onAuthStateChanged((user) => {
          user
            .getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-personas',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setTableData(response.data.data);
                  dispatch(
                    personasStatusActions.setTableData(response.data.data)
                  );
                  setTableLoading(false);
                })
                .catch(() => {
                  setTableLoading(false);
                  setError(true);
                });

              // Sequence Filter List
              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-sequences-volumes',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  getSequencesStatusList(response.data.data);
                })
                .catch(() => {
                  setSequenceFilterLoading(false);
                });

              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-industry-personas',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setIndustryData(response.data.data);
                  dispatch(
                    personasStatusActions.setIndustryData(response.data.data)
                  );
                  setIndustryLoading(false);
                })
                .catch(() => {
                  setIndustryLoading(false);
                  setIndustryError(true);
                });

              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-size-personas',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setBusinessData(response.data.data);
                  dispatch(
                    personasStatusActions.setBusinessSizeData(
                      response.data.data
                    )
                  );
                  setBusinessSizeLoading(false);
                })
                .catch(() => {
                  setBusinessSizeLoading(false);
                  setBusinessSizeError(true);
                });

              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-top-persona',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setTopPersonaData(response.data.data);
                  dispatch(
                    personasStatusActions.setTopPersona(response.data.data)
                  );
                  setTopPersonaLoading(false);
                })
                .catch(() => {
                  setTopPersonaLoading(false);
                  setTopPersonaError(true);
                });

              axios({
                method: 'POST',
                url: apiEndpoint + 'api/smartlead-title-personas',
                headers: { Authorization: 'Bearer ' + idToken },
                data: data,
              })
                .then((response) => {
                  setTitleData(response.data.data);
                  dispatch(
                    personasStatusActions.setTitleData(response.data.data)
                  );
                  setJobTitleLoading(false);
                })
                .catch(() => {
                  setJobTitleLoading(false);
                  setJobTitleError(true);
                });
            })
            .catch(function (error) {
              // Handle error
            });
        });
      }
    }
  };

  const filterTableSquences = (filterList: string[]) => {
    const data = {
      client: Cookies.get('fuelStationId'),
      list_of_sequences: filterList.toString(),
    };

    if (filterList.length > 0) {
      setTableLoading(true);
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'POST',
              url: apiEndpoint + 'api/smartlead-personas',
              headers: { Authorization: 'Bearer ' + idToken },
              data: data,
            })
              .then((response) => {
                setTableData(response.data.data);
                setTableLoading(false);
              })
              .catch(() => {
                setTableLoading(false);
                setError(true);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  };

  const dualFilter = (filterList: string[]) => {
    handleFilterSequences(filterList);
    filterTableSquences(filterList);
  };
  const topPersonaPanel =
    topPersonaData?.map((item: any) => ({
      name: `${item.Sequence}`,
      conversation: `${parseFloat(item.Conversion_Rate)}`,
      reply: `${parseFloat(item.reply_rate)}`,
      interested: `${parseFloat(item.possible_conversation_rate)}`,
      industry: `${item.Industry.split(' ')
        .map((word: string) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ')}`,
      size: `${item.Size}`,
      title: `${item.Title}`,
      open: `${parseFloat(item.open_rate)}`,
      industryImage: `${(item.Industry ?? '')
        .toLowerCase()
        .split(' ')
        .join('_')}`,
      titleImage: `${(item.Title ?? '').toLowerCase().split(' ').join('_')}`,
      sizeImage: `${(item.Size ?? '').toLowerCase().split('-').join('_')}`,
    })) || [];

  const industryFallBackImage = (event: any) => {
    event.target.src = bankingIcon;
  };

  const titleFallBackImage = (event: any) => {
    event.target.src = ceoIcon;
  };

  const sizeFallBackImage = (event: any) => {
    event.target.src = employeesIcon;
  };

  const topPersonaText = topPersonaPanel
    .slice(0, 1)
    .map((item: any, index: number) => {
      return (
        <div key={index}>
          <div className="personas-dashboard-container__company-info__top-personas__info">
            <div className="personas-dashboard-container__company-info__top-personas__info__item">
              <img
                src={
                  Cookies.get('clientType') === 'SaaS'
                    ? ceoIconSaas
                    : `https://fueltofly.ams3.digitaloceanspaces.com/job-title-images/${item.titleImage}.svg`
                }
                alt=""
                onError={titleFallBackImage}
              />
              <p>{item.title}</p>
            </div>
            <div className="personas-dashboard-container__company-info__top-personas__info__item">
              <img
                src={
                  Cookies.get('clientType') === 'SaaS'
                    ? bankingIconSaas
                    : `https://fueltofly.ams3.digitaloceanspaces.com/industry-images/${item.industryImage}.png`
                }
                alt=""
                onError={industryFallBackImage}
              />
              <p>{item.industry}</p>
            </div>
            <div className="personas-dashboard-container__company-info__top-personas__info__item">
              <img
                src={
                  Cookies.get('clientType') === 'SaaS'
                    ? employeesIconSaas
                    : `https://fueltofly.ams3.digitaloceanspaces.com/company-size-images/${item.sizeImage}.svg`
                }
                alt=""
                onError={sizeFallBackImage}
              />
              <p>{item.size}</p>
            </div>
          </div>
          <div className="personas-dashboard-container__company-info__top-personas__percentages">
            <p>{item.conversation}% Conversion</p>
            <p>{item.reply}% Reply</p>
            <p>{item.interested}% Interested</p>
          </div>
        </div>
      );
    });

  const [activeGraph, setActiveGraph] = useState('industry'); // Track the active graph: 'industry' or 'business' or 'job'

  const handleGraphChange = (graph: string) => {
    setActiveGraph(graph);
  };

  const activeToggleChange = () => {
    setActiveToggle(!activeToggle);
  };

  useEffect(() => {
    // After after toggle function is run and the state changes, the function below will run
    if (initialRender.current) {
      initialRender.current = false;
      return; // Skip the effect on initial render
    }
    handleFilterSequencesStatus();
  }, [activeToggle]);

  return (
    <div className="personas-dashboard-container">
      <ToastContainer />
      <header className="personas-dashboard-container__header-dropdown">
        <div className="personas-dashboard-container__header-dropdown__container">
          <img src={sequenceIcon} alt="" />
          <SequencesFilter
            options={sequencesList}
            originalOption="Campaigns"
            clickHandler={dualFilter}
            activeToggle={activeToggle}
          />
        </div>

        <div className="personas-dashboard-container__header-dropdown__toggle">
          <div className="personas-dashboard-container__header-dropdown__toggle__flex">
            <div className="personas-dashboard-container__header-dropdown__toggle__flex__toggle-btn">
              <label className="switch">
                <input type="checkbox" />
                <span
                  className="slider round"
                  onClick={activeToggleChange}
                ></span>
              </label>
            </div>
          </div>
          <p>{activeToggle ? 'Active' : 'All'}</p>
        </div>
      </header>

      <div className="personas-dashboard-container__company-info">
        <div className="personas-dashboard-container__company-info__top-personas">
          <h2>Consider targeting more people like:</h2>
          {topPersonaLoading ? (
            // Show the spinner while loading is true
            <div className="loading-container-top-persona">
              <div className="loading-container-top-persona__flex">
                <div className="loading-block loading-container-top-persona__flex__image"></div>
                <div className="loading-block loading-text-small"></div>
              </div>
              <div className="loading-container-top-persona__flex">
                <div className="loading-block loading-container-top-persona__flex__image"></div>
                <div className="loading-block loading-text-small"></div>
              </div>
              <div className="loading-container-top-persona__flex">
                <div className="loading-block loading-container-top-persona__flex__image"></div>
                <div className="loading-block loading-text-small"></div>
              </div>
              <div className="loading-container-top-persona__flex">
                <div className="loading-block loading-container-top-persona__flex__interested"></div>
                <div className="loading-block loading-container-top-persona__flex__interested"></div>
                <div className="loading-block loading-container-top-persona__flex__interested"></div>
              </div>
            </div>
          ) : topPersonaError ? (
            // Display error message when data could not be loaded
            <div className="error-message">
              Data could not be loaded. Please try again later.
            </div>
          ) : topPersonaData?.length < 1 && toastShown ? (
            <div className="loading-container-top-persona">
              <div className="loading-container-top-persona__flex">
                <div className="missing-data loading-container-top-persona__flex__image"></div>
                <div className="missing-data loading-text-small"></div>
              </div>
              <div className="loading-container-top-persona__flex">
                <div className="missing-data loading-container-top-persona__flex__image"></div>
                <div className="missing-data loading-text-small"></div>
              </div>
              <div className="loading-container-top-persona__flex">
                <div className="missing-data loading-container-top-persona__flex__image"></div>
                <div className="missing-data loading-text-small"></div>
              </div>
              <div className="loading-container-top-persona__flex">
                <div className="missing-data loading-container-top-persona__flex__interested"></div>
                <div className="missing-data loading-container-top-persona__flex__interested"></div>
                <div className="missing-data loading-container-top-persona__flex__interested"></div>
              </div>
            </div>
          ) : topPersonaData?.length < 1 ? (
            // Display error message when data could not be loaded
            <div className="error-message">No data to display yet</div>
          ) : (
            <div>{topPersonaText}</div>
          )}
        </div>
        <div className="personas-dashboard-container__company-info__right-graph">
          <div className="personas-dashboard-container__company-info__right-graph__buttons">
            <button
              className={activeGraph === 'industry' ? 'active' : ''}
              onClick={() => handleGraphChange('industry')}
            >
              Industry
            </button>
            <button
              className={activeGraph === 'business' ? 'active' : ''}
              onClick={() => handleGraphChange('business')}
            >
              Business Size
            </button>
            <button
              className={activeGraph === 'job' ? 'active' : ''}
              onClick={() => handleGraphChange('job')}
            >
              Job Title
            </button>
          </div>
          <div className="personas-dashboard-container__company-info__right-graph__graph">
            {activeGraph === 'industry' && (
              <IndustryGraph
                data={industryData}
                loading={industryLoading}
                error={industryError}
                missingData={toastShown}
              />
            )}
            {activeGraph === 'business' && (
              <BusinessSizeGraph
                data={businessData}
                loading={businessSizeLoading}
                error={businessSizeError}
              />
            )}
            {activeGraph === 'job' && (
              <JobTitleGraph
                data={titleData}
                loading={jobTitleLoading}
                error={jobTitleError}
              />
            )}
          </div>
        </div>
      </div>
      <div className="personas-dashboard-container__personas-table">
        <PersonasTable
          sequences={sequencesList}
          tableData={tableData}
          loading={tableLoading}
          error={tableError}
          missingData={toastShown}
          filterTableSequences={filterTableSquences}
        />
      </div>
    </div>
  );
};

export default PersonasDashboard;
