// Fundamentals
import React from 'react';

// Styles
import './sequenceToggle.scss';

const SequenceToggle = () => {
  return (
    <div className="sequence-toggle-container">
      <label className="switch">
        <input type="checkbox" />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default SequenceToggle;
