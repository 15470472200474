// Fundamentals
import React, { useEffect, useState } from 'react';
import SequenceBlockItem from './sequenceBlockItem';
import axios from 'axios';

// Styles
import './sequenceBlock.scss';
import { SpinnerCircular } from 'spinners-react';

interface SequenceBlockProps {
  isSidebarOpen: boolean;
  openSidebar: () => void;
}

const SequenceBlock = ({ isSidebarOpen, openSidebar }: SequenceBlockProps) => {
  const [sequences, setSequences] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios({
      method: 'GET',
      url: apiEndpoint + 'api/sequences/Breathe',
      // + Cookies.get('displayName'),
    })
      .then((response) => {
        setSequences(response.data.data.sequences);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <div>
      {loading ? (
        // Show the spinner while loading is true
        <div className="spinner-container">
          <SpinnerCircular
            size={40}
            thickness={200}
            speed={100}
            color="var(--sliderEnd)"
            secondaryColor="var(--sliderStart)"
            className="sign-in-spinner"
          />
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later.
        </div>
      ) : (
        // Render the fetched data when loading is false
        <div>
          {sequences?.map((sequence: any, index: number) => (
            <SequenceBlockItem
              key={index}
              sequence={sequence}
              isSidebarOpen={isSidebarOpen}
              openSidebar={openSidebar}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SequenceBlock;
