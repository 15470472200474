// Fundamentals
import React, { useEffect, useState } from 'react';
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { showErrorToast } from '../../../utils/toast';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../../redux/auth-slice';

// Styles
import './profileSettings.scss';

//Images
import profilePhoto from '../../../assets/settingsPages/my-profile-profile-shot.svg';
import profileEdit from '../../../assets/settingsPages/profile-settings-edit.svg';
import profileDelete from '../../../assets/settingsPages/profile-settings-delete.svg';
import companyLogoDefault from '../../../assets/sideBar/default-company-logo.svg';
import eyeIcon from '../../../assets/register/eye-icon.png';
import eyeOpenIcon from '../../../assets/register/eye-open-icon.png';
import { current } from '@reduxjs/toolkit';

interface IClientDataSelector {
  payload: '';
}

interface IClientDataSlice {
  imageUrl: IClientDataSelector;
}

interface IStore {
  client: IClientDataSlice;
}
const ProfileSettings = () => {
  const imageUrlRedux = useSelector((state: IStore) => state.client.imageUrl);
  const [error, setError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [uppercase, setUppercase] = useState(null);
  const [greaterThan7, setGreaterThan7] = useState(null);
  const [specialCharacter, setSpecialCharacter] = useState(null);
  const [auth, setAuth] = useState(null);
  const user = auth?.currentUser;
  const [retry, setRetry] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    auth.signOut();
    Cookies.remove('loggedIn');
    Cookies.remove('isAdmin');
    dispatch(authActions.logout());
    navigate('/');
  };

  useEffect(() => {
    setAuth(getAuth());
  }, []);

  useEffect(() => {
    if (uppercase && greaterThan7 && specialCharacter) {
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      reauthenticateWithCredential(user, credentials)
        .then(() => {
          updatePassword(user, newPassword)
            .then(() => {
              showErrorToast(
                'Password updated successfully, you will be logged out shortly'
              );
              setNewPassword('');
              setCurrentPassword('');
              const timeoutId = setTimeout(() => {
                logout();
              }, 3000);
            })
            .catch((error) => {
              showErrorToast('Password Update failed, please try again later');
            });
          setError(null);
        })
        .catch((error) => {
          error.code === 'auth/wrong-password'
            ? setError('Current password entered incorrectly')
            : setError('Something went wrong');
        });
    }
  }, [uppercase, greaterThan7, specialCharacter, retry]);

  const handleRetry = () => {
    setRetry(retry + 1); // Increment retry counter to trigger useEffect
  };

  const checkPasswordCriteria = (inputPassword: string) => {
    setUppercase(/(?=.*[A-Z])/.test(inputPassword));
    setGreaterThan7(inputPassword.length > 7);
    setSpecialCharacter(/(?=.*[!@#$%^&*()_+])/.test(inputPassword));
  };

  const passwordSubmit = (e: any) => {
    e.preventDefault();
    checkPasswordCriteria(newPassword);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="profile-settings-container">
      <ToastContainer />
      <h1>Manage Profile</h1>
      <div className="profile-settings-container__body">
        <div className="profile-settings-container__body__left-side">
          <img
            src={
              imageUrlRedux.payload &&
              imageUrlRedux.payload !=
                'https://fueltofly.ams3.digitaloceanspaces.com/client_profile_images/profile.png'
                ? imageUrlRedux.payload
                : companyLogoDefault
            }
            alt="logo"
          />
          {/* <div className="profile-settings-container__body__left-side__photo-options">
            <img src={profileEdit} alt="" />
            <img src={profileDelete} alt="" />
          </div> */}
        </div>
        <div className="profile-settings-container__body__right-side">
          {/* <div className="profile-settings-container__body__right-side__name">
            <div>
              <label htmlFor="name">First Name</label>
              <input type="text" placeholder="Lisa" />
            </div>
            <div>
              <label htmlFor="lastname">Last Name</label>
              <input type="text" placeholder="Smith" />
            </div>
          </div> */}
          {/* <div className="profile-settings-container__body__right-side__settings-option profile-settings-container__body__right-side__settings-option__email">
            <label htmlFor="email">Email address</label>
            <input type="email" placeholder="lisa.smith@gmail.com" />
          </div> */}
          {/* <button className="sequence-button">Save Changes</button> */}
          <div className="profile-settings-container__body__right-side__reset">
            <form
              onSubmit={passwordSubmit}
              className="profile-settings-container__body__right-side__reset__container"
            >
              <label
                htmlFor="currentpassword"
                className="profile-settings-container__body__right-side__reset__container__label"
              >
                Current Password
              </label>
              <span>
                <img
                  src={passwordVisible ? eyeOpenIcon : eyeIcon}
                  className="eye-icon"
                  id="eyeIcon"
                  onClick={togglePasswordVisibility}
                />
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  required
                  id="currentpassword"
                  placeholder="Enter Current Password"
                  className={`sign-up-block__container__password onboarding-input`}
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                />
              </span>
              <label
                htmlFor="password"
                className="profile-settings-container__body__right-side__reset__container__label"
              >
                New Password
              </label>
              <span>
                <img
                  src={passwordVisible ? eyeOpenIcon : eyeIcon}
                  className="eye-icon"
                  id="eyeIcon"
                  onClick={togglePasswordVisibility}
                />
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  required
                  id="password"
                  placeholder="Enter New Password"
                  className={`sign-up-block__container__password onboarding-input`}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </span>
              {error ? <p className="profile-error">{error}</p> : null}
              <p className="profile-settings-container__body__right-side__reset__container__password-definitions">
                <span
                  className={
                    uppercase
                      ? 'successful'
                      : uppercase === false
                      ? 'failed'
                      : 'original'
                  }
                >
                  One uppercase,{' '}
                </span>
                <span
                  className={
                    greaterThan7
                      ? 'successful'
                      : greaterThan7 === false
                      ? 'failed'
                      : 'original'
                  }
                >
                  more than 7 characters,{' '}
                </span>

                <span
                  className={
                    specialCharacter
                      ? 'successful'
                      : specialCharacter === false
                      ? 'failed'
                      : 'original'
                  }
                >
                  one special character
                </span>
              </p>
              <button
                type="submit"
                onClick={handleRetry}
                className="sequence-button"
              >
                Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
