// Fundamentals
import React from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

// Styles
import './topPersona.scss';

//Components

//Images
import topPersonaImage from '../../../../assets/analytics/top_persona.png';
import topPersonaImageSaas from '../../../../assets/analytics/analytics-top-persona-saas.svg';

// Redux

interface ITopPersona {
  topPersonaData: any[];
  loading: boolean;
  isAdmin: boolean;
  error: boolean;
}

interface IType {
  type: string;
  payload: string;
}

interface IClientTypeSlice {
  clientType: IType;
}

interface IStore {
  clienttype: IClientTypeSlice;
}

const industryFallBackImage = (event: any) => {
  event.target.src = topPersonaImage;
};

const TopPersona = ({ topPersonaData, loading, error }: ITopPersona) => {
  return (
    <div className="top-persona-container">
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container">
          <div className="loading-block loading-text-large"></div>
          <div className="loading-block loading-text-small"></div>
          <div className="loading-block loading-text-small loading-interest"></div>
          <div className="loading-block loading-image-top-persona"></div>
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later
        </div>
      ) : topPersonaData?.length < 1 && Cookies.get('clientType') === 'SaaS' ? (
        // Display error message when data could not be loaded
        <div className="error-message">Fields need to be added</div>
      ) : topPersonaData?.length < 1 ? (
        <div className="error-message">No data to display yet</div>
      ) : topPersonaData ? (
        <>
          <div>
            <h4>Consider targeting more people like:</h4>
            <p>{topPersonaData[0]?.Title}</p>
            <p>{topPersonaData[0]?.Industry}</p>
            <p>{topPersonaData[0]?.Size}</p>
          </div>
          <h5 className="top-persona-container__background-purple">
            <p>{`${topPersonaData[0]?.possible_conversation_rate}% Interest`}</p>
          </h5>
        </>
      ) : null}
      {loading ? null : (
        <>
          {topPersonaData ? (
            <img
              src={
                Cookies.get('clientType') === 'SaaS'
                  ? topPersonaImageSaas
                  : `https://fueltofly.ams3.digitaloceanspaces.com/industry-images/${`${(
                      topPersonaData[0]?.Industry ?? ''
                    )
                      .toLowerCase()
                      .split(' ')
                      .join('_')}`}.png`
              }
              alt=""
              onError={industryFallBackImage}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default TopPersona;
