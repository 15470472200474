// Fundamentals
import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import Cookies from 'js-cookie';
import auth from '../../../../firebase/init';

// Styles
import './copyDashboardTable.scss';

//Components
import Modal from '../../../Modal/modal';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { copiesActions } from '../../../../redux/analytics/copiesSlice';

interface ISelector {
  type: string;
  payload: [];
}

interface ICopiesSlice {
  copyTableData: ISelector;
}

interface IStore {
  copies: ICopiesSlice;
}

interface ISequenceFilter {
  sequence: string;
}

const CopyDashboardTable = ({ sequence }: ISequenceFilter) => {
  const apiEndpoint = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const copyTableDataRedux = useSelector(
    (state: IStore) => state.copies.copyTableData
  );

  useEffect(() => {
    if (copyTableDataRedux?.payload) {
      setRowData(copyTableDataRedux.payload);
    } else {
      setLoading(true);

      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            axios({
              method: 'GET',
              url:
                apiEndpoint +
                'api/smartlead-copy-table/' +
                Cookies.get('fuelStationId'),
              headers: { Authorization: 'Bearer ' + idToken },
            })
              .then((response) => {
                setRowData(response.data.data);
                dispatch(copiesActions.setCopyTableData(response.data.data));
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                setError(true);
              });
          })
          .catch(function (error) {
            // Handle error
          });
      });
    }
  }, []);

  // Below code is for rendering the image
  const firstLetter = (params: any) => {
    const title = params.value;
    const firstLetter = title.charAt(0).toUpperCase();

    return (
      <div className="copy-dashboard-table-container__first-letter">
        <div className="copy-dashboard-table-container__first-letter__letter">
          {firstLetter}
        </div>
        {title}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: 'Campaign',
      field: 'sequence',
      filter: 'agSetColumnFilter',
      cellRenderer: firstLetter,
      resizable: true,
      minWidth: 300,
    },
    {
      headerName: 'Variant',
      field: 'variant_name',
      filter: 'agSetColumnFilter',
      resizable: true,
    },
    {
      headerName: 'Status',
      field: 'Status',
      filter: 'agSetColumnFilter',
      resizable: true,
    },
    {
      headerName: 'Email Body',
      field: 'email_body',
      filter: 'agTextColumnFilter',
      resizable: true,
      minWidth: 200,
      cellRenderer: (params: any) => renderEmailBodyCell(params),
      wrapText: true,
      autoHeight: true,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Open Rate',
      field: 'open_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        // Assuming 'reply_rate' is a numeric value representing a percentage
        if (typeof params.value === 'number') {
          return `${params.value.toFixed(2)}%`;
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Reply Rate',
      field: 'reply_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        // Assuming 'reply_rate' is a numeric value representing a percentage
        if (typeof params.value === 'number') {
          return `${params.value.toFixed(2)}%`;
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Interested Rate',
      field: 'interested_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        // Assuming 'reply_rate' is a numeric value representing a percentage
        if (typeof params.value === 'number') {
          return `${params.value.toFixed(2)}%`;
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Conversion Rate',
      field: 'conversion_rate',
      filter: 'agNumberColumnFilter',
      resizable: true,
      valueFormatter: (params: any) => {
        // Assuming 'reply_rate' is a numeric value representing a percentage
        if (typeof params.value === 'number') {
          return `${params.value.toFixed(2)}%`;
        } else {
          return params.value;
        }
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Total Delivered',
      field: 'total_sent',
      filter: 'agNumberColumnFilter',
      resizable: true,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        filterOptions: [
          'equals',
          'greaterThanOrEqual',
          'lessThanOrEqual',
          'inRange',
        ],
        maxNumConditions: 1,
        sort: 'desc',
      },
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      cellStyle: { overflow: 'hidden' },
      menuTabs: ['filterMenuTab'] as any,
    };
  }, []);

  //Below code is to have the graph resize on windows resize
  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };
  }, [gridApi]);

  useEffect(() => {
    // Use the gridApi to set the filter model when the sequence changes
    if (gridApi) {
      const filterModel = {
        sequence: {
          filterType: 'set',
          values: [sequence],
        },
      };

      gridApi.setFilterModel(filterModel);
    }
  }, [sequence, gridApi]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit(); //Sets the width of the columns to use the full width
  };

  const paginationPageSize = 11;

  const handleOpenModal = (value: string) => {
    setIsModalVisible(true);
    setModalContent(value);
  };

  useEffect(() => {
    const handleEscapeKeyPress = (event: any) => {
      if (event.key === 'Escape') {
        setIsModalVisible(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  //Below code is to Display and Hide the hidden email body text
  const renderEmailBodyCell = (params: any) => {
    const { rowIndex, value } = params;

    const truncatedValue =
      value.slice(0, 40) + (value.length > 40 ? '...' : '');

    return (
      <div className="copy-dashboard-table-container__email-body">
        {truncatedValue}

        <button
          className="copy-dashboard-table-container__email-body show-more-button"
          onClick={() => handleOpenModal(value)}
        >
          Show More
        </button>
      </div>
    );
  };

  const LoadingContainerTableRow = () => {
    return (
      <div className="loading-container-table__row">
        <div className="loading-container-table__row__column flex">
          <div className="loading-block loading-container-table__row__column__image"></div>
          <div className="loading-block loading-container-table__row__column__text"></div>
        </div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
        <div className="loading-block loading-container-table__row__column__small"></div>
      </div>
    );
  };

  return (
    <div className="copy-dashboard-table-container">
      <h2>Copy Performance</h2>
      {loading ? (
        // Show the spinner while loading is true
        <div className="loading-container-table">
          <LoadingContainerTableRow />
          <LoadingContainerTableRow />
          <LoadingContainerTableRow />
          <LoadingContainerTableRow />
        </div>
      ) : error ? (
        // Display error message when data could not be loaded
        <div className="error-message">
          Data could not be loaded. Please try again later.
        </div>
      ) : rowData?.length < 1 ? (
        // Display error message when data could not be loaded
        <div className="error-message">No data to display yet</div>
      ) : (
        <div className="ag-theme-alpine-dark copy-dashboard-table-container__table">
          <AgGridReact
            columnDefs={columnDefs}
            suppressMenuHide={true}
            rowData={rowData}
            pagination={true}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={false}
            onGridReady={onGridReady}
            domLayout="autoHeight"
            rowHeight={72}
            defaultColDef={defaultColDef}
          ></AgGridReact>
        </div>
      )}
      <Modal
        size="medium"
        visible={isModalVisible}
        toggleModalVisibility={() => setIsModalVisible(false)}
      >
        <div className="modal-content">
          <div className="close" onClick={handleCloseModal}></div>
          <p>
            {modalContent.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
                <br />
              </React.Fragment>
            ))}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default CopyDashboardTable;
