import React, { useState, useEffect } from 'react';

// Styles
import './sequencesFilter.scss';

interface DropdownProps {
  options: any[];
  originalOption: string;
  clickHandler?: (filterList: string[]) => void;
  sequenceStatus?: string[];
  activeToggle?: boolean;
}

interface ILabel {
  sequence: any;
  index: number;
  status?: string[];
}

const SequencesFilter = ({
  options,
  originalOption,
  clickHandler,
  sequenceStatus,
  activeToggle,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [selectedCheckboxList, setSelectedCheckboxList] = useState([]);
  const [combinedOptions, setCombinedOptions] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    deSelectAllSequences();
  }, [activeToggle]);

  const addFilterItemToList = (sequence: any) => {
    let temporaryList = filterList;
    // temporaryList.push(sequence) wasn't working so stackoverflow said do this instead
    temporaryList = [...temporaryList, sequence];
    setFilterList(temporaryList);
  };

  const removeFilterItemFromList = (sequence: any) => {
    const temporaryList = filterList;
    const index = temporaryList.indexOf(sequence);
    // create a copy for splicing cos dispatch() causes problems
    // if you try to splice temporaryList
    const arrayForSplicing = [...temporaryList];
    // only splice array when item is found
    if (index > -1) {
      arrayForSplicing.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFilterList(arrayForSplicing);
  };

  const selectAllSequences = () => {
    const numberOfSequences = options.length;
    const temporaryList = [];
    for (let i = 0; i < numberOfSequences; i++) {
      temporaryList.push(i);
    }
    temporaryList.push(100);
    setSelectedCheckboxList(temporaryList);

    const temporaryFilterList: string[] = [];
    options.map((sequence) => {
      temporaryFilterList.push(sequence.Sequence);
    });
    setFilterList(temporaryFilterList);
  };

  const deSelectAllSequences = () => {
    setSelectedCheckboxList([]);
    setFilterList([]);
  };

  useEffect(() => {
    const activeOptions = [] as any[];
    const inactiveOptions = [] as any[];

    const optionsOrdered = options.map((item: any) => {
      if (item.sequence_status === 'Active') {
        activeOptions.push(item);
      } else if (item.sequence_status === 'Inactive') {
        inactiveOptions.push(item);
      } else if (!item.sequence_status) {
        inactiveOptions.push(item);
      }
    });

    const sortedInactiveOptions = inactiveOptions.sort((a, b) => {
      // Assuming item has a property 'name' for sorting
      const nameA = a.name?.toLowerCase();
      const nameB = b.name?.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; // Names are equal
    });

    setCombinedOptions(activeOptions.concat(sortedInactiveOptions));
  }, [options]);

  const SelectAllCheckbox = ({ sequence, index }: ILabel) => {
    const [selectedIndex, setSelectedIndex] = useState(false);

    useEffect(() => {
      if (selectedCheckboxList.indexOf(index) >= 0) {
        setSelectedIndex(true);
      }
    }, [selectedCheckboxList]);

    const handleSelection = () => {
      if (selectedIndex && index == 100) {
        deSelectAllSequences();
      }
      if (!selectedIndex && index == 100) {
        // Select All
        selectAllSequences();
      }
    };

    return (
      <div
        className="checkbox-wrapper dropdown-option"
        onClick={handleSelection}
      >
        <label>
          <input type="checkbox" className={selectedIndex ? 'checked' : ''} />
          <p>{sequence}</p>
        </label>
      </div>
    );
  };

  const Checkbox = ({ sequence, index, status }: ILabel) => {
    const [selectedIndex, setSelectedIndex] = useState(false);
    useEffect(() => {
      if (selectedCheckboxList.indexOf(index) >= 0) {
        setSelectedIndex(true);
      }
    }, [selectedCheckboxList]);

    const handleSelection = () => {
      if (selectedIndex) {
        const temporaryList = selectedCheckboxList;
        const itemIndex = temporaryList.indexOf(index);

        // create a copy for splicing cos there'll be problems
        // if you try to splice temporaryList
        const arrayForSplicing = [...temporaryList];
        // only splice array when item is found
        if (itemIndex > -1) {
          arrayForSplicing.splice(itemIndex, 1); // 2nd parameter means remove one item only
        }

        setSelectedCheckboxList(arrayForSplicing);
        removeFilterItemFromList(sequence);
      } else {
        let temporaryList = selectedCheckboxList;
        // temporaryList.push(index) wasn't working so stackoverflow said do a deep copy instead
        temporaryList = [...temporaryList, index];

        setSelectedCheckboxList(temporaryList);
        addFilterItemToList(sequence);
      }
    };

    const isActive = status && status.includes('Active');
    return (
      <div
        className="checkbox-wrapper dropdown-option"
        onClick={handleSelection}
      >
        <label>
          <input type="checkbox" className={selectedIndex ? 'checked' : ''} />
          <p>
            {sequence}{' '}
            <span style={{ color: isActive ? 'green' : 'orange' }}>
              {status}
            </span>
          </p>
        </label>
      </div>
    );
  };

  return (
    <div className={`dropdown-sequences ${isOpen ? 'open' : ''}`}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {originalOption}
        <div className={`arrow ${isOpen ? 'up' : ''}`}></div>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <div className="checkbox-scroll-list">
            <SelectAllCheckbox sequence="Select All" index={100} />
            {combinedOptions?.map((option, index) => (
              <Checkbox
                key={index}
                sequence={option?.Sequence}
                status={option?.sequence_status}
                index={index}
              />
            ))}
          </div>
          <div className="filter-sequences-button-container">
            <button
              className="filter-sequences"
              onClick={() => {
                clickHandler(filterList);
                toggleDropdown();
              }}
            >
              Filter Sequences
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SequencesFilter;
