// Fundamentals
import React, { useState, Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { showErrorToast } from '../../utils/toast';
import { Link } from 'react-router-dom';

// Styles
import './register.scss';

// Images
import logo from '../../assets/sideBar/ctrl-logo.svg';
import signUpIcon from '../../assets/register/signup-icon.svg';
import connectIcon from '../../assets/register/connect-icon.svg';
import companyIcon from '../../assets/register/company-icon.svg';
import crewIcon from '../../assets/register/crew-icon.svg';

// Components
import SignUpBlock from '../../components/register/signUp/signUp';
import ApiKey from '../../components/register/apiKey/apiKey';
import TeamMembers from '../../components/register/teamMembers/teamMembers';
import CompanyDetails from '../../components/register/companyDetails/companyDetails';
import trailingSpaceRemover, {
  firstSpaceRemover,
} from '../../utils/trailingSpaceRemover';
import Completed from '../../components/register/completed/completed';

const Register = () => {
  const [currentComponent, setCurrentComponent] = useState('signUp'); // Default to 'signUp'
  const [completedSignUp, setCompletedSignUp] = useState(false);
  const [completedApiKey, setCompletedApiKey] = useState(false);
  const [completedTeamMembers, setCompletedTeamMembers] = useState(false);
  const [completedCompanyDetails, setCompletedCompanyDetails] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const [inputValues, setInputValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    apiKey: '',
    teamMembers: [],
    companyName: '',
    companyWebsite: '',
  });

  const updateTeamMembers = (newTeamMembers: any) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      teamMembers: [...prevInputValues.teamMembers, ...newTeamMembers],
    }));
  };

  // Color system, lineColor, textColor, imageColor all affect different css styles
  const whiteText = 'white-color'; // Set current onboarding step color to white
  const whiteImage = 'white-image'; // Set current onboarding step color to white
  const lineColor = (completed: any) =>
    completed ? 'blue-color-line' : 'grey-color';
  const textColor = (completed: any, current: any) =>
    current ? whiteText : completed ? 'blue-color-text' : 'grey-color';
  const imageColor = (completed: any, current: any) =>
    current ? whiteImage : completed ? 'blue-color-image' : 'grey-color';

  const renderCurrentComponent = () => {
    switch (currentComponent) {
      case 'signUp':
        return (
          <SignUpBlock
            onSignUpComplete={() => {
              setCompletedSignUp(true);
              setCurrentComponent('apiKey');
            }}
            inputValues={inputValues}
            setInputValues={setInputValues}
          />
        );
      case 'apiKey':
        return (
          <ApiKey
            onApiKeyComplete={() => {
              setCurrentComponent('teamMembers');
              setCompletedApiKey(true);
            }}
            onApiKeyBack={() => {
              setCurrentComponent('signUp'), setCompletedSignUp(false);
            }}
            inputValues={inputValues}
            setInputValues={setInputValues}
          />
        );
      case 'teamMembers':
        return (
          <TeamMembers
            onTeamMembersComplete={() => {
              setCurrentComponent('companyDetails');
              setCompletedTeamMembers(true);
            }}
            onTeamMembersBack={() => {
              setCurrentComponent('apiKey'), setCompletedApiKey(false);
            }}
            inputValues={inputValues}
            setInputValues={setInputValues}
            updateTeamMembers={updateTeamMembers}
          />
        );
      case 'companyDetails':
        return (
          <CompanyDetails
            onCompanyDetailsBack={() => {
              setCurrentComponent('teamMembers');
              setCompletedTeamMembers(false);
            }}
            inputValues={inputValues}
            setInputValues={setInputValues}
            signUpSubmit={signUpSubmit}
            requestInProgress={requestInProgress}
          />
        );
      default:
        return null;
    }
  };

  //Final submit
  const signUpSubmit = () => {
    const data = {
      first_name: trailingSpaceRemover(
        firstSpaceRemover(inputValues.firstName)
      ),
      last_name: trailingSpaceRemover(firstSpaceRemover(inputValues.lastName)),
      user_email: trailingSpaceRemover(firstSpaceRemover(inputValues.email)),
      client_secret: trailingSpaceRemover(
        firstSpaceRemover(inputValues.apiKey)
      ),
      team_members: inputValues.teamMembers.toString(),
      company_name: trailingSpaceRemover(
        firstSpaceRemover(inputValues.companyName)
      ),
      company_website: trailingSpaceRemover(
        firstSpaceRemover(inputValues.companyWebsite)
      ),
      password: trailingSpaceRemover(firstSpaceRemover(inputValues.password)),
    };

    setRequestInProgress(true);
    axios({
      method: 'POST',
      url: apiEndpoint + 'api/onboarding',
      data: data,
      headers: {
        Authorization: 'a2b4f1e87d9c3fe57vb78d76d887b7',
      },
    })
      .then((response) => {
        if (
          response.data.data === 'Domain already exists!' ||
          response.data.data === 'Client already exists!'
        ) {
          showErrorToast(
            'It appears your company has already signed up. Ask to be invited or contact Support.'
          );
          setRequestInProgress(false);
        } else if (response.data.data === 'API Key already exists!') {
          showErrorToast(
            'It appears your api key has already been registered. Please login using the registered account or contact Support.'
          );
        } else {
          accountCreatedToast();
          setRequestInProgress(false);
          setCurrentComponent('completed');
        }
      })
      .catch(() => {
        // Axios catch
        setRequestInProgress(false);
        showErrorToast('Something went wrong.');
      });
  };

  const accountCreatedToast = () => {
    toast.success(
      <Fragment>
        Account created successfully!
        <br />
        <Link to="/">Log in now</Link>
      </Fragment>,
      {
        theme: 'dark',
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        className: 'success-toast',
      }
    );
  };

  const emailAlreadyInUseToast = () => {
    toast.warn(
      <Fragment>
        Email already registered. Consider{' '}
        <Link to="/forgot-password">resetting</Link> your password instead.
      </Fragment>,
      {
        theme: 'dark',
        autoClose: false,
        position: toast.POSITION.TOP_CENTER,
        className: 'error-toast',
      }
    );
  };

  return (
    <div
      className={`onboarding ${requestInProgress ? 'loading-circle' : null}`}
    >
      <ToastContainer />
      <img src={logo} alt="" className="onboarding__logo" />
      {currentComponent === 'completed' ? (
        <Completed />
      ) : (
        <>
          <div className="onboarding__pathway">
            {/* SignUp */}
            <div className="onboarding__pathway__container">
              <img
                src={signUpIcon}
                alt=""
                className={imageColor(
                  completedSignUp,
                  currentComponent === 'signUp'
                )}
              />
              <p
                className={textColor(
                  completedSignUp,
                  currentComponent === 'signUp'
                )}
              >
                Sign Up
              </p>
            </div>
            <div
              className={`onboarding__pathway__line ${lineColor(
                completedSignUp
              )}`}
            ></div>

            {/* ApiKey */}
            <div className="onboarding__pathway__container">
              <img
                src={connectIcon}
                alt=""
                className={imageColor(
                  completedApiKey,
                  currentComponent === 'apiKey'
                )}
              />
              <p
                className={textColor(
                  completedApiKey,
                  currentComponent === 'apiKey'
                )}
              >
                Connect
              </p>
            </div>
            <div
              className={`onboarding__pathway__line ${lineColor(
                completedApiKey
              )}`}
            ></div>

            {/* Team Members */}
            <div className="onboarding__pathway__container">
              <img
                src={crewIcon}
                alt=""
                className={imageColor(
                  completedTeamMembers,
                  currentComponent === 'teamMembers'
                )}
              />
              <p
                className={textColor(
                  completedTeamMembers,
                  currentComponent === 'teamMembers'
                )}
              >
                Crew
              </p>
            </div>
            <div
              className={`onboarding__pathway__line ${lineColor(
                completedTeamMembers
              )}`}
            ></div>

            {/* Company */}
            <div className="onboarding__pathway__container">
              <img
                src={companyIcon}
                alt=""
                className={imageColor(
                  completedCompanyDetails,
                  currentComponent === 'companyDetails'
                )}
              />
              <p
                className={textColor(
                  completedCompanyDetails,
                  currentComponent === 'companyDetails'
                )}
              >
                Company
              </p>
            </div>
          </div>

          <div className="onboarding__center-block">
            {renderCurrentComponent()}
            {/* {currentComponent === 'companyDetails' ? (
              <button onClick={signUpSubmit} className="complete-signup-btn">
                Complete Sign Up
              </button>
            ) : null} */}
          </div>
        </>
      )}
    </div>
  );
};

export default Register;
