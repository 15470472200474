import React, { useEffect, useState } from 'react';

// Styles
import './sequenceLinkingTab.scss';
import RenameSequences from '../../../pages/renameSequences/renameSequences';

interface Client {
  uuid: string;
  name: string;
}

interface SequenceName {
  id: string;
  name: string;
}

interface SequenceLinkingTabProps {
  clientList: Client[];
  sequenceNameList: SequenceName[];
}

const SequenceLinkingTab = ({
  clientList,
  sequenceNameList,
}: SequenceLinkingTabProps) => {
  return (
    <div className="sequence-linking-tab">
      <RenameSequences
        clientList={clientList}
        sequenceNameList={sequenceNameList}
      />
    </div>
  );
};

export default SequenceLinkingTab;
