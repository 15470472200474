import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute, {
  AdminRoute,
} from './components/protectedRoute/protectedRoute';
import AdminDashboard from './pages/admin/admin-dashboard';
import ForgotPassword from './pages/forgotPassword/forgotPassword';
import ResetPassword from './pages/resetPassword/reset-password';
import SignIn from './pages/signIn/signIn';
import SignUp from './pages/signUp/signUp';
import SideBarLayout from './components/sideBarLayout/sideBarLayout';
import SequencesPage from './pages/sequences/sequences';
import SequenceNewSearch from './pages/sequence-new-search/sequence-new-search';
import Settings from './pages/settingsTabs/settingsTab';
import { useTheme } from './contexts/theme/themeContext';
import SequencesSettings from './pages/sequenceSettings/sequenceSettings';
import AnalyticsDashboard from './pages/dashboards/analyticsDashboard/analyticsDashboard';
import PersonasDashboard from './pages/dashboards/personasDashboard/personasDashboard';
import CopyDashboard from './pages/dashboards/copyDashboard/copyDashboard';
import ContactPage from './pages/contactPage/contactPage';
import Faq from './pages/faq/faq';
import Register from './pages/register/register';
import RenameSequences from './pages/renameSequences/renameSequences';
import TrialExpiredPage from './pages/trialExpired/trialExpired';

function App() {
  const { theme } = useTheme();
  return (
    <div style={{ ...(theme as React.CSSProperties) }}>
      <Router>
        <Routes>
          <Route element={<SignIn />} path="/" />
          <Route element={<SignUp />} path="/sign-up" />
          <Route element={<Register />} path="/register" />
          <Route
            element={
              <ProtectedRoute>
                <TrialExpiredPage />
              </ProtectedRoute>
            }
            path="/package-upgrade"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <AnalyticsDashboard isAdmin={true} />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/analytics/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <AnalyticsDashboard isAdmin={false} />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/analytics/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <ContactPage />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/contacts/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <ContactPage />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/contacts/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <CopyDashboard />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/copies/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <CopyDashboard />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/copies/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <PersonasDashboard />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/personas/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <PersonasDashboard />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/personas/:name"
          />
          {/* <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <RenameSequences />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/rename/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <RenameSequences />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/rename/:name"
          /> */}
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <SequencesPage />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/sequences/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <SequencesPage />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/sequences/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <SequencesSettings />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/sequence-settings/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <SequencesSettings />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/sequence-settings/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <SequenceNewSearch />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/new-search/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <SequenceNewSearch />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/new-search/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <Settings isAdmin={true} />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/settings/:name"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <Settings isAdmin={false} />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/settings/:name"
          />
          <Route
            element={
              <AdminRoute>
                <SideBarLayout isAdmin={true}>
                  <Faq />
                </SideBarLayout>
              </AdminRoute>
            }
            path="/admin/faq"
          />
          <Route
            element={
              <ProtectedRoute>
                <SideBarLayout isAdmin={false}>
                  <Faq />
                </SideBarLayout>
              </ProtectedRoute>
            }
            path="/faq"
          />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<ResetPassword />} path="/reset-password" />
          <Route
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
            path="/admin"
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
